import React, { Fragment } from 'react';
import { Col, Row, Button, Form, FormGroup, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce';

import {
  ContainerFieldItem,
  FilterNamedRangeRequest,
  FilterNamedRangeResponse,
  ILeadData,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { JsonObject } from '@cover42/protobuf-util';
import { useAppLogger } from 'Services/logger';
import { useWidgetService } from 'Services/widget';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { PolicyEditData } from '../../../PolicyEdit';
import {
  addListDataPage,
  encodeFilterValue,
  evalInScope,
  FilterInput,
  FilterInputTypeEnum,
  getEmptyListData,
  getFilterControlName,
  getPageNumberControlName,
  parseInputs,
  parseMappings,
} from './list-hooks';
import { StepInfoItem } from '../../../interfaces';
import { useCoreActions } from '../../../DynamicCore';
import { formatFormData } from '../../../core-hooks';

export interface CoreListProps {
  stepItem: ContainerFieldItem;
  leadData: ILeadData;
  formData: ITariffDataStep;
  productCode: string;
  productData: IDataFactorsAndVariables | PolicyEditData;
  isDisabled?: boolean;
  stepData: StepInfoItem;
}

export const CoreList: React.FC<CoreListProps> = ( { formData, productData, isDisabled, stepItem, stepData } ) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const { showAlert, hideAlert } = useAppAlertService();
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const service = useWidgetService();
  const logger = useAppLogger();
  const actions = useCoreActions();
  const { setValue, control, getValues } = useFormContext();

  const { factorName, input, filter, row: rowTemplate, pageSize, mapping, isGoNextStepAfterSelected } = stepItem;
  const { nextStep, nameStep } = stepData || {};
  const [ isLoading, setIsLoading ] = React.useState( false );
  const [ listData, setListData ] = React.useState<FilterNamedRangeResponse>( getEmptyListData( pageSize! ) );

  const inputs = parseInputs( input ?? '' );
  const mappings = parseMappings( mapping ?? '' );

  const getInitialPageNumber = React.useCallback( (): number => {
    const name = getPageNumberControlName( factorName );
    const pageNumber = parseInt( formData ? formData[name] : '1', 10 );

    return ( isNaN( pageNumber ) ? 1 : pageNumber );
  }, [ factorName, formData ] );

  const [ initialPageNumber, setInitialPageNumber ] = React.useState<number>( getInitialPageNumber() );
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState<number>( initialPageNumber );

  const composeFormDataToFilterMap = React.useCallback( () => {
    const map = {};
    for ( const i of inputs ) {
      const name = getFilterControlName( factorName, i );
      const value = formData ? formData[name] : '';
      if ( !!value ) {
        map[i.columnName] = encodeFilterValue( value );
      }
    }

    return map;
  }, [ factorName, formData, inputs ] );

  const initialFilterMap = composeFormDataToFilterMap();
  const [ filterMap, setFilterMap ] = React.useState<object>( initialFilterMap );
  const [ areFiltersActivated, setAreFiltersActivated ] = React.useState<boolean>(
    Object.keys( initialFilterMap ).length > 0 );

  const evalExpr = React.useCallback( ( template: string, ctx: object ): string => {
    const code = template;
    try {
      const s = evalInScope( code, ctx );

      return s;
    } catch ( e ) {
      logger.error( `Error evaluating [${code}] with context: ${e}` );

      return ( e as Error ).message;
    }
  }, [ logger ] );

  const composeFilters = React.useCallback( ( map: object ): string => {
    const filterObj = { ...map };
    for ( const { columnName } of inputs ) {
      if ( !filterObj[columnName] ) {
        filterObj[columnName] = '';
      }
    }
    return evalExpr( filter!, { filter: filterObj } );
  }, [ evalExpr, filter, inputs ] );

  const currentFilters = React.useMemo(
    () => composeFilters( filterMap ),
    [ composeFilters, filterMap ],
  );

  const areFiltersDefined = React.useMemo( () => {
    for ( const { columnName } of inputs ) {
      if ( filterMap[columnName] ) {
        return true;
      }
    }

    return false;
  }, [ filterMap, inputs ] );

  const loadPage = React.useCallback(
    async (
      filters: string,
      startPageNumber: number,
      endPageNumber: number,
    ): Promise<FilterNamedRangeResponse | null> => {
      hideAlert();
      setIsLoading( true );

      const { productSlug, configuration } = productData;

      try {
        const pageSizeToUse = ( endPageNumber - startPageNumber + 1 ) * ( pageSize ?? 10 );
        const request: FilterNamedRangeRequest = {
          productSlug: productSlug!,
          name: factorName!,
          filters,
          pageSize: pageSizeToUse,
          pageToken: `${startPageNumber}`,
        };

        if ( configuration && configuration.productVersionId ) {
          request.productVersionId = configuration.productVersionId;
        }

        const pageData = await service.filterNamedRange( request );

        setIsLoading( false );

        return pageData;
      } catch ( e ) {
        setIsLoading( false );

        logger.error( e );
        showAlert( {
          message: t( 'base:forms.messages.error' ),
          type: 'danger',
        } );
        return null;
      }
    },
    [ factorName, hideAlert, logger, pageSize, productData, service, showAlert, t ],
  );

  const savePageNumber = React.useCallback( ( newPageNumber: number ): void => {
    setCurrentPageNumber( newPageNumber );

    const name = getPageNumberControlName( factorName );
    setValue( name, `${newPageNumber}` );
  }, [ factorName, setValue ] );

  React.useEffect( () => {
    if ( !areFiltersActivated || !areFiltersDefined ) {
      return;
    }

    let isMounted = true;

    loadPage( currentFilters, 1, initialPageNumber ).then(
      ( pageData: FilterNamedRangeResponse | null ) => {
        if ( isMounted && !!pageData ) {
          setListData( pageData );
          savePageNumber( initialPageNumber );
        }
      } );

    return () => {
      isMounted = false;
    };
  }, [ areFiltersActivated, areFiltersDefined, currentFilters, initialPageNumber, loadPage, savePageNumber ] );

  const onLoadMore = React.useCallback(
    async () => {
      const newPageNumber = currentPageNumber + 1;
      const pageData = await loadPage( currentFilters, newPageNumber, newPageNumber );
      if ( pageData ) {
        const newListData = addListDataPage( listData, pageData );
        setListData( newListData );
        savePageNumber( newPageNumber );
      }
    },
    [ currentFilters, currentPageNumber, listData, loadPage, savePageNumber ],
  );

  const onGoNextStep = React.useCallback( async () => {
    if ( actions.isRejected() || !actions.isValidForm() ) {
      setIsLoading( false );
      logger.error( 'An error has occurred in the form data.' );
      return;
    }

    const allFormData = await getValues();
    const savedFormData = formatFormData( allFormData );
    const result = await service.savedInfo( nameStep, savedFormData );

    if ( result.errorCode === 0 && nextStep ) {
      setIsLoading( false );

      actions.goToStep( nextStep, true );
    } else {
      setIsLoading( false );
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, getValues, logger, nameStep, nextStep, service, showAlert, t ] );

  const onRowClick = React.useCallback( ( row: JsonObject ) => {
    if ( isDisabled ) {
      return;
    }

    for ( const { source, target } of mappings ) {
      const value = evalExpr( source, { row } );
      const [ insuredObjectName, productFieldName ] = target.split( '.' );
      const fieldName = `${productFieldName}_${insuredObjectName}`;

      setValue( fieldName, value ? value : '', { shouldValidate: true } );
    }

    if ( isGoNextStepAfterSelected && nextStep ) {
      setIsLoading( true );
      setTimeout( () => {
        onGoNextStep();
      }, 1000 );
    }
  }, [ evalExpr, isDisabled, isGoNextStepAfterSelected, mappings, nextStep, onGoNextStep, setValue ] );

  const renderRow = React.useCallback(
    ( row: JsonObject, template: string ): JSX.Element => {
      const s = evalExpr( template, { row } );

      return (
        <Fragment>
          <Col>
            { s }
          </Col>
          <Col className="select">
            <Button variant="link" disabled={ isDisabled }>
              { t( 'list.select' ) } &gt;
            </Button>
          </Col>
        </Fragment>
      );
    }, [ evalExpr, isDisabled, t ] );

  const onFilterChange = React.useCallback( debounce(
    ( inp: FilterInput, value: string ) => {
      const { columnName, minCharNo } = inp;
      const val = ( value ?? '' ).trim();

      if ( val.length > 0 && val.length < minCharNo ){
        return;
      }

      const name = getFilterControlName( factorName, inp );
      setValue( name, val );

      setFilterMap( ( map: object ) => {
        const newMap = { ...map };
        if ( val ) {
          newMap[columnName] = encodeFilterValue( val );
        } else {
          Reflect.deleteProperty( newMap, columnName );
        }

        return newMap;
      } );

      setInitialPageNumber( 1 );
      savePageNumber( 1 );
      setAreFiltersActivated( true );

    }, 500 ),
  [],
  );

  const renderFilters = React.useCallback( () => {
    return (
      <Row className='list-filters'>
        {
          inputs.map( ( i ) => {
            const filterControlName = getFilterControlName( factorName, i );
            const value = ( formData ? formData[filterControlName] : '' );

            return (
              <Col key={ i.columnName }>
                <Controller
                  id={ filterControlName }
                  name={ filterControlName }
                  control={ control }
                  defaultValue={ value || '' }
                  render={ ( props ) => (
                    <FormGroup className="mb-0" controlId={ props.name }>
                      <Form.Label>{ i.label }</Form.Label>
                      <FormControl type={ i.inputType === FilterInputTypeEnum.Int ? 'number' : 'text' }
                        defaultValue={ value }
                        onChange={
                          ( e: React.ChangeEvent<HTMLInputElement> ) =>
                            onFilterChange( i, e.target.value )
                        }
                        disabled={ isDisabled }
                      />
                    </FormGroup>
                  ) }
                />
              </Col>
            );
          },
          )
        }
      </Row>
    );
  }, [ control, factorName, formData, inputs, isDisabled, onFilterChange ] );

  const renderPageNumberInput = React.useCallback( () => {
    const controlName = getPageNumberControlName( factorName );
    const value = ( formData ? formData[controlName] : '1' );
    return (
      <React.Fragment>
        <Controller
          id={ controlName }
          name={ controlName }
          control={ control }
          defaultValue={ value }
          render={ ( props ) => (
            <FormGroup className="mb-0" controlId={ props.name }>
              <FormControl type="hidden"
                defaultValue={ value }
              />
            </FormGroup>
          ) }
        />
      </React.Fragment>
    );
  }, [ control, factorName, formData ] );

  return (
    <FormGroup className="list">
      { renderFilters() }
      { renderPageNumberInput() }
      { isLoading && loadingOnFullContainer }
      <Row className="list-search-results">
        <div className="list-title">
          { t( 'list.searchResults' ) }
        </div>
        <div className="list-subtitle">
          { areFiltersActivated ?
            t( 'list.itemsFound', { totalItems: listData.totalItems } ) :
            t( 'list.noResultsYet' ) }
        </div>
      </Row>
      { listData.totalItems > 0 && (
        <Fragment>
          <Row className="list-rows">
            {
              ( listData.items ?? [] ).map( ( row ) => (
                <Row key={ JSON.stringify( row ) }
                  onClick={ () => onRowClick( row ) }
                >
                  { renderRow( row, rowTemplate! ) }
                </Row>
              ) )
            }
          </Row>
          { !!listData.nextPageToken && (
            <Row className="list-load-more">
              <Button
                type="button"
                variant="primary"
                className="load-more"
                onClick={ onLoadMore }
                disabled={ isDisabled }
              >
                { t( 'list.loadMore' ) }
              </Button>
            </Row>
          ) }
        </Fragment>
      ) }
    </FormGroup>
  );
};
