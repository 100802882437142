import React from 'react';
import { ILeadData } from 'Services/widgets/interfaces';
import { RecalculationTariff } from '../../DynamicCore';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { StepInfoItem } from '../../interfaces';
import { JSONPageType } from '../../../booking-funnel/enum';
import { EmailConfirmationStep } from './system-sub-steps/EmailConfirmationStep';
import { SuccessPageStep } from './system-sub-steps/SuccessPageStep';

export interface SystemCoreStepProps {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  stepData: StepInfoItem;
  recalculationTariff: RecalculationTariff;
  policyStepNames: string[];
}

export const SystemCoreStep: React.FC<SystemCoreStepProps> = ( dataProps ) => {
  const { stepData } = dataProps;
  const pageType = stepData && stepData.pageType ? stepData.pageType : JSONPageType.EmailVerificationPage;

  return (
    <>
      { pageType === JSONPageType.EmailVerificationPage && (
        <EmailConfirmationStep
          { ...dataProps }
        />
      ) }
      { pageType === JSONPageType.SuccessPage && (
        <SuccessPageStep
          { ...dataProps }
        />
      ) }
    </>
  );
};
