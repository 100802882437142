import React from 'react';
import jsonpath from 'jsonpath';
import dayjs from 'dayjs';
import { ContainerFieldItem, ILeadData } from 'Services/widgets/interfaces';
import { customDateFormat, defaultDateFormat } from 'App/ui-utils';

export interface CoreDateRangeFieldProps {
  idx: number;
  lead: ILeadData;
  stepItem: ContainerFieldItem;
}

export const CoreDateRangeField: React.FC<CoreDateRangeFieldProps> = ( { idx, stepItem, lead } ) => {

  const renderValueElement = ( ): React.ReactElement => {
    const dateRange = jsonpath.query( lead || {}, `$.${stepItem.fieldName}` )[0];

    if ( dateRange ) {
      const [ startDateField, endDateField ] = dateRange.split( ' - ' );

      if ( startDateField && endDateField ) {
        const startDate = dayjs( startDateField );
        const endDate = dayjs( endDateField );

        return (
          <p
            className="date-value"
            custom-date-format={
              `${startDate.format( customDateFormat )} - ${endDate.format( customDateFormat )}`
            }
          >
            <span>
              { `${startDate.format( defaultDateFormat )} - ${endDate.format( defaultDateFormat )}` }
            </span>
          </p>
        );
      }
    }

    return ( <p>-</p> );
  };

  return (
    <div
      id={ `${stepItem.fieldName}-${idx}` }
      className="details-section mx-4"
    >
      <p className="bf-selected-val f-14" dangerouslySetInnerHTML={ { __html: `${stepItem.label}` } }></p>
      { renderValueElement() }
    </div>
  );
};
