import React, { Fragment } from 'react';
import InputMask from 'react-input-mask';
import { blankLink } from 'config';
import { JsonValue } from '@cover42/protobuf-util';
import { Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable, ContainerFieldItem, FieldStringSettings } from 'Services/widgets/interfaces';
import {
  checkedRecalculation,
  checkedUnderwriting,
  isRequiredField,
} from '../../../booking-funnel/booking-funnel-hooks';
import { AsteriskRequiredField } from '../../../AsteriskRequiredField';
import { useCoreActions } from '../../DynamicCore';
import { FieldDefaultValue } from 'App/widget/types';
import { isBoolean } from 'lodash';
import { renderFieldLabel, renderPlaceholder } from '../../core-hooks';
import { InputType } from 'Services/widgets/enums';
import { preparedMaskFormatChars } from '../../mask-input-hooks';

export interface StringFieldProps {
  valueField: string;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: boolean;
  showAsterisk?: boolean;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
  isDisabled?: boolean;
}

export const StringField: React.FC<StringFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  showAsterisk,
  stepItem,
  fieldDefaultValue,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { errors, control, setValue } = useFormContext();
  const { productFieldValue, isReadOnly } = fieldDefaultValue || {};
  const fieldName = isShortName ? variable.name :
    `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;

  const { inputType, settings } = stepItem || {};
  const {
    mask,
    maskChar,
    maskFormatRegex,
    maskFormatText,
    maskFormatChars,
    enabledButtonToShowField,
    buttonText,
  } = settings as FieldStringSettings || {};

  const initIsShowField = (): boolean => {
    if ( isRequiredField( variable, stepItem ) || valueField ) {
      return true;
    }

    if ( enabledButtonToShowField ) {
      return false;
    }

    return true;
  };

  const [ isShowField, setIsShowField ] = React.useState<boolean>( initIsShowField() );

  const formatChars = preparedMaskFormatChars( maskFormatChars );

  const getDefaultValue = ( stringValue: JsonValue ): string => {
    if ( productFieldValue ) {
      return productFieldValue as string;
    }

    if ( stepItem && stepItem.defaultValue ) {
      return stepItem.defaultValue as string || '';
    }

    return stringValue !== null ? stringValue as string : '';
  };

  const renderClassName = ( ): string => {
    let classNameField = '';
    let readOnly = '';

    if ( stepItem?.className ) {
      classNameField = stepItem?.className;
    }

    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return `${classNameField} read-only`;
    }

    if ( stepItem?.isReadOnly ) {
      readOnly = ' read-only';
    }

    return `${classNameField}${readOnly}`;
  };

  const isReadOnlyInput = ( ): boolean => {
    if ( isBoolean( isReadOnly ) && isReadOnly ) {
      return isReadOnly;
    }

    if ( stepItem && stepItem.isReadOnly ) {
      return stepItem.isReadOnly;
    }

    return false;
  };

  const isUseMaskInput = ( ): boolean => {
    if ( inputType === InputType.Mask && mask ) {
      return true;
    }

    return false;
  };

  const isValidEmail = ( email: string ): boolean => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test( email );
  };

  const isValidateEmail = ( value: string ): string | boolean => {
    if ( value && !isValidEmail( value ) ) {
      const errMessage = t( 'bookingFunnel.personalData.emailFormatError' );
      return errMessage;
    }

    return true;
  };

  const isValidateString = ( value: string ): string | boolean => {
    if ( !isUseMaskInput() && isRequiredField( variable, stepItem ) ) {
      const valueTrim = value.trim();
      if ( !valueTrim && value ) {
        const errMessage = t( 'base:forms.messages.fieldRequired', {
          fieldLabel: variable.label,
        } );

        return errMessage;
      }
    }

    if ( !value ) {
      return true;
    }

    // TODO: Commenting on this code, will need to redo the validation for the mask
    // if ( isUseMaskInput() ) {
    //   const maskLength = mask.length;
    //   const currentValueLength = value.replace( maskChar, '' ).length;
    //   const errMessage = t( 'base:forms.messages.dateInvalid', {
    //     fieldLabel: variable.label,
    //     fieldFormat: mask,
    //   } );

    //   return currentValueLength < maskLength ? errMessage : true;
    // }

    if ( isUseMaskInput() && maskFormatRegex ) {
      const errMessage = t( 'base:forms.messages.dateInvalid', {
        fieldLabel: variable.label,
        fieldFormat: maskFormatText || mask,
      } );

      const maskPatternRegex: RegExp = new RegExp( maskFormatRegex );

      return !maskPatternRegex.test( value ) ? errMessage : true;
    }

    return true;
  };

  const trimValue = ( value: string ): void => {
    const valueTrim = value.trim();

    if ( value !== valueTrim && isRequiredField( variable, stepItem ) ) {
      setValue( fieldName, valueTrim, { shouldValidate: true } );
    }
  };

  const onRecalculation = React.useCallback( ( ): void => {
    const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
    const isIgnoreOnRecalculation = checkedRecalculation( stepItem! );
    actions.recalculationPremium( isCheckedUnderwriting, isIgnoreOnRecalculation );
  }, [ actions, stepItem ] );

  React.useEffect( () => {
    if ( stepItem && isDisabled && stepItem.defaultValue ) {
      setValue( fieldName, stepItem.defaultValue, { shouldValidate: true } );
    }
  }, [ fieldName, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      { isShowField ? (
        <Controller
          name={ fieldName }
          control={ control }
          rules={ {
            required: isDisabled ? false : isRequiredField( variable, stepItem ),
            validate: ( value ) => {
              if ( isDisabled ) {
                return true;
              }

              return inputType === InputType.Email ? isValidateEmail( value ) : isValidateString( value );
            },
          } }
          defaultValue={ valueField ? valueField : getDefaultValue( variable.defaultValue ) }
          render={ ( props ) => (
            <FormGroup className={ stepItem?.classNameBox! } controlId={ props.name }>
              <Form.Label id={ `${props.name}-label` } className={ variable.bfTooltip && 'tooltip-label' }>
                <div
                  className="d-inline-block"
                  dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
                />
                { showAsterisk && variable.isRequired && ( <AsteriskRequiredField /> ) }
                { variable.bfTooltip && (
                  <a
                    id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                    href={ blankLink }
                    role='button'
                    onClick={ ( e ) => {
                      e.preventDefault();
                      showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                    } }
                  >
                    { t( 'bookingFunnel.tooltipHelp' ) }
                  </a>
                ) }
              </Form.Label>
              { variable.bfDescription && (
                <span className="gm-info f-12 mt-1">
                  { variable.bfDescription }
                </span>
              ) }
              { isUseMaskInput() ? (
                <InputMask
                  mask={ mask }
                  maskChar={ maskChar || null }
                  disabled={ isDisabled }
                  name={ props.name }
                  value={ props.value }
                  readOnly={ isReadOnlyInput() }
                  className={ renderClassName() }
                  placeholder={ renderPlaceholder( variable.label, stepItem ) }
                  isInvalid={ errors[props.name] !== undefined }
                  onChange={ ( e ) => {
                    props.onChange( e );
                  } }
                  onBlur={ onRecalculation }
                  formatChars={ formatChars }
                >
                  { ( inputProps ) =>
                    <Form.Control
                      { ...inputProps }
                      type="text"
                    />
                  }
                </InputMask>
              ) : (
                <Form.Control
                  { ...props }
                  type={ inputType ? inputType as string : 'text' }
                  className={ renderClassName() }
                  readOnly={ isReadOnlyInput() }
                  placeholder={ renderPlaceholder( variable.label, stepItem ) }
                  isInvalid={ errors[props.name] !== undefined }
                  disabled={ isDisabled }
                  onBlur={ ( ) => {
                    trimValue( props.value );

                    onRecalculation();
                  } }
                />
              ) }
              <FormControl.Feedback type="invalid">
                { errors[props.name]?.message ? (
                errors[props.name]?.message
                ) : (
                  <Fragment>
                    { t( 'base:forms.messages.fieldRequired', { fieldLabel: variable.label } ) }
                  </Fragment>
                ) }
              </FormControl.Feedback>
            </FormGroup>
          ) }
        />
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <Button
            type="button"
            variant="link"
            className="m-0 py-0 text-c-black custom-back-button bf-show-btn"
            onClick={ () => setIsShowField( true ) }
          >
            { buttonText ? buttonText : t( 'base:forms.addField' ) }
          </Button>
        </div>
      ) }
    </Fragment>
  );
};
