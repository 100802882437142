import React, { Fragment } from 'react';
import rightArrowIconUrl from 'assets/bf-images/funk/right_arrow.svg';
import { isEqual } from 'lodash';
import { Row, Col, Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useWidgetService } from 'Services/widget';
import { Controller, FormProvider } from 'react-hook-form';
import {
  CardNumberElement,
  IbanElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  ConfirmCardSetupData,
  ConfirmSepaDebitSetupData,
  SetupIntentResult,
  StripeCardNumberElement,
  StripeIbanElement,
} from '@stripe/stripe-js/types/stripe-js';
import { PaymentMethodName } from 'Services/widgets/enums';
import {
  ICreateLeadData,
  IInitiatePaymentSetup,
  IPaymentSystem,
  IPaymentSystemComplete,
  IPolicyObject,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { PaymentSubStepItem } from '../../../summary-sub-steps/PaymentSubStep';
import { IbanFields } from './IbanFields';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { CardFields } from './CardFields';
import {
  generateVariables,
  keyMultiInsuredObjects,
  prepareAccountData,
  prepareDataForTariff,
  preparePolicyPayload,
} from '../../../../core-hooks';
import { StripeSystemProps } from '../../StripeSystem';
import { FUNKBFStep } from 'App/components/widgets/funk/enums';
import { LeadStatus } from '@cover42/ts-contracts';
import { PersonalDataStepCore } from '../../../personal-sub-steps/RegistrationStep';
import { ActiveStepCore, ActiveSubStepCore, useCoreActions } from 'App/components/widgets/dynamic-core/DynamicCore';
import { JSONSubSteps } from 'App/components/widgets/booking-funnel/enum';
import { MobileNavCore } from 'App/components/widgets/dynamic-core/ui-components/MobileNavCore';

export const StripeForm: React.FC<StripeSystemProps> = ( {
  lead, productData, productCode, formContext, currentStep, policyStepNames,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();
  const previousFormData = React.useRef<ITariffDataStep>( {} );
  const [ paymentMethodName, setPaymentMethodName ] = React.useState<string>( '' );
  const [ initiatePaymentSetup, setInitiatePaymentSetup ] = React.useState<IInitiatePaymentSetup | null>( null );
  const { leadData, isSetupPayment } = productData;

  const stepData = currentStep && currentStep.containers.find( ( subStep ) => subStep.name === JSONSubSteps.Payment );

  const stripe = useStripe();
  const elements = useElements();

  let paymentDataFields: PaymentSubStepItem = {
    paymentMethod: '',
    accountFirstName: lead.personalData?.firstName,
    accountLastName: lead.personalData?.lastName,
    accountEmail: lead.personalData?.email,
  };

  if ( lead.paymentData !== null ) {
    paymentDataFields = lead.paymentData as PaymentSubStepItem;
  }

  const { errors, control, formState, reset, getValues } = formContext;

  React.useEffect( () => {
    const formValues = getValues();
    const saveFormValues = async () => {
      try {
        await service.savedInfo( 'paymentData', previousFormData.current );
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };

    if ( !isEqual( formValues, previousFormData.current ) ) {
      previousFormData.current = formValues;
      saveFormValues();
    }
  }, [ actions, formState.isValid, formState.isValidating, getValues, service, showAlert, t ] );

  React.useEffect( () => {
    let isMounted = true;

    if ( isSetupPayment ) {
      if ( lead.paymentData?.paymentMethod ) {
        setPaymentMethodName( lead.paymentData?.paymentMethod );
      }

      return;
    }

    const loadPaymetData = async () => {
      try {
        const paymentData: IPaymentSystem = {
          stripe: {
            leadCode: leadData.leadCode,
          },
          productSlug: productData.productSlug,
        };

        const initiatePayment = await service.initiatePaymentSetup( paymentData );

        if ( isMounted ) {
          if ( lead.paymentData?.paymentMethod ) {
            setPaymentMethodName( lead.paymentData?.paymentMethod );
          }

          if ( initiatePayment ) {
            setInitiatePaymentSetup( initiatePayment );
          }
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          if ( isMounted ) {
            showAlert( {
              message: t( 'base:forms.messages.errorSave' ),
              type: 'danger',
            } );
          }
        } else {
          throw e;
        }
      }
    };

    loadPaymetData();

    return () => {
      isMounted = false;
    };
  }, [ service, reset, lead.paymentData, leadData.leadCode, showAlert, t, isSetupPayment, productData ] );

  const changePaymentType = React.useCallback( ( paymetType: PaymentMethodName ) => {
    setPaymentMethodName( paymetType );
  }, [] );

  const onSubmit = React.useCallback( async ( formData: PaymentSubStepItem ) => {
    if ( isSetupPayment ) {
      actions.goToStep( ActiveStepCore.Summary, true );

      return;
    }

    if ( !stripe || !elements ) {
      return;
    }

    const clientSecret = initiatePaymentSetup?.stripe?.pspSecretToken!;
    let setupIntentResult: SetupIntentResult | null = null;

    if ( paymentMethodName === PaymentMethodName.Card ) {
      const cardNumberElement: StripeCardNumberElement | null = elements.getElement( CardNumberElement );
      const dataCard: ConfirmCardSetupData = {
        payment_method: {
          card: cardNumberElement!,
          billing_details: {
            name: `${formData.accountFirstName!} ${formData.accountLastName!}`,
            email: formData.accountEmail!,
          },
        },
      };

      const confirmCard = await stripe.confirmCardSetup(
        clientSecret,
        dataCard,
      );

      setupIntentResult = confirmCard;
    }

    if ( paymentMethodName === PaymentMethodName.Sepa ) {
      const ibanElement: StripeIbanElement | null = elements.getElement( IbanElement );
      const dataSepa: ConfirmSepaDebitSetupData = {
        payment_method: {
          sepa_debit: ibanElement!,
          billing_details: {
            name: `${formData.accountFirstName!} ${formData.accountLastName!}`,
            email: formData.accountEmail!,
          },
        },
      };

      const confirmSepaDebit = await stripe.confirmSepaDebitSetup(
        clientSecret,
        dataSepa,
      );
      setupIntentResult = confirmSepaDebit;
    }

    if ( setupIntentResult && !setupIntentResult.error ) {
      const paymendData: IPaymentSystemComplete = {
        stripe: {
          firstName: formData.accountFirstName!,
          lastName: formData.accountLastName!,
          email: formData.accountEmail!,
          leadCode: leadData.leadCode,
          pspCustomerId: initiatePaymentSetup?.stripe?.pspCustomerId!,
          pspPaymentMethodId: setupIntentResult.setupIntent.payment_method!,
        },
        productSlug: productData.productSlug,
      };

      const completePayment = await service.completePaymentSetup( paymendData, formData );
      if ( completePayment ) {
        try {
          const tariffData = preparePolicyPayload( lead, policyStepNames! );

          let tariffDataForMultiInsured: IPolicyObject[] = [];
          const multiInsuredObjects = lead[keyMultiInsuredObjects];

          if ( multiInsuredObjects ) {
            const variables = generateVariables( multiInsuredObjects );

            tariffDataForMultiInsured = prepareDataForTariff(
              tariffData,
              variables,
              multiInsuredObjects,
              false,
            );
          }
          const preparedPolicyData = prepareDataForTariff(
            tariffData,
            productData.variables,
            productData.insuredObjects,
            false,
          );

          const personalData = lead.personalData! as PersonalDataStepCore;
          const preparedAccountData = prepareAccountData( personalData );
          let payloadLead: ICreateLeadData = {
            code: productData.leadData.leadCode,
            productCode: productCode,
            policyObjects:  [ ...preparedPolicyData, ...tariffDataForMultiInsured ],
            account: preparedAccountData,
            status: LeadStatus.Approved,
          };

          const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

          if ( savedLeadData ) {
            payloadLead.productVersionId = savedLeadData.policy.productVersionId;
          }

          const result = savedLeadData ?
            await service.updateLeadFunk( payloadLead ) : await service.createLead( payloadLead );

          if ( result ) {
            actions.onSubmit();
          } else {
            showAlert( {
              message: t( 'base:forms.messages.errorSave' ),
              type: 'danger',
            } );
          }
        } catch( e ) {
          if ( e instanceof Error ) {
            showAlert( {
              message: t( 'base:forms.messages.errorSave' ),
              type: 'danger',
            } );
          } else {
            throw e;
          }
        }
      } else {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    } else {
      showAlert( {
        message: setupIntentResult ? setupIntentResult.error.message : t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
      actions.goToTopPage();
    }
  }, [ actions, elements, initiatePaymentSetup,
    isSetupPayment, lead, leadData.leadCode, paymentMethodName,
    policyStepNames, productCode, productData.insuredObjects,
    productData.leadData.leadCode, productData.productSlug,
    productData.variables, service, showAlert, stripe, t ] );

  if ( actions.goToSummaryStep && lead[ActiveStepCore.Summary] === null ) {
    actions.goToSummaryStep( ActiveStepCore.Summary, ActiveSubStepCore.SummarySubStep1, true );
  }

  if ( initiatePaymentSetup === null && !isSetupPayment ) {
    return <div className="bf-loading col-auto py-4" style={ { height: 'auto' } }><LoadingSpinner /></div>;
  }

  return (
    <FormProvider { ...formContext }>
      <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
        <Row className="max-w-450">
          <Col sm={ 12 } className="mb-4 max-w-289">
            <Controller
              name="paymentMethod"
              control={ control }
              rules={ { required: true } }
              defaultValue={ paymentDataFields.paymentMethod }
              render={ ( props ) => (
                <FormGroup className="custom-btn-radio my-2">
                  { stepData && stepData.items.map( ( item, idx ) => (
                    <Fragment key={ idx }>
                      { item.paymentType === PaymentMethodName.Sepa && (
                        <div className="mb-4">
                          <FormControl
                            { ...props }
                            className="form-check-input"
                            type="radio"
                            id={ PaymentMethodName.Sepa }
                            value={ PaymentMethodName.Sepa }
                            checked={ props.value === PaymentMethodName.Sepa }
                            onClick={ () => changePaymentType( PaymentMethodName.Sepa ) }
                            onBlur={ props.onBlur }
                          />
                          <Form.Label
                            className="btn btn-border-radio"
                            htmlFor={ PaymentMethodName.Sepa }
                          >SEPA <span>Lastschrift</span>
                          </Form.Label>
                        </div>
                      ) }
                      { item.paymentType === PaymentMethodName.Card && (
                        <div className="mb-4">
                          <FormControl
                            { ...props }
                            className="form-check-input"
                            type="radio"
                            id={ PaymentMethodName.Card }
                            value={ PaymentMethodName.Card }
                            checked={ props.value === PaymentMethodName.Card }
                            onClick={ () => changePaymentType( PaymentMethodName.Card ) }
                            onBlur={ props.onBlur }
                          />
                          <Form.Label className="btn btn-border-radio" htmlFor={ PaymentMethodName.Card }>
                            Kreditkarte
                          </Form.Label>
                        </div>
                      ) }
                    </Fragment>
                  ) ) }
                  <Form.Control
                    type="hidden"
                    isInvalid={ errors.paymentMethod !== undefined }
                  />
                  <Form.Control.Feedback type="invalid">
                    { t( 'base:forms.messages.fieldRequired',
                      { fieldLabel: t( 'bookingFunnel.paymentData.paymentMethod' ) } ) }
                  </Form.Control.Feedback>
                </FormGroup>
              ) }
            />
          </Col>
          { paymentMethodName === PaymentMethodName.Card && (
            <CardFields paymentFields={ paymentDataFields } />
          ) }
          { paymentMethodName === PaymentMethodName.Sepa && (
            <IbanFields
              paymentFields={ paymentDataFields }
              isSetupPayment={ isSetupPayment }
            />
          ) }
        </Row>
        <Row>
          { stepData && stepData.items.map( ( item, idx ) => (
            <Fragment key={ idx }>
              { item.paymentType === paymentMethodName && (
                <Col sm={ 12 } className="mb-2">
                  { item.infoTextPayment?.map( ( text, textIdx ) => (
                    <div key={ textIdx } dangerouslySetInnerHTML={
                      { __html: text }
                    }
                    />
                  ) ) }
                </Col>
              ) }
            </Fragment>
          ) ) }
        </Row>
        <div className="max-w-667">
          <MobileNavCore
            lead={ lead }
          />
        </div>
        <Row className="dynamic-btn-panel mt-5 d-flex justify-content-space-between">
          <Button
            id={ `${FUNKBFStep.PaymentStep}_back` }
            type="button"
            variant="link"
            className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
            onClick={ () => actions.goToSummaryStep &&
              actions.goToSummaryStep( ActiveStepCore.Summary, ActiveSubStepCore.SummarySubStep1, true ) }
          >
            { t( 'bookingFunnel.previousBtn' ) }
          </Button>
          <Button
            id={ `${FUNKBFStep.PaymentStep}_next` }
            type="submit"
            variant="primary"
            disabled={ formState.isSubmitting }
            className="mr-0 mb-0 bg-btn-primary"
          >
            { t( 'bookingFunnel.funk.payment.nextBtn' ) }
            <img src={ rightArrowIconUrl } className="ml-2" alt={ t( 'bookingFunnel.funk.payment.nextBtn' ) } />
          </Button>
        </Row>
      </Form>
    </FormProvider>
  );
};
