import React, { Fragment, ReactElement } from 'react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Card, Accordion } from 'react-bootstrap';
import { AccountDataType, FieldType, FormType, ProductFieldTypeEntity, Title } from 'Services/widgets/enums';
import { PersonalDataStepCore } from '../../../steps/personal-sub-steps/RegistrationStep';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';
import { evalFunction } from 'App/components/widgets/booking-funnel/functions';
import {
  ContainerFieldItem,
  IBFProductVariable,
  ILeadData,
  ITariffDataStep,
  SummaryLabel,
  SummarySettings,
} from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { FIELD_TYPE_BOOL, FIELD_TYPE_DATETIME, FIELD_TYPE_NUMBER, isObject } from 'App/components/widgets/bf-hooks';
import { convertValueToNumber, currencyFormatter, defaultCurrency } from '../../../core-hooks';
import { customDateFormat, defaultDateFormat } from 'App/ui-utils';
import { useLocale } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { StepInfoItem } from '../../../interfaces';
import { personalDataCoreKey } from '../../steps/personal-sub-steps/RegistrationCoreStep';
import { AddressType } from '../../../enums';
import { CoreLabel } from '../CoreLabel';
import { PositionLabel } from 'Services/widgets/enums';
import { CoreViewDynamicField } from './CoreViewDynamicField';

export interface CorePersonalInfoProps {
  isOpened: boolean;
  currentStep: StepInfoItem;
  productData: IDataFactorsAndVariables;
  lead: ILeadData;
  labels?: SummaryLabel[];
  settings: SummarySettings;
}

export const CorePersonalInfo: React.FC<CorePersonalInfoProps> = (
  { isOpened, currentStep, productData, lead, labels, settings },
) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const locale = useLocale();
  const { factors, variables } = productData;
  const [ isCollapsed, setCollapsed ] = React.useState<boolean>( isOpened );
  const personalInfo = lead[personalDataCoreKey] as PersonalDataStepCore;
  const { disbledExpandView } = settings || {};

  const personalDataStep = currentStep?.steps.find(
    ( item ) => item.formType && item.formType === FormType.Registration
    );

  const collapseToggleHandler = React.useCallback( () => {
    setCollapsed( !isCollapsed );
  }, [ isCollapsed ] );

  const getTitle = ( titleVal: Title ): string => {
    const titles = Object.values( Title );

    if ( titles.includes( titleVal ) ) {
      return titleVal;
    }

    // When the value selected by the user is Title.none, titleVal is:
    // t( 'bookingFunnel.personalDataFunk.honorifics.none' )
    // So the if clause will be false
    // Explained in RegistrationStep
    return '-';
  };

  const getValueFactorField = React.useCallback( (
    variable: IBFProductVariable, containerField: ContainerFieldItem,
  ): ReactElement | string => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = personalInfo[fieldName];

    if ( selectedVal && containerField && containerField.uiType === JSONItemUIType.MultiSelect ) {
      const selectedValues: string[] = typeof selectedVal === 'string' ? JSON.parse( selectedVal ) : null;

      if ( selectedValues ) {
        const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
        if ( findFactor ) {
          const factorList = findFactor?.values.filter( ( f ) => selectedValues.includes( String( f.key ) ) );

          return factorList.map( ( item ) => {
            return item.name;
          } ).join( ', ' );
        }
      }

      if ( typeof selectedVal === 'object' ) {
        return selectedVal.map( ( item ) => {
          return item.name;
        } ).join( ', ' );
      }
    }

    if ( isObject( selectedVal ) && selectedVal['name'] ) {
      return selectedVal['name'];
    }

    if ( selectedVal ) {
      const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
      if ( findFactor ) {
        const factorInfo = findFactor?.values.find( ( f ) => f.key === selectedVal )!;
        return factorInfo?.name;
      }
    }

    return '-';
  }, [ factors, personalInfo ] );

  const getValueBaseField = React.useCallback( ( variable: IBFProductVariable, containerField: ContainerFieldItem ) => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = personalInfo[fieldName];

    if ( variable.typeId === FIELD_TYPE_BOOL && selectedVal ) {
      return selectedVal ? t( 'bookingFunnel.tariff.yes' ) : t( 'bookingFunnel.tariff.no' );
    }

    if ( variable.typeId === FIELD_TYPE_NUMBER && selectedVal ) {
      const isCurrency: boolean = containerField?.uiType === JSONItemUIType.Currency ? true : false;
      if ( isCurrency ) {
        const converValue = convertValueToNumber( selectedVal );
        return `${ currencyFormatter( converValue, defaultCurrency, locale, false ) } Euro`;
      }

      return selectedVal;
    }

    if ( variable.typeId === FIELD_TYPE_DATETIME && selectedVal ) {
      return dayjs( selectedVal ).format( defaultDateFormat );
    }

    const resVal: string = selectedVal ? selectedVal.toString() : '';
    return !isEmpty( resVal ) ? resVal : '-';
  }, [ locale, personalInfo, t ] );

  const getFullAddress = React.useCallback( ( ): string => {
    if ( personalInfo !== null ) {
      const { street, houseNumber, zipCode, city, country, addressType } = personalInfo as ITariffDataStep;

      let newAddress = ( street ? street : '' )
      + ( houseNumber ? ` ${houseNumber}, ` : '' )
      + ( zipCode ? `${zipCode} `: '' )
      + ( city ? city : '' );

      if ( addressType === AddressType.InsuredAddress ) {
        newAddress = newAddress + ( country ? `, ${country}` : '' );
      }

      return newAddress;
    }

    return '';
  }, [ personalInfo ] );

  const isReuseAddress = React.useCallback( ( ): boolean => {
    if ( personalInfo !== null && personalInfo['addressType']
     && personalInfo['addressType'] === AddressType.InsuredAddress ) {
      return true;
    }

    return false;
  }, [ personalInfo ] );

  const topLabel
    = labels?.find( ( label ) => label.stepName === personalDataStep?.name && label.position === PositionLabel.Top );
  const bottomLabel
    = labels?.find( ( label ) => label.stepName === personalDataStep?.name && label.position === PositionLabel.Bottom );

  return (
    <Fragment>
      { personalInfo !== null && (
        <Accordion defaultActiveKey={ isOpened ? personalDataStep?.name! : '' }>
          <Card className="p-0 m-0">
            { !disbledExpandView && (
              <Accordion.Toggle
                className="py-2 px-3"
                as={ Card.Header }
                variant="link"
                eventKey={ personalDataStep?.name! }
                onClick={ collapseToggleHandler }
              >
                <span className="align-middle bf-title-text">{ currentStep?.navigationTitle }</span>
                <i className={ `material-icons chevron align-middle${ isCollapsed ? ' bf-open' : ' bf-close' }` }>
                  { isCollapsed ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }
                </i>
              </Accordion.Toggle>
            ) }
            { disbledExpandView && (
              <Card.Header className="py-2 px-3 cursor-default">
                <span className="align-middle bf-title-text">{ currentStep?.navigationTitle }</span>
              </Card.Header>
            ) }
            <Accordion.Collapse eventKey={ personalDataStep?.name! }>
              <Card.Body className="px-3">
                { topLabel && (
                  <CoreLabel stepItem={ topLabel as unknown as ContainerFieldItem } className="" />
                ) }
                { personalDataStep && personalDataStep.containers.map( ( container, containerIdx ) => (
                  <Fragment key={ containerIdx }>
                    { evalFunction( lead || [], container.visibleCriteria ) && (
                      <Fragment>
                        { container.items && container.items.map( ( item, idx ) => (
                          <Fragment key={ idx }>
                            { evalFunction( lead || [], item.visibleCriteria ) && (
                              <Fragment>
                                { item.type && item.type === FieldType.Account && (
                                  <Fragment>
                                    { personalInfo[item.fieldName] && item.dataType === AccountDataType.Gender && (
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">
                                          { item.label ?
                                            item.label :
                                            t( 'bookingFunnel.personalData.title' ) }
                                        </p>
                                        <p>
                                          { t( `bookingFunnel.personalData.${personalInfo[item.fieldName]}` ) }
                                        </p>
                                      </div>
                                    ) }
                                    { personalInfo[item.fieldName] && item.dataType === AccountDataType.Email && (
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">
                                          { item.label ?
                                            item.label :
                                            t( `bookingFunnel.personalData.${item.fieldName}` ) }
                                        </p>
                                        <p>{ personalInfo[item.fieldName] }</p>
                                      </div>
                                    ) }
                                    { personalInfo[item.fieldName] && item.dataType === AccountDataType.String && (
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">
                                          { item.label ?
                                            item.label :
                                            t( `bookingFunnel.personalData.${item.fieldName}` ) }
                                        </p>
                                        <p>{ personalInfo[item.fieldName] }</p>
                                      </div>
                                    ) }
                                    { personalInfo[item.fieldName] && item.dataType === AccountDataType.TitleType && (
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">
                                          { item.label ?
                                            item.label :
                                            t( 'bookingFunnel.funk.registration.nameTitleField' ) }
                                        </p>
                                        <p>{ getTitle( personalInfo[item.fieldName] ) }</p>
                                      </div>
                                    ) }
                                    { personalInfo[item.fieldName] && item.dataType === AccountDataType.Phone && (
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">
                                          { item.label ?
                                            item.label :
                                            t( `bookingFunnel.personalData.${item.fieldName}` ) }
                                        </p>
                                        <p>{ personalInfo[item.fieldName] }</p>
                                      </div>
                                    ) }
                                    { personalInfo[item.fieldName] && item.dataType === AccountDataType.Date && (
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">
                                          { item.label ?
                                            item.label :
                                            t( `bookingFunnel.personalData.${item.fieldName}` ) }
                                        </p>
                                        <p></p>
                                        <p
                                          className="date-value"
                                          custom-date-format={
                                            dayjs( personalInfo[item.fieldName] ).format( customDateFormat )
                                          }
                                        >
                                          <span>
                                            { dayjs( personalInfo[item.fieldName] ).format( defaultDateFormat ) }
                                          </span>
                                        </p>
                                      </div>
                                    ) }
                                    { item.dataType === AccountDataType.Address && (
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">
                                          { item.label ?
                                            item.label :
                                            t( `bookingFunnel.personalData.${item.fieldName}` ) }
                                        </p>
                                        <p>{ getFullAddress() }</p>
                                      </div>
                                    ) }
                                  </Fragment>
                                ) }
                                { item.type && item.type === FieldType.AddressSwitcher && isReuseAddress() && (
                                  <div className="details-section mx-4">
                                    <p className="bf-selected-val f-14">
                                      { item.label ? item.label : t( 'bookingFunnel.personalData.addressTitle' ) }
                                    </p>
                                    <p>{ getFullAddress() }</p>
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.StateVariable && (
                                  <CoreViewDynamicField
                                    lead={ lead }
                                    stepItem={ item }
                                  />
                                ) }
                                { item.type && item.type === FieldType.Policy && variables.filter(
                                  ( v ) => `${v.name}_${v.groupName}` === `${item.fieldName}_${item.insuredObjectName}`
                                  && !v.isHidden,
                                )
                                  .map( ( variable, cIdx ) => (
                                    <Fragment key={ cIdx }>
                                      <div className="details-section mx-4">
                                        <p className="bf-selected-val f-14">{ variable.label }</p>
                                        { variable.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
                                          <p>{ getValueBaseField( variable, item ) }</p>
                                        ) }
                                        { variable.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
                                          <p>{ getValueFactorField( variable, item ) }</p>
                                        ) }
                                      </div>
                                    </Fragment>
                                  ) ) }
                              </Fragment>
                            ) }
                          </Fragment>
                        ) ) }
                      </Fragment>
                    ) }
                  </Fragment>
                ) ) }
                { bottomLabel && (
                  <CoreLabel stepItem={ bottomLabel as unknown as ContainerFieldItem } className="" />
                ) }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) }
    </Fragment>
  );
};
