import { PremiumFormulaUnits } from '../Services/widgets/enums';

export const defaultDateFormat: string = 'DD.MM.YYYY';
export const deviceDateFormat: string = 'MM.YYYY';
export const apiDateTimeFormat: string = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const invoiceDateFormat: string = 'DD MMM YYYY';
export const customDateFormat: string = 'DD/MM/YYYY';

export const durationInHours = {
  [PremiumFormulaUnits.Year]: 24 * 28 * 12,
  [PremiumFormulaUnits.HalfYear]: 24 * 28 * 6,
  [PremiumFormulaUnits.Quarter]: 24 * 28 * 3,
  [PremiumFormulaUnits.Month]: 24 * 28,
  [PremiumFormulaUnits.Week]: 24 * 7,
  [PremiumFormulaUnits.Day]: 24,
  [PremiumFormulaUnits.Hour]: 1,
};

export const setCustomStyles = ( elemenId: string, styleVal: string ) => {
  if ( elemenId && styleVal ) {
    const findElement = document.getElementById( elemenId );
    if ( findElement !== null ) {
      findElement.remove();
    }

    const elStyle = document.createElement( 'style' );
    elStyle.id = elemenId;
    elStyle.type = 'text/css';
    elStyle.innerHTML = styleVal;
    document.getElementsByTagName( 'head' )[0].appendChild( elStyle );
  }
};
