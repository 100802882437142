import React, { Fragment } from 'react';
import { Col, Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IbanElement } from '@stripe/react-stripe-js';
import { StripeIbanElementChangeEvent } from '@stripe/stripe-js';
import { PaymentCoreStepItem } from '../../../PaymentCoreStep';

export interface IbanFieldsProps {
  paymentFields: PaymentCoreStepItem;
  isSetupPayment: boolean;
};

export const IbanFields: React.FC<IbanFieldsProps> = ( { paymentFields, isSetupPayment } ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control, setValue } = useFormContext();

  const onChangeIban = React.useCallback( ( e: StripeIbanElementChangeEvent ) => {
    if ( e.complete ) {
      setValue( 'paymentDataValid', e.complete, { shouldValidate: true } );
    }
  }, [ setValue ] );

  return (
    <Fragment>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountFirstName"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountFirstName || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.firstName' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                isInvalid={ errors.accountFirstName !== undefined }
                placeholder={ t( 'bookingFunnel.personalData.firstName' ) }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.firstName' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountLastName"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountLastName || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.lastName' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                isInvalid={ errors.accountLastName !== undefined }
                placeholder={ t( 'bookingFunnel.personalData.lastName' ) }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.lastName' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountEmail"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountEmail || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.email' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="email"
                isInvalid={ errors.accountEmail !== undefined }
                placeholder={ t( 'bookingFunnel.personalData.email' ) }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.email' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      { isSetupPayment && (
        <Col md={ 12 } className="mb-4">
          <Controller
            name="isSetupPayment"
            control={ control }
            rules={ { required: false } }
            defaultValue={ '****' }
            render={ ( props ) => (
              <FormGroup controlId={ props.name }>
                <Form.Label>
                  { t( 'bookingFunnel.paymentData.accountIban' ) }
                </Form.Label>
                <Form.Control
                  { ...props }
                  type="text"
                  readOnly={ true }
                  placeholder={ t( 'bookingFunnel.personalData.accountIban' ) }
                />
              </FormGroup>
            ) }
          />
        </Col>
      ) }
      { !isSetupPayment && (
        <Col md={ 12 } className="mb-4">
          <Controller
            name="paymentDataValid"
            control={ control }
            rules={ { required: true } }
            defaultValue={ paymentFields.paymentDataValid || '' }
            render={ ( props ) => (
              <FormGroup controlId={ props.name }>
                <Form.Label>
                  { t( 'bookingFunnel.paymentData.accountIban' ) }
                </Form.Label>
                <Form.Control
                  type="hidden"
                  isInvalid={ errors.paymentDataValid !== undefined }
                />
                <IbanElement
                  options={ { supportedCountries: [ 'SEPA' ] } }
                  onChange={ onChangeIban }
                />
                <Form.Control.Feedback type="invalid">
                  { t( 'base:forms.messages.fieldRequired',
                    { fieldLabel: t( 'bookingFunnel.paymentData.accountIban' ) } ) }
                </Form.Control.Feedback>
              </FormGroup>
            ) }
          />
        </Col>
      ) }
    </Fragment>
  );
};
