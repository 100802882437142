import React, { Fragment } from 'react';
import { Col, Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PaymentCoreStepItem } from '../../../PaymentCoreStep';
import classNames from 'classnames';
import { useIbanHook } from '../../../../../../../utils/IbanHook';
import 'assets/styles/stripe.css';

export interface IbanFieldsProps {
  paymentFields: PaymentCoreStepItem;
  isSetupPayment: boolean;
};

export const IbanFields: React.FC<IbanFieldsProps> = ( { paymentFields, isSetupPayment } ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control, getValues, setValue } = useFormContext();
  const utils = useIbanHook();

  const onChangeIban = React.useCallback( ( e: React.FormEvent<HTMLInputElement> ) => {
    const element = e.target as HTMLInputElement;
    let value: string = element.value || '';

    if ( value ) {
      value = ( value.replace( /\s+/g, '' ).match( /.{1,4}/g ) || [] ).join( ' ' );
    }

    setValue( 'accountIban', value, { shouldValidate: true } );
  }, [ setValue ] );

  return (
    <Fragment>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountFirstName"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountFirstName || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.firstName' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                isInvalid={ errors.accountFirstName !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.firstName' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountLastName"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountLastName || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.lastName' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                isInvalid={ errors.accountLastName !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.lastName' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountEmail"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountEmail || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.email' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="email"
                isInvalid={ errors.accountEmail !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.email' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      { isSetupPayment && (
        <Col md={ 12 } className="mb-4">
          <Controller
            name="isSetupPayment"
            control={ control }
            rules={ { required: false } }
            defaultValue={ '****' }
            render={ ( props ) => (
              <FormGroup controlId={ props.name }>
                <Form.Label>
                  { t( 'bookingFunnel.paymentData.accountIban' ) }
                </Form.Label>
                <Form.Control
                  { ...props }
                  type="text"
                  readOnly={ true }
                />
              </FormGroup>
            ) }
          />
        </Col>
      ) }
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountIban"
          control={ control }
          rules={ {
            required: true,
            validate: ( value ) => {
              return utils.isValidAccountIban( value );
            },
          } }
          defaultValue={ paymentFields.accountIban || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name } className="stripe-iban-container">
              <Form.Label id={ `${props.name}-label` }>
                { t( 'bookingFunnel.paymentData.accountIban' ) }
              </Form.Label>
              <div className="StripeElement">
                <div className={ classNames( 'iban-input', !!errors[props.name] ? 'is-invalid'
                  : ( !!getValues( props.name ) ? 'is-complete' : '' ) ) }
                >
                  <span className="bank-icon is-loaded">{ utils.getStripeBankIcon( !!errors[props.name] ) }</span>
                  <Form.Control
                    { ...props }
                    onChange={ onChangeIban }
                    type="text"
                    placeholder={ 'DE00 0000 0000 0000 0000 00' }
                    isInvalid={ errors[props.name] !== undefined }
                  />
                </div>
              </div>
              { errors[props.name] && (
                <div className="invalid-feedback d-block">
                  { errors[props.name]?.message ? ( errors[props.name]?.message
                  ) : (
                    <Fragment>
                      { t( 'base:forms.messages.fieldRequired',
                        { fieldLabel: t( 'bookingFunnel.paymentData.accountName' ) } ) }
                    </Fragment>
                  ) }
                </div>
              ) }
            </FormGroup>
          ) }
        />
      </Col>
    </Fragment>
  );
};
