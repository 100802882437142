import { MaskFormatItem } from 'Services/widgets/interfaces';

const defaultMaskFormatChars = {
  '9': '[0-9]',
  'a': '[a-zA-ZäöüÄÖÜß]',
  '*': '[a-zA-ZäöüÄÖÜß0-9]',
};

export const preparedMaskFormatChars = ( maskFormatChars: MaskFormatItem[] ): null | {} => {
  let maskFormat: null | {} = null;

  if ( !!maskFormatChars ) {
    maskFormatChars.forEach( ( item ) => {
      maskFormat = {
        ...maskFormat,
        [item.character]: item.allowedInput,
      };
    } );
  }

  return maskFormat ? maskFormat : defaultMaskFormatChars;
};
