export enum BFStep {
  TariffSubStep1 = 'step1_tariffSubStep1',
  TariffSubStep2 = 'step1_tariffSubStep2',
  PersonalDataStep = 'step2_personalData',
  PaymentStep = 'step3_payment',
  SummaryStep = 'step4_summary',
  PaymentApprovementStep = 'step5_paymentApprovement',
}

export enum JSONSteps {
  PolicyStep = 'PolicyStep',
  SummaryStep = 'SummaryStep',
}

export enum JSONSubSteps {
  Consent = 'consent',
  Payment = 'payment'
}

export enum JSONItemUIType {
  Select = 'select',
  Radio = 'radio',
  Currency = 'currency',
  AddButton = 'addButton',
  ProductType = 'productType',
  MultiSelect = 'multiSelect',
  AddressSingleLine = 'addressSingleLine',
  AddressMultiLines = 'addressMultiLines',
  JobStatusType = 'jobStatus',
  AutoRenderObject = 'autoRenderObject',
  Checkbox = 'checkbox',
  ReactDayPicker = 'reactDayPicker',
  List = 'list',
  Age = 'age'
}

export enum JSONPremiumShowsType {
  ShowAllPremium = 'showAllPremium',
  ShowOnlyTotalPremium = 'showOnlyTotalPremium',
  ShowOnlyDetailsPremium = 'showOnlyDetailsPremium',
}

export enum JSONComponentType {
  Policy = 'policy',
  PersonalData = 'personalData',
  Summary = 'summary',
  Payment = 'payment',
  System = 'system',
}

export enum JSONPageType {
  EmailVerificationPage = 'emailVerificationPage',
  SuccessPage = 'successPage',
}
