import { BaseRequest } from '../base';
import {
  ProductPremiumResponse,
  ProductInvoiceResponse,
  IPayloadInvoice,
  IPayloadForDocumentPdf,
  LeadDataResponse,
  ICreateLeadData,
  IPolicyObjects,
  IBFInsuredObjectItem,
  IInitiateLeadResponse,
  IPaymentSystem,
  IPaymentSystemComplete,
  ICompletePaymentSetupResponse,
  IInitiatePaymentSetup,
  IProductDataForInvoiceWS,
  ProductInvoiceWSResponse,
  CustomApplicationResponse,
  ICustomApplicationWS,
  ICreateLeadDataWS,
  IInitiateAuthResponse,
  IInitiateAuth,
  IRespondAuth,
  IRespondAuthResponse,
  ISmsCodeAuthResponse,
  ICustomerResponse,
  IInsuranceTypeItem,
  IApiListResponse,
  IStaticDocumentItem,
  IBFCustomStylesSettingsResponse,
  SendEmailResponse,
  SendEmailPayload,
  IPSPConfig,
  AddressCompletionResponse,
  AddressItem,
  WorkflowResponse,
  VariableResponse,
  PayloadRule,
  ValidateAddress,
  ProductResponse,
  GetProductDocumentUrlResponse,
  IProductDocumentItem,
  EmailVerificationResponse,
  InitiateEmailVerification,
  CompleteEmailVerification,
  AuthResponse,
  PreSignedPost,
  PreSignedPostRequest,
  FilterNamedRangeRequest,
  FilterNamedRangeResponse,
  BookingFunnelSettingsResponse,
} from './interfaces';
import { ICFFactorType } from 'App/components/widgets/factor-service';

export class GetFactorsRequest extends BaseRequest<ICFFactorType[]> {
  constructor( productCode: string, allValues: boolean ) {
    super( {
      method: 'GET',
      url: `/publicapi/v1/products/${ productCode }/product-factors${!allValues ? '?all_values=0' : ''}`,
      responseType: 'json',
    } );
  }
}

export class GetInsuredObjectsRequest extends BaseRequest<IBFInsuredObjectItem[]> {
  constructor( productCode: string ) {
    super( {
      method: 'GET',
      url: `/publicapi/v1/products/${ productCode }/insured-objects`,
      responseType: 'json',
    } );
  }
}

export class ProductPremiumRequest extends BaseRequest<ProductPremiumResponse> {
  constructor(
    productCode: string,
    policyObjects: IPolicyObjects,
  ) {

    super( {
      method: 'POST',
      url: `/publicapi/v1/products/${ productCode }/product-premiums`,
      data: policyObjects,
      responseType: 'json',
    } );
  }
}

export class ProductInvoiceRequest extends BaseRequest<ProductInvoiceResponse> {
  constructor(
    productCode: string,
    payload: IPayloadInvoice,
  ) {

    super( {
      method: 'POST',
      url: `/publicapi/v1/products/${ productCode }/product-invoice`,
      data: payload,
      responseType: 'json',
    } );
  }
}

export class DownloadPdfDocumentRequest extends BaseRequest<BlobPart> {
  constructor(
    payload: IPayloadForDocumentPdf,
  ) {

    super( {
      method: 'POST',
      url: '/publicapi/v1/documents',
      data: payload,
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf',
      },
    } );
  }
}

export class DownloadStaticPdfDocumentRequest extends BaseRequest<BlobPart> {
  constructor(
    documentCode: string,
  ) {

    super( {
      method: 'GET',
      url: `/publicapi/v1/documents/download/${ documentCode }`,
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf',
      },
    } );
  }
}

export class GetProductDocumentUrlRequest extends BaseRequest<GetProductDocumentUrlResponse> {
  constructor(
    productCode: string,
    documentCode: string,
  ) {

    super( {
      method: 'GET',
      url: `/publicapi/v1/documents/product/${ productCode }/${ documentCode }/download-url`,
      responseType: 'json',
    } );
  }
}

export class CreateLeadRequest extends BaseRequest<LeadDataResponse> {
  constructor(
    leadData: ICreateLeadData | ICreateLeadDataWS,
    bearerToken?: string,
  ) {

    let headers = {};

    if ( bearerToken ) {
      headers = {
        'Authorization': `Bearer ${bearerToken}`,
      };
    }

    super( {
      method: 'POST',
      url: '/publicapi/v1/leads',
      data: leadData,
      responseType: 'json',
      headers,
    } );
  }
}

export class UpdateLeadRequest extends BaseRequest<LeadDataResponse> {
  constructor(
    leadData: ICreateLeadData,
    bearerToken?: string,
  ) {

    let headers = {};

    if ( bearerToken ) {
      headers = {
        'Authorization': `Bearer ${bearerToken}`,
      };
    }

    super( {
      method: 'PUT',
      url: `/publicapi/v1/leads/${leadData.code}`,
      data: leadData,
      responseType: 'json',
      headers,
    } );
  }
}

export class GetBFCustomStylesSettingsRequest extends BaseRequest<IBFCustomStylesSettingsResponse> {
  constructor(
    productCode: string,
  ) {

    super( {
      method: 'GET',
      url: `/publicapi/v1/products/${ productCode }/custom-css`,
      responseType: 'json',
    } );
  }
}

export class ListInsuredObjectTypeRequest extends BaseRequest<IInsuranceTypeItem[]> {
  constructor(
    productCode: string,
  ) {
    const queryParams = new URLSearchParams();

    // Set default Page size and Page token
    queryParams.set( 'pageSize', '50' );

    super( {
      method: 'GET',
      url: `/publicapi/v1/products/${ productCode }/insured-object-types`,
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class InitiateLeadRequest extends BaseRequest<IInitiateLeadResponse> {
  constructor(
    productSlug?: string,
  ) {

    super( {
      method: 'POST',
      url: productSlug ? `/publicapi/v1/leads/initiate/${productSlug}` : '/publicapi/v1/leads/initiate',
      data: {},
      responseType: 'json',
    } );
  }
}

export class InitiatePaymentSetupRequest extends BaseRequest<IInitiatePaymentSetup> {
  constructor(
    initiateData: IPaymentSystem,
  ) {

    super( {
      method: 'POST',
      url: '/publicapi/v1/payment-setup/initiate',
      data: initiateData,
      responseType: 'json',
    } );
  }
}

export class CompletePaymentSetupRequest extends BaseRequest<ICompletePaymentSetupResponse> {
  constructor(
    paymentSetup: IPaymentSystemComplete,
  ) {
    let leadCode = '';

    if ( paymentSetup.stripe ) {
      leadCode = paymentSetup.stripe.leadCode;
    }

    if ( paymentSetup.braintree ) {
      leadCode = paymentSetup.braintree.leadCode;
    }

    super( {
      headers: { 'Idempotency-Key': leadCode },
      method: 'POST',
      url: '/publicapi/v1/payment-setup/complete',
      data: paymentSetup,
      responseType: 'json',
    } );
  }
}

export class GetStaticDocumentsRequest extends BaseRequest<IApiListResponse<IStaticDocumentItem>> {
  constructor() {

    super( {
      method: 'GET',
      url: '/publicapi/v1/documents?filter=templateSlug=static&pageSize=30',
      responseType: 'json',
    } );
  }
}

export class GetProductDocumentsRequest extends BaseRequest<IApiListResponse<IProductDocumentItem>> {
  constructor( productCode: string ) {
    const queryParams = new URLSearchParams();

    // Set default Page size
    queryParams.set( 'pageSize', '50' );

    super( {
      method: 'GET',
      url: `/publicapi/v1/documents/${ productCode }?${queryParams.toString()}`,
      responseType: 'json',
    } );
  }
}

export class ProductInvoiceWSRequest extends BaseRequest<ProductInvoiceWSResponse> {
  constructor(
    productCode: string,
    payload: IProductDataForInvoiceWS,
  ) {

    super( {
      method: 'POST',
      url: `/publicapi/v1/products/${ productCode }/product-invoice`,
      data: payload,
      responseType: 'json',
    } );
  }
}

export class CreateCustomApplicationRequest extends BaseRequest<CustomApplicationResponse> {
  constructor(
    productCode: string,
    payload: ICustomApplicationWS,
  ) {

    super( {
      method: 'POST',
      url: `/publicapi/v1/products/${ productCode }/custom-application`,
      data: payload,
      responseType: 'json',
    } );
  }
}

export class InitiateAuthRequest extends BaseRequest<IInitiateAuthResponse> {
  constructor(
    loginRequest: IInitiateAuth,
  ) {
    super( {
      method: 'POST',
      url: '/v1/customers/auth/initiate',
      data: loginRequest,
      responseType: 'json',
    }, false );
  }
}

export class RespondAuthRequest extends BaseRequest<IRespondAuthResponse> {
  constructor(
    loginRequest: IRespondAuth,
  ) {

    super( {
      method: 'POST',
      url: '/v1/customers/auth/respond',
      data: loginRequest,
      responseType: 'json',
    }, false );
  }
}

export class RespondBySmsCodeAuthRequest extends BaseRequest<ISmsCodeAuthResponse> {
  constructor(
    loginRequest: IRespondAuth,
  ) {

    super( {
      method: 'POST',
      url: '/v1/customers/auth/respond',
      data: loginRequest,
      withCredentials: true,
      responseType: 'json',
      loginRequest: true,
    }, false );
  }
}

export class AuthRequest extends BaseRequest<AuthResponse> {
  constructor(
    loginRequest: IInitiateAuth,
  ) {

    super( {
      method: 'POST',
      url: '/v1/customers/auth/initiate',
      data: loginRequest,
      withCredentials: true,
      responseType: 'json',
      loginRequest: true,
    }, false );
  }
}

export class GetCustomerInfoRequest extends BaseRequest<ICustomerResponse> {
  constructor(
  ) {

    super( {
      method: 'GET',
      url: '/v1/customers/me?expand=account',
      responseType: 'json',
    } );
  }
}

export class GetLeadDataRequest extends BaseRequest {
  constructor(
    leadCode: string,
  ) {

    super( {
      method: 'GET',
      url: `/publicapi/v1/leads/${ leadCode }`,
      responseType: 'json',
    } );
  }
}

export class SendEmailRequest extends BaseRequest<SendEmailResponse> {
  constructor(
    payloadData: SendEmailPayload,
  ) {

    super( {
      method: 'POST',
      url: '/publicapi/v1/emails/send',
      data: payloadData,
      responseType: 'json',
    } );
  }
}

export class InitiatePaymentMethodRequest extends BaseRequest<IInitiatePaymentSetup> {
  constructor(
    code?: string,
  ) {
    const customerCode = code ? code : 'me';
    super( {
      method: 'POST',
      url: `/v1/customers/${customerCode}/payment_setup/initiate`,
      data: {},
      responseType: 'json',
    } );
  }
}

export class CompletePaymentDataRequest extends BaseRequest<ICompletePaymentSetupResponse> {
  constructor(
    paymentSetup: IPaymentSystemComplete,
    code?: string,
  ) {
    const customerCode = code ? code : 'me';

    super( {
      method: 'POST',
      url: `/v1/customers/${customerCode}/payment_setup/complete`,
      data: paymentSetup,
      responseType: 'json',
    } );
  }
}

export class GetPSPConfig extends BaseRequest<IPSPConfig> {
  constructor(
  ) {

    super( {
      method: 'GET',
      url: '/publicapi/v1/payment-setup/get-psp-config',
      responseType: 'json',
    } );
  }
}

export class RefreshTokenEISRequest extends BaseRequest {
  constructor(
    userName: string,
  ) {
    super( {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: '/authservice/v1/refresh-token',
      data: { username: userName },
      withCredentials: true,
      responseType: 'json',
      loginRequest: true,
    }, false );
  }
}

export class GetAddressCompletionsRequest extends BaseRequest<AddressCompletionResponse> {
  constructor(
    partialAddress: string,
    country?: string,
  ) {

    const queryParams = new URLSearchParams();
    if ( partialAddress ) {
      queryParams.set( 'partialAddress', partialAddress );
    }

    if ( country ) {
      queryParams.set( 'country', country );
    }

    super( {
      method: 'GET',
      url: `/publicapi/v1/addresses/completions?${queryParams.toString()}`,
      responseType: 'json',
    } );
  }
}

export class ValidateAddressRequest extends BaseRequest<ValidateAddress> {
  constructor(
    addressItem: AddressItem,
  ) {
    const queryParams = new URLSearchParams();
    if ( addressItem.street ) {
      queryParams.set( 'street', addressItem.street );
    }

    if ( addressItem.houseNumber ) {
      queryParams.set( 'houseNumber', addressItem.houseNumber );
    }

    if ( addressItem.postalCode ) {
      queryParams.set( 'postalCode', addressItem.postalCode );
    }

    if ( addressItem.city ) {
      queryParams.set( 'city', addressItem.city );
    }

    if ( addressItem.countryName ) {
      queryParams.set( 'country', addressItem.countryName );
    }

    super( {
      method: 'GET',
      url: `/publicapi/v1/addresses/validation?${queryParams.toString()}`,
      responseType: 'json',
    } );
  }
}

export class GetWorkflowBySlugRequest extends BaseRequest<IApiListResponse<WorkflowResponse>> {
  constructor(
    workflowSlug: string,
  ) {
    const queryParams = new URLSearchParams();

    if ( workflowSlug ) {
      queryParams.set( 'filter', `slug=${workflowSlug}` );
    }

    super( {
      method: 'GET',
      url: `/processmanager/v1/workflows?${queryParams.toString()}`,
      params: queryParams,
    } );
  }
}

export class GetUnderwritingRules extends BaseRequest<VariableResponse> {
  constructor(
    workflowCode: string,
    payloadData: PayloadRule,
  ) {

    super( {
      method: 'POST',
      url: `/processmanager/v1/workflows/${workflowCode}/trigger-sync`,
      data: { payload: payloadData },
      responseType: 'json',
    } );
  }
}

export class GetProductByCodeRequest extends BaseRequest<ProductResponse> {
  constructor(
    productCode: string,
  ) {
    const queryParams = new URLSearchParams();

    if ( productCode ) {
      queryParams.set( 'filter', `code=${productCode}` );
    }

    super( {
      method: 'GET',
      url: `/publicapi/v1/products?${queryParams.toString()}`,
      params: queryParams,
    } );
  }
}

const TYPE_PRE_APPLICATION = 'pre-application';

export class GetStaticDocumentsByProductCodeRequest extends BaseRequest<IApiListResponse<IStaticDocumentItem>> {
  constructor(
    productCode: string,
  ) {
    const queryParams = new URLSearchParams();

    if ( productCode ) {
      queryParams.set( 'filter', `type=${TYPE_PRE_APPLICATION}` );
    }

    super( {
      method: 'GET',
      url: `/publicapi/v1/documents/${productCode}`,
      params: queryParams,
      responseType: 'json',
    } );
  }
}

export class InitiateEmailVerificationRequest extends BaseRequest<EmailVerificationResponse> {
  constructor(
    payload: InitiateEmailVerification,
  ) {
    super( {
      method: 'POST',
      url: '/publicapi/v1/email-verification/initiate',
      data: payload,
      responseType: 'json',
    } );
  }
}

export class CompleteEmailVerificationRequest extends BaseRequest<EmailVerificationResponse> {
  constructor(
    payload: CompleteEmailVerification,
  ) {
    super( {
      method: 'POST',
      url: '/publicapi/v1/email-verification/complete',
      data: payload,
      responseType: 'json',
    } );
  }
}

export class PresignedUrlRequest extends BaseRequest<PreSignedPost> {
  constructor(
    data: PreSignedPostRequest,
  ) {
    super( {
      method: 'POST',
      url: '/publicapi/v1/documents/pre-signed-post',
      responseType: 'json',
      data,
    } );
  }
}

export class UploadDocumentWithPreSignedUrlRequest extends BaseRequest<Record<string, string>> {
  constructor(
    formData: FormData,
    url: string,
    contentType: string,
    onUploadProgress?: ( progressEvent: any ) => void,
  ) {
    super( {
      method: 'POST',
      url: url,
      responseType: 'json',
      onUploadProgress: onUploadProgress,
      data: formData,
      headers: {
        'Content-Type': contentType,
      },
    }, false );
  }
}

export class DeleteUploadedDocument extends BaseRequest<void> {
  constructor(
    documentCode: string,
    leadCode: string,
  ) {
    super( {
      method: 'DELETE',
      url: `/publicapi/v1/documents/${ documentCode }/lead/${ leadCode }`,
      responseType: 'json',
      data: {},
    } );
  }
}

export class FilterNamedRange extends BaseRequest<FilterNamedRangeResponse> {
  constructor( request: FilterNamedRangeRequest ) {
    const { productSlug, name, filters, search, order, pageSize, pageToken } = request;

    const queryParams = new URLSearchParams();
    queryParams.set( 'productSlug', productSlug );
    queryParams.set( 'name', name );

    if ( filters ) {
      queryParams.set( 'filters', filters );
    }

    if ( search ) {
      queryParams.set( 'search', search );
    }

    if ( order ) {
      queryParams.set( 'order', order );
    }

    if ( pageSize ) {
      queryParams.set( 'pageSize', `${pageSize}` );
    }

    if ( pageToken ) {
      queryParams.set( 'pageToken', pageToken );
    }

    super( {
      method: 'GET',
      url: `/publicapi/v1/named-ranges/records?${queryParams.toString()}`,
      responseType: 'json',
    } );
  }
}

export class GetBookingFunnelSettingsRequest extends BaseRequest<BookingFunnelSettingsResponse> {
  constructor(
    code: string,
  ) {

    super( {
      method: 'GET',
      url: `/publicapi/v1/booking-funnels/${code}`,
      responseType: 'json',
    } );
  }
}
