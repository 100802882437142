import React, { Fragment, ReactElement } from 'react';
import dayjs from 'dayjs';
import arrowUpIconUrl from 'assets/bf-images/core/arrow_up.svg';
import arrowDnIconUrl from 'assets/bf-images/core/arrow_dn.svg';
import { blankLink } from 'config';
import { useTranslation } from 'react-i18next';
import {
  ContainerFieldItem,
  IBFInsuredObjectItem,
  IBFProductVariable,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { isEmpty } from 'lodash';
import { FIELD_TYPE_BOOL, FIELD_TYPE_DATETIME, isObject } from 'App/components/widgets/bf-hooks';
import { customDateFormat, defaultDateFormat } from 'App/ui-utils';
import { ICFFactorType } from 'App/components/widgets/factor-service';
import { ProductFieldTypeEntity } from 'Services/widgets/enums';

interface ObjectProps {
  stepItem: ContainerFieldItem;
  factors: ICFFactorType[];
  tariffData: ITariffDataStep;
  multiInsuredObjects: IBFInsuredObjectItem[];
  insuredObjectName: string;
  nameStep: string;
}

export const CoreObjectList: React.FC<ObjectProps> = (
  { stepItem, factors, tariffData, multiInsuredObjects, insuredObjectName, nameStep },
) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const showLabelForEveryGroup: boolean = stepItem && stepItem['showLabelForEveryGroup'];
  const labelGroup = stepItem && stepItem.label;

  const [ isShowAllList, setShowAllLis ] = React.useState<boolean>( showLabelForEveryGroup || false );
  const startOrder = stepItem && stepItem['startOrder'];

  const onShowList = React.useCallback ( ( isShow: boolean ): void => {
    setShowAllLis( isShow );
  }, [] );

  const savedMultiInsuredObjects = multiInsuredObjects?.filter(
    ( o ) => o.name.startsWith( `${insuredObjectName}_` )
  );
  const firstObject = savedMultiInsuredObjects[0];
  const allObjects = savedMultiInsuredObjects.filter( ( obj ) => obj.name !== firstObject.name );

  const getValueFactorField = React.useCallback( ( variable: IBFProductVariable ): ReactElement | string => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = tariffData[fieldName];

    if ( isObject( selectedVal ) && selectedVal['name'] ) {
      return selectedVal['name'];
    }

    if ( selectedVal ) {
      const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
      if ( findFactor ) {
        const factorInfo = findFactor?.values.find( ( f ) => f.key === selectedVal )!;
        return factorInfo?.name;
      }
    }

    return '-';
  }, [ factors, tariffData ] );

  const getValueBaseField = React.useCallback( ( variable: IBFProductVariable ): React.ReactElement => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = tariffData[fieldName];

    if ( variable.typeId === FIELD_TYPE_BOOL && selectedVal ) {
      return ( <p>{ selectedVal ? t( 'bookingFunnel.tariff.yes' ) : t( 'bookingFunnel.tariff.no' ) }</p> );
    }

    if ( variable.typeId === FIELD_TYPE_DATETIME && selectedVal ) {
      return (
        <p
          className="date-value"
          custom-date-format={
            dayjs( selectedVal ).format( customDateFormat )
          }
        >
          <span>
            { dayjs( selectedVal ).format( defaultDateFormat ) }
          </span>
        </p>
      );
    }

    const resVal: string = selectedVal ? selectedVal.toString() : '';

    return ( <p>{ !isEmpty( resVal ) ? resVal : '-' }</p> );
  }, [ t, tariffData ] );

  return (
    <Fragment>
      { firstObject && (
        <div
          className={ `multi-insured-object ${firstObject.name}` }
        >
          { labelGroup && showLabelForEveryGroup && startOrder ? (
            <span className="multi-insured-object-title d-inline-block f-16 text-current-color">
              { `${startOrder}. ${labelGroup}` }
            </span>
          ) : (
            <>{ firstObject['label'] && (
              <span className="mt-3 d-inline-block f-16 text-current-color">
                { `${firstObject['label']} (1)` }
              </span>
            ) }
            </>
          ) }
          { stepItem && stepItem.items && stepItem.items.map( ( field, fieldIdx ) => {
            const foundProductField = firstObject.productFields?.find(
              ( f ) => `${f.name}_${f.groupName}` === `${field.fieldName}_${f.groupName}` && !f.isHidden,
            );

            if ( !foundProductField ) {
              return <Fragment key={ fieldIdx }></Fragment>;
            }

            return (
              <div
                key={ fieldIdx }
                id={ `${foundProductField.groupName}-${foundProductField.name}-${fieldIdx+1}` }
                className="details-section mx-4"
              >
                <p className="bf-selected-val f-14">{ foundProductField.label }</p>
                { foundProductField.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
                  <>{ getValueBaseField( foundProductField ) }</>
                ) }
                { foundProductField.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
                  <p>{ getValueFactorField( foundProductField ) }</p>
                ) }
              </div>
            );
          } ) }
        </div>
      ) }
      { isShowAllList && allObjects && allObjects.map( ( item, idx ) => (
        <div
          key={ idx }
          className={ `multi-insured-object ${item.name}` }
        >
          { labelGroup && showLabelForEveryGroup && startOrder ? (
            <span className="multi-insured-object-title d-inline-block f-16 text-current-color">
              { `${ startOrder + 1 + idx }. ${labelGroup}` }
            </span>
          ) : (
            <>
              { item['label'] && (
                <span className="mt-3 d-inline-block f-16 text-current-color">
                  { `${item['label']} (${idx + 2})` }
                </span>
              ) }
            </>
          ) }
          { stepItem && stepItem.items && stepItem.items.map( ( field, fieldIdx ) => {
            const foundProductField = item.productFields?.find(
              ( f ) => `${f.name}_${f.groupName}` === `${field.fieldName}_${f.groupName}` && !f.isHidden,
            );

            if ( !foundProductField ) {
              return <Fragment key={ fieldIdx }></Fragment>;
            }

            return (
              <div
                key={ fieldIdx }
                id={ `${foundProductField.groupName}-${foundProductField.name}-${fieldIdx+1}` }
                className="details-section mx-4"
              >
                <p className="bf-selected-val f-14">{ foundProductField.label }</p>
                { foundProductField.typeEntity === ProductFieldTypeEntity.SystemProductFieldType && (
                  <>{ getValueBaseField( foundProductField ) }</>
                ) }
                { foundProductField.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
                  <p>{ getValueFactorField( foundProductField ) }</p>
                ) }
              </div>
            );
          } ) }
        </div>
      ) ) }
      { !showLabelForEveryGroup && allObjects.length >= 1 && (
        <div className="details-section d-flex justify-content-center">
          <a
            href={ blankLink }
            role='button'
            onClick={ ( e ) => {
              e.preventDefault();
              onShowList( !isShowAllList );
            } }
          >
            { isShowAllList ? (
              <Fragment>
                { t( 'bookingFunnel.funkReanovo.btnLess' ) }
                <img className="pl-2" src={ arrowUpIconUrl } alt="Icon Up" />
              </Fragment>
            ) : (
              <Fragment>
                { `${ t( 'bookingFunnel.funkReanovo.btnMore' )} +${ allObjects.length }` }
                <img className="pl-2" src={ arrowDnIconUrl } alt="Icon Down" />
              </Fragment>
            ) }
          </a>
        </div>
      ) }
    </Fragment>
  );
};
