import React from 'react';
import { Col, Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PaymentCoreStepItem } from '../../../PaymentCoreStep';
import { AdyenCheckout, Card, CardConfiguration, CoreConfiguration, UIElement } from '@adyen/adyen-web';
import { useAppLogger } from 'Services/logger';
import { Controller, useFormContext } from 'react-hook-form';
import CardBrands from './card_brands.json';

export interface CardFieldsProps {
  paymentFields: PaymentCoreStepItem;
  adyenConfiguration: CoreConfiguration;
  onInit: ( component: UIElement ) => void;
  onLoaded: ( ) => void;
};

export const CardFields: React.FC<CardFieldsProps> = ( { adyenConfiguration, paymentFields, onInit, onLoaded } ) => {
  const logger = useAppLogger();
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control } = useFormContext();
  const isFirstLoad = React.useRef<boolean>( true );

  const onConfigSuccess = React.useCallback( (): void => {
    onLoaded();
  }, [ onLoaded ] );

  const getCardConfiguration = React.useCallback( (): CardConfiguration => {
    return {
      billingAddressMode: 'none',
      brands: CardBrands,
      showBrandIcon: true,
      showPayButton: false,
      onConfigSuccess,
    };
  }, [ onConfigSuccess ] );

  React.useEffect( () => {
    const initializeAdyenCard = async () => {
      try {
        if ( isFirstLoad.current ) {
          const cardConfiguration = getCardConfiguration();
          const checkout = await AdyenCheckout( adyenConfiguration );
          onInit(
            ( new Card( checkout, cardConfiguration ) )
              .mount( '#card-container' ).update( {} ),
          );
          isFirstLoad.current = false;
        }
      } catch ( error ) {
        logger.error( 'Error initializing Adyen Checkout:', error );
      }
    };

    initializeAdyenCard();
  }, [ adyenConfiguration, getCardConfiguration, logger, onInit ] );

  return (
    <>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountFirstName"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountFirstName || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.firstName' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                isInvalid={ errors.accountFirstName !== undefined }
                placeholder={ t( 'bookingFunnel.personalData.firstName' ) }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.firstName' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountLastName"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountLastName || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.lastName' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                isInvalid={ errors.accountLastName !== undefined }
                placeholder={ t( 'bookingFunnel.personalData.lastName' ) }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.lastName' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountEmail"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountEmail || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.email' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="email"
                isInvalid={ errors.accountEmail !== undefined }
                placeholder={ t( 'bookingFunnel.personalData.email' ) }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.email' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } id="card-container" className="mt-4"></Col>
    </>
  );
};
