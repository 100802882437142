import 'assets/scss/partials/_bf-file-upload.scss';
import React from 'react';
import useScreenType from 'react-screentype-hook';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import * as qs from 'query-string';
import HashTabs from 'App/components/utils/HashTabs';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tab } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { ApiErrorResponse, ILeadData, NavigatorItem, StepItem } from 'Services/widgets/interfaces';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { useWidgetService } from 'Services/widget';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { setCustomStyles } from 'App/ui-utils';
import {
  filteredSteps,
  generateMultiObjects,
  generatePolicyObject,
  generateSavedData,
  generateSteps,
  generateVariables,
  keyAccountCode,
  keyCustomFields,
  keyIsDisabledSaveForLater,
  keyMultiInsuredObjects,
  partnerKey,
} from './core-hooks';
import { IDataFactorsAndVariables } from '../booking-funnel/BookingFunnel';
import { isEmpty, cloneDeep } from 'lodash';
import { CoreSuccessPage } from './core-booking-funnel/core-ui-components/CoreSuccessPage';
import { InvalidLinkPage } from './ui-components/InvalidLinkPage';
import { LoadPage } from './ui-components/LoadPage';
import { PolicyFieldItem, StepInfoItem } from './interfaces';
import { JSONComponentType } from '../booking-funnel/enum';
import {
  ActiveStepCore,
  ActiveSubStepCore,
  CoreActions,
  DynamicCoreActionsContext,
  RecalculationTariff,
} from './DynamicCore';
import { CoreNavigationMobile } from './core-booking-funnel/core-ui-components/CoreNavigationMobile';
import { keyCoreInvoiceResult } from './core-booking-funnel/core-ui-components/CoreTariffInfo';
import { Configuration } from 'App/widget/types';
import { NavigatorType, TooltipView } from 'Services/widgets/enums';
import { getParamUrlByName } from '../bf-hooks';
import { JsonValue } from '@cover42/protobuf-util';
import { personalDataCoreKey } from './core-booking-funnel/steps/personal-sub-steps/RegistrationCoreStep';
import { useAppLogger } from 'Services/logger';
import {
  isGA4Loaded,
  isHubSpotLoaded,
  isUniversalAnalyticsLoaded,
  trackStepLoadedInGA,
  trackStepLoadedInHubSpot,
} from './analytics-hooks';

const PolicyCoreStepComponent = React.lazy( () =>
  import( './core-booking-funnel/steps/PolicyCoreStep' )
    .then( ( { PolicyCoreStep } ) => ( { default: PolicyCoreStep } ) ),
);

const PersonalCoreStepComponent = React.lazy( () =>
  import( './core-booking-funnel/steps/PersonalCoreStep' )
    .then( ( { PersonalCoreStep } ) => ( { default: PersonalCoreStep } ) ),
);

const SystemCoreStepComponent = React.lazy( () =>
  import( './core-booking-funnel/steps/SystemCoreStep' )
    .then( ( { SystemCoreStep } ) => ( { default: SystemCoreStep } ) ),
);

const PaymentCoreStepComponent = React.lazy( () =>
  import( './core-booking-funnel/steps/PaymentCoreStep' )
    .then( ( { PaymentCoreStep } ) => ( { default: PaymentCoreStep } ) ),
);

const SummaryCoreStepComponent = React.lazy( () =>
  import( './core-booking-funnel/steps/SummaryCoreStep' )
    .then( ( { SummaryCoreStep } ) => ( { default: SummaryCoreStep } ) ),
);

export interface CoreBookingFunnelProps {
  productCode: string;
  productName: string;
  tenantSlug: string;
  configuration?: Configuration;
}

export const paymentDataCoreKey = 'paymentData';
const siteUrlSandbox = '/';
const analyticsLimitRequest = 20;

const CoreBookingFunnel: React.FC<CoreBookingFunnelProps> = (
  { productCode, productName, tenantSlug, configuration },
) => {
  const { t, i18n } = useTranslation( [ 'widgets', 'base' ] );
  const screenType = useScreenType();
  const history = useHistory();
  const location = useLocation();
  const service = useWidgetService();
  const logger = useAppLogger();

  const { pathname, search } = location;
  const { hideAlert } = useAppAlertService();
  const {
    accountData, homeSiteUrl, isNewLead, bookingFunnelCode, bookingFunnelModeView, leadStatuses,
  } = configuration || {};
  const [ errorMessage, setErrorMessage ] = React.useState<string | null>( null );
  const [ lead, setLead ] = React.useState<ILeadData | null>( null );
  const [ bfLogo, setBFLogo ] = React.useState<string>( '' );
  const [ bfLayout, setBFLayout ] = React.useState<string>( '' );
  const [ productData, setProductData ] = React.useState<IDataFactorsAndVariables | null>( null );
  const [ reloadTrigger, setReloadTrigger ] = React.useState<number>( dayjs().valueOf() );
  const [ stepsInfo, setStepsInfo ] = React.useState<StepInfoItem[] | null>( null );
  const [ policyStepNames, setPolicyStepNames ] = React.useState<string[] | null>( null );
  const [ actionPersonalStep, setActionPersonalStep ] = React.useState<string | null>( null );
  const [ recalculationTariff, setRecalculationTariff ] = React.useState<RecalculationTariff>( {
    recalculationTrigger: dayjs().valueOf(),
    isCheckedUnderwriting: true,
  } );
  const [ isSuccess, setIsSuccess ] = React.useState<boolean>( false );
  const [ isInvalidLink, setInvalidLink ] = React.useState<boolean>( false );
  const [ isShowLoadPage, setIsShowLoadPage ] = React.useState<boolean>( false );
  const [ actionSummaryStep, setActionSummaryStep ] = React.useState<ActiveSubStepCore>(
    ActiveSubStepCore.SummarySubStep1 );
  const [ isValidForm, setIsValidForm ] = React.useState<boolean>( true );
  const [ navigator, setNavigator ] = React.useState<NavigatorItem>( { type: NavigatorType.Dropdown } );
  const [ leadNumber, setLeadNumber ] = React.useState<string>( '' );
  const [ isTrackStepLoadedGA, setIsTrackStepLoadedGA ] = React.useState<boolean>( false );
  const [ isTrackStepLoadedHS, setIsTrackStepLoadedHS ] = React.useState<boolean>( false );
  const isRejected = React.useRef<boolean>( false );
  const previousLimitRequestGA = React.useRef<number>( 0 );
  const previousLimitRequestHS = React.useRef<number>( 0 );

  const isLoadingPremiumData = React.useRef<boolean>( false );
  const mainSiteUrl = siteUrlSandbox;

  const lng = i18n.language;
  if ( lng === 'de' ) {
    dayjs.locale( 'de' );
  } else {
    dayjs.locale( 'en-gb' );
  }

  const getStepHash = React.useCallback( (): string | undefined => {
    const parsed = qs.parse( window.location.hash, { decode: false } );
    return parsed['step'];
  }, [] );

  const getFullUrl = React.useCallback( ( stepName: string, groupName?: string ): string => {
    let fullPath: string = `${pathname}${search}#step=${stepName}`;

    const leadCode = getParamUrlByName( 'leadCode' );
    if ( leadCode ) {
      fullPath = `${fullPath}&leadCode=${leadCode}`;
    }

    if ( groupName ) {
      fullPath = `${fullPath}&containerGroup=${groupName}`;
    }

    return fullPath;
  }, [ pathname, search ] );

  const getCurrentStepInfo = React.useCallback( ( ): StepInfoItem => {
    const currentHash = getStepHash();
    let currentItem = stepsInfo?.find( ( c ) => c.nameStep === currentHash )!;

    if ( !currentItem && stepsInfo ) {
      history.push( getFullUrl( stepsInfo[0].nameStep ) );
      currentItem = stepsInfo?.find( ( c ) => c.nameStep === stepsInfo[0].nameStep )!;
    }

    return currentItem;
  }, [ getFullUrl, getStepHash, history, stepsInfo ] );

  const onUpdatedAccountData = React.useCallback( async (): Promise<void> => {
    if ( accountData ) {
      const customFields = accountData.hasOwnProperty( keyCustomFields ) ?
        JSON.parse( accountData[keyCustomFields] as string ) : {};

      delete accountData[keyCustomFields];

      const personalData = {
        ...accountData,
        ...customFields,
      };

      await service.savedInfo( personalDataCoreKey, personalData );

      if ( accountData.hasOwnProperty( keyAccountCode ) ) {
        await service.savedInfo( keyAccountCode, accountData[keyAccountCode] as string );
        await service.savedInfo( keyIsDisabledSaveForLater, true );
      }
    }

  }, [ accountData, service ] );

  const trackStepLoaded = React.useCallback( ( steps: StepInfoItem[] | null, nameStep?: string ): void => {
    const currentStepInfo = getCurrentStepInfo( );
    const currentNameStep = nameStep ? nameStep : currentStepInfo.nameStep;

    const selectedStep = steps?.find( ( item ) => item.nameStep === currentNameStep );
    if ( selectedStep ) {
      const idx = steps?.findIndex( ( item ) => item.nameStep === currentNameStep ) || 0;
      trackStepLoadedInGA( idx+1, selectedStep.navigationTitle! );
      trackStepLoadedInHubSpot( idx+1, selectedStep.navigationTitle! );
    }
  }, [ getCurrentStepInfo ] );

  React.useEffect( () => {
    let isMounted = true;
    const loadProductData = async () => {
      let resultData: IDataFactorsAndVariables | null = null;
      const bfCode = bookingFunnelCode || getParamUrlByName( 'bookingFunnelCode' );

      try {
        const [
          customSettings,
          factorsData,
          insuredObjectsData,
          productDocuments,
          storeLead,
        ] = await Promise.all( [
          bfCode ?
            service.getBookingFunnel( bfCode ) :
            service.getBFCustomStylesSettings( productCode ),
          service.getProductFactors( productCode, false ),
          service.getInsuredObjects( productCode ),
          service.getProductDocuments( productCode ),
          service.getLead( 0 ),
        ] );

        await onUpdatedAccountData();
        const productInfo = await service.getProductByCode( productCode );
        const initiateLeadData = await service.initiateLead( productInfo?.slug );

        let initiateLead = initiateLeadData;
        let storeLeadData = storeLead;

        const stepsData = customSettings.stepsConfig;

        if( ( stepsData && stepsData.steps && stepsData.steps.length <= 0 ) || !stepsData ) {
          logger.error( 'System Error: stepsConfig is empty or not set up' );
          setErrorMessage( t( 'base:messages.systemError' ) );

          return null;
        }

        let allSteps = stepsData?.steps;
        if ( accountData && allSteps ) {
          allSteps = filteredSteps( allSteps );
        }

        const steps = generateSteps( allSteps as StepItem[] );
        const policyNames = steps.filter( ( p ) => p.componentType === JSONComponentType.Policy )
          .map( ( item ) => item.nameStep );

        const leadCode = getParamUrlByName( 'leadCode' );

        if ( leadCode ) {
          const result = await service.getLeadByCodeCore( leadCode );
          const { errorCode, isSaved } = result;

          if ( errorCode === 0 && !isSaved ) {
            initiateLead = {
              ...initiateLead,
              leadCode,
            };

            const { policy, quote, partnerLinks } = result.data;

            if ( policy && policy.policyObjects && customSettings.stepsConfig ) {
              const policyObject = generatePolicyObject( policy.policyObjects, insuredObjectsData );

              if ( !isEmpty( policyObject ) ) {
                const multiInsuredObjects = generateMultiObjects( policy.policyObjects, insuredObjectsData );
                await service.savedInfo( keyMultiInsuredObjects, multiInsuredObjects );

                const policySteps = steps.filter( ( p ) => p.componentType === JSONComponentType.Policy );

                if ( policySteps && policySteps.length ) {
                  policySteps.forEach( async ( item ) => {
                    const policyStepData = generateSavedData( policyObject, item.steps, multiInsuredObjects );

                    await service.savedInfo( item.nameStep, policyStepData );
                  } );
                }
              }
            }

            if ( partnerLinks && partnerLinks[0] ) {
              const partner = {
                partnerCode: partnerLinks[0].partnerCode,
                partnerRoleCode: partnerLinks[0].partnerRoleCode,
              };
              await service.savedInfo( partnerKey, partner );
            }

            if ( quote && quote.grossAmount && quote.currency ) {
              await service.savedInfo( keyCoreInvoiceResult, quote );
            }

            await service.savedInfo( 'isEditLead', true );

            storeLeadData = await service.getLead( 0 );
          }

          if ( errorCode === 1 ) {
            setInvalidLink( true );
          }
        } else {
          const isEditLead = storeLeadData.isEditLead;

          if ( isEditLead ) {
            storeLeadData = await service.resetLead();
          }
        }

        if ( isMounted ) {
          if ( customSettings ) {
            if ( customSettings.logo ) {
              setBFLogo( customSettings.logo );
            }

            if ( customSettings.styles ) {
              setBFLayout( 'custom-layout-1' );
              setCustomStyles( 'custom-css', customSettings.styles );
            }
          }

          if ( factorsData.length && insuredObjectsData.length && productInfo ) {
            const variableList = generateVariables( insuredObjectsData );

            setPolicyStepNames( policyNames );
            setStepsInfo( steps );

            if ( stepsData && stepsData.navigator ) {
              setNavigator( stepsData.navigator as NavigatorItem );
            }

            resultData = {
              factors: factorsData,
              insuredObjects: insuredObjectsData,
              variables: variableList.sort( ( a, b ) => a.legacyBfOrder! - b.legacyBfOrder! ),
              leadData: initiateLead,
              isCustomLayout: customSettings.styles ? true : false,
              productName,
              productSlug: productInfo.slug,
              isSetupPayment: false,
              staticDocuments: [],
              productDocuments: productDocuments || [],
              documentSettings: customSettings.documentSettings || undefined,
              stepsConfig: customSettings.stepsConfig,
              configuration: configuration,
              bookingFunnelCode: bfCode,
            };

            setProductData( resultData );
            setErrorMessage( null );

            setIsTrackStepLoadedGA( true );
            setIsTrackStepLoadedHS( true );
          } else {
            setErrorMessage( t ( 'base:messages.noData' ) );
          }

          if ( isNewLead ) {
            storeLeadData = await service.resetLead();

            if ( accountData ) {
              await onUpdatedAccountData();
              storeLeadData = await service.getLead( 0 );
            }
          }

          if ( storeLeadData ) {
            if ( customSettings && customSettings.stepsConfig ) {
              await service.savedInfo(
                'tooltipView',
                customSettings.stepsConfig.tooltipView ? customSettings.stepsConfig.tooltipView : TooltipView.Popup,
              );

              storeLeadData = await service.getLead( 0 );
            }

            setLead( storeLeadData );
          }
        }
      } catch( e ) {
        const apiError = e as unknown as ApiErrorResponse;
        if ( isMounted && apiError.error ) {
          setErrorMessage(
            `Error in loading product factors or fields with product code: ${productCode}.`+
              `The reason is: ${apiError.error.data.message}`,
          );
        } else {
          logger.error( 'System Error: ', apiError );
          setErrorMessage( t( 'base:messages.systemError' ) );
        }
      }

      return resultData;
    };

    const loadProductFactorsWithAllValues = async ( oldProductData: IDataFactorsAndVariables | null ) => {
      if ( !oldProductData ) {
        return;
      }

      try {
        const factorsData = await service.getProductFactors( productCode, true );

        if ( isMounted ) {
          if ( factorsData.length ) {
            setProductData( {
              ...oldProductData,
              factors: factorsData,
            } );
            setErrorMessage( null );
          } else {
            setErrorMessage( t ( 'base:messages.noData' ) );
          }
        }
      } catch( e ) {
        isMounted = false;
        if ( e instanceof Error ) {
          if ( isMounted ) {
            setErrorMessage(
              `Error in loading product factors or fields with product code: ${productCode}.`+
              `The reason is: ${e.message}`,
            );
          }
        } else {
          throw e;
        }
      }
    };

    loadProductData().then(
      ( data: IDataFactorsAndVariables | null ) => { loadProductFactorsWithAllValues( data ); },
    );

    return () => {
      isMounted = false;
    };
  }, [ reloadTrigger, service, productCode, t, productName, configuration, accountData, logger,
    isNewLead, onUpdatedAccountData, bookingFunnelCode ] );

  const coreBFActions = React.useMemo<CoreActions>( () => {
    return {
      reload: ( updatedProduct ): void => {
        if ( updatedProduct !== undefined ) {
          setProductData( updatedProduct );
        } else {
          setReloadTrigger( dayjs().valueOf() );
        }
      },
      goToStep: async ( stepName: string, isReloadLead: boolean ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          const leadData: ILeadData = await service.getLead( 0 );

          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( getFullUrl( stepName ) );

        if ( stepName === ActiveStepCore.Summary
          && actionSummaryStep !== ActiveSubStepCore.SummarySubStep1 ) {
          setActionSummaryStep( ActiveSubStepCore.SummarySubStep1 );
        }

        scrollToTopPage();
        trackStepLoaded( stepsInfo, stepName );
      },
      goToPersonalStep: async ( stepName: string, subStep: string | null, isReloadLead: boolean ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          const leadData: ILeadData = await service.getLead( 0 );
          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( getFullUrl( stepName ) );

        setActionPersonalStep( subStep );

        scrollToTopPage();
      },
      showLoadPage: ( isShow: boolean ): void => {
        setIsShowLoadPage( isShow );
      },
      goToSummaryStep: async (
        stepName: ActiveStepCore, subStep: ActiveSubStepCore, isReloadLead: boolean,
      ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          setLead( null );

          const leadData: ILeadData = await service.getLead( 0 );
          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( getFullUrl( stepName ) );

        setActionSummaryStep( subStep );

        scrollToTopPage();
      },
      onSubmit: ( appNumber ): void => {
        if ( appNumber ) {
          setLeadNumber( appNumber );

          const currentHash = getStepHash();
          const fullPath: string = `${pathname}${search}#step=${currentHash}&leadNumber=${appNumber}`;
          history.push( fullPath );
        }

        setIsSuccess( true );
        scrollToTopPage();
      },
      goToTopPage: (): void => {
        scrollToTopPage();
      },
      reloadLead: async ( ): Promise<void> => {
        const leadData: ILeadData = await service.getLead( 0 );
        if ( leadData ) {
          setLead( leadData );
        }

        const cloneData = cloneDeep( recalculationTariff );

        setRecalculationTariff( cloneData );
      },
      goToEditStep: async (
        stepName: string,
        groupName: string,
        isReloadLead: boolean,
      ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          const leadData: ILeadData = await service.getLead( 0 );
          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( getFullUrl( stepName, groupName ) );

        if ( groupName ) {
          setTimeout( () => {
            const productTypeId = document.getElementById( `${groupName}-box` )!;

            if ( productTypeId ) {
              productTypeId.scrollIntoView( { block: 'center' } );
            }
          }, 300 );
        }
      },
      recalculationPremium: async ( isCheckedUnderwriting: boolean ): Promise<void> => {
        if ( isCheckedUnderwriting ) {
          await service.resetUnderwritingResult();
        }

        setTimeout( () => {
          setRecalculationTariff(
            {
              recalculationTrigger: dayjs().valueOf(),
              isCheckedUnderwriting,
            },
          );
        }, 700 );
      },
      setRejected: ( rejected: boolean ): void => {
        isRejected.current = rejected;
      },
      setValidForm: ( isValid: boolean ): void => {
        setIsValidForm( isValid );
      },
      getStepsInfo: ( ): StepInfoItem[] | null => {
        return stepsInfo;
      },
      navigatorItem: ( ): NavigatorItem => {
        return navigator;
      },
      isRejected: ( ): boolean => {
        return isRejected.current;
      },
      isValidForm: ( ): boolean => {
        return isValidForm;
      },
      goToEditPersonalStep: async (
        stepName: string, subStep: string | null, groupName: string, isReloadLead: boolean,
      ): Promise<void> => {
        hideAlert();
        if ( isReloadLead ) {
          const leadData: ILeadData = await service.getLead( 0 );
          if ( leadData ) {
            setLead( leadData );
          }
        }

        history.push( getFullUrl( stepName, groupName ) );

        setActionPersonalStep( subStep );

        scrollToTopPage();
      },
      updatedStoreData: async ( fields: PolicyFieldItem[], fieldValue: JsonValue ): Promise<void> => {
        if ( !fields ) {
          return;
        }

        const leadStore: ILeadData = await service.getLead( 0 );

        fields.forEach( async ( field ) => {
          const storeKey = field.stepName;
          const currentStore = leadStore[storeKey];

          let newStore = {
            [field.fieldName]: fieldValue,
          };

          if ( currentStore ) {
            newStore = {
              ...currentStore,
              ...newStore,
            };
          }

          await service.savedInfo( storeKey, newStore );
        } );
      },
      setLoadingPremiumData: ( loaded: boolean ): void => {
        isLoadingPremiumData.current = loaded;
      },
      isLoadingPremiumData: (): boolean => {
        return isLoadingPremiumData.current;
      },
      getCurrentStep: ( ): StepInfoItem => {
        return getCurrentStepInfo( );
      },
    };
  }, [ actionSummaryStep, getCurrentStepInfo, getFullUrl, getStepHash, hideAlert, history,
    isValidForm, navigator, pathname, recalculationTariff, search, service, stepsInfo, trackStepLoaded ] );

  const onClickTabsHandler = async (): Promise<void> => {
    hideAlert();

    setLead( null );
    coreBFActions.reloadLead();

    setTimeout( () => {
      trackStepLoaded( stepsInfo );
    }, 500 );
  };

  React.useEffect( () => {
    if ( !isTrackStepLoadedGA ) {
      return;
    }

    const intervalGA = setInterval( () => {
      if ( previousLimitRequestGA.current >= analyticsLimitRequest ) {
        logger.warn( 'Google Analytics not found on the page.' );

        clearInterval( intervalGA );
        return;
      }

      const currentStepInfo = getCurrentStepInfo( );
      const currentNameStep = currentStepInfo.nameStep;

      const selectedStep = stepsInfo?.find( ( item ) => item.nameStep === currentNameStep );
      const idx = stepsInfo?.findIndex( ( item ) => item.nameStep === currentNameStep ) || 0;


      if ( ( isGA4Loaded() || isUniversalAnalyticsLoaded() ) && selectedStep ) {
        trackStepLoadedInGA( idx+1, selectedStep.navigationTitle! );
        setIsTrackStepLoadedGA( false );
        previousLimitRequestGA.current = analyticsLimitRequest;
      } else {
        previousLimitRequestGA.current = previousLimitRequestGA.current + 1;
      }

    }, 1000 );

    return () => {
      clearInterval( intervalGA );
    };
  }, [ getCurrentStepInfo, isTrackStepLoadedGA, logger, reloadTrigger, stepsInfo ] );

  React.useEffect( () => {
    if ( !isTrackStepLoadedHS ) {
      return;
    }

    const intervalHS = setInterval( () => {
      if ( previousLimitRequestHS.current >= analyticsLimitRequest ) {
        logger.warn( 'HubSpot tracking not found on the page' );

        clearInterval( intervalHS );
        return;
      }

      const currentStepInfo = getCurrentStepInfo( );
      const currentNameStep = currentStepInfo.nameStep;

      const selectedStep = stepsInfo?.find( ( item ) => item.nameStep === currentNameStep );
      const idx = stepsInfo?.findIndex( ( item ) => item.nameStep === currentNameStep ) || 0;

      if ( isHubSpotLoaded() && selectedStep ) {
        trackStepLoadedInHubSpot( idx+1, selectedStep.navigationTitle! );
        setIsTrackStepLoadedHS( false );
        previousLimitRequestHS.current = analyticsLimitRequest;
      } else {
        previousLimitRequestHS.current = previousLimitRequestHS.current + 1;
      }

    }, 1000 );

    return () => {
      clearInterval( intervalHS );
    };
  }, [ getCurrentStepInfo, isTrackStepLoadedHS, logger, reloadTrigger, stepsInfo ] );

  const onClearLeadData = async (): Promise<void> => {
    if ( policyStepNames ) {
      policyStepNames.forEach( async ( policyKey, idx ) => {
        await service.savedInfo( policyKey, null );
      } );
    }

    await service.savedInfo( keyCoreInvoiceResult, null );
    await service.savedInfo( keyMultiInsuredObjects, null );
    await service.savedInfo( ActiveStepCore.Summary, null );
  };

  const homePageHandler = ( isGoToPortal: boolean, siteUrl?: string ): void => {
    hideAlert();

    const isLogin = lead?.isLogin;

    if ( isLogin ) {
      onClearLeadData();
    }

    setLead( null );
    setIsSuccess( false );
    setLeadNumber( '' );
    setInvalidLink( false );

    if ( siteUrl ) {
      setReloadTrigger( dayjs().valueOf() );

      window.location.href = siteUrl;
      return;
    }

    if ( homeSiteUrl ) {
      window.location.href = homeSiteUrl;
      return;
    }

    setReloadTrigger( dayjs().valueOf() );

    if ( isGoToPortal ) {
      window.location.href = mainSiteUrl;
    } else {
      history.push( '/' );
    }
  };

  const scrollToTopPage = (): void => {
    const rootPage = document.getElementById( 'root' )!;

    if ( rootPage ) {
      rootPage.scrollIntoView( { block: 'start' } );

      return;
    }

    const bookingFunnelPage = document.getElementById( 'booking-funnel-page' )!;
    if ( bookingFunnelPage ) {
      bookingFunnelPage.scrollIntoView( { block: 'start' } );
    }
  };

  if ( errorMessage !== null ) {
    return (
      <div className="m-5 d-flex justify-content-center">
        <p>{ errorMessage }</p>
      </div>
    );
  }

  const loading = <div className="bf-loading"><LoadingSpinner /></div>;
  if ( lead === null ) {
    return loading;
  }

  const classNameToDesktopMode = (): string => {
    let className = bfLayout;
    if ( screenType.isLargeDesktop || screenType.isDesktop || screenType.isTablet ) {
      className = `${className} bf-desktop-mode`;
    }

    const editProduct = getParamUrlByName( 'containerGroup' );
    if ( editProduct ) {
      className = `${className} bf-edit-mode`;
    }

    return className;
  };

  const classNameToTabs = (): string => {
    let className = `booking-funnel-wizard d-flex justify-content-center ${navigator.type}`;
    const currentStep = getCurrentStepInfo( );
    if ( currentStep && currentStep.componentType === JSONComponentType.System ) {
      className = `${className} hide-nav-mode`;
    }

    return className;
  };

  const isDisabledTab = ( item: StepInfoItem ): boolean => {
    let isDisabled: boolean = false;
    const isEditLead = lead['isEditLead'];
    if ( ( lead && !lead[item.nameStep] && !isEditLead ) || isRejected || !isValidForm ) {
      isDisabled = true;
    }

    const currentStep = getCurrentStepInfo( );
    const stepsInfoData = cloneDeep( stepsInfo );
    const filterStepsInfo = stepsInfoData.filter( ( i ) => i.componentType !== JSONComponentType.System );
    const itemIndex = filterStepsInfo.findIndex( ( c ) => c.nameStep === item.nameStep );
    const currentIndex = filterStepsInfo.findIndex( ( c ) => c.nameStep === currentStep.nameStep );

    if ( currentIndex > -1 && itemIndex > -1 && !isEditLead ) {
      if ( currentIndex < itemIndex ) {
        isDisabled = true;
      }
    }

    return isDisabled;
  };

  const getTitleToTab = ( item: StepInfoItem ): React.ReactNode => {
    const stepsInfoData = cloneDeep( stepsInfo );
    const filterStepsInfo = stepsInfoData.filter( ( i ) => i.componentType !== JSONComponentType.System );
    const index = filterStepsInfo.findIndex( ( c ) => c.nameStep === item.nameStep );

    if ( index > -1 ) {
      let title: React.ReactNode = (
        <div>
          <h6>{ index + 1 }</h6>
          <p className="m-0">{ item.navigationTitle }</p>
        </div>
      );

      if ( navigator && navigator.type === NavigatorType.Circles ) {
        title = ( <span>{ index + 1 }</span> );
      }

      return title;
    }

    return '';
  };

  return (
    <DynamicCoreActionsContext.Provider value={ coreBFActions }>
      <Row
        id="booking-funnel-page"
        className={
          `m-0 ${ classNameToDesktopMode() }`
        }
        hidden={ isSuccess || isInvalidLink || isShowLoadPage }
      >
        { bfLogo && (
          <Col sm={ 12 } className="bf-page-header">
            <div className="logo d-flex">
              <img src={ bfLogo } alt="Logo" />
            </div>
          </Col>
        ) }
        <Col sm={ 12 } className="bf-page-content">
          { stepsInfo && navigator.type === NavigatorType.Dropdown && (
            <CoreNavigationMobile
              defaultActiveKey={ stepsInfo ? stepsInfo[0].nameStep : '' }
              lead={ lead }
              isRejected={ isRejected.current }
              isValidForm={ isValidForm }
            />
          ) }
          <HashTabs
            className={ classNameToTabs() }
            variant="tabs"
            defaultActiveKey={ stepsInfo ? stepsInfo[0].nameStep : '' }
            id="step"
            onSelect={ () => onClickTabsHandler() }
          >
            { stepsInfo && stepsInfo.map( ( item, idx ) => (
              <Tab
                key={ idx }
                eventKey={ item.nameStep }
                tabClassName={ item.componentType === JSONComponentType.System ? 'd-none' : '' }
                disabled={ isDisabledTab( item ) }
                title={ getTitleToTab( item ) }
              >
                { item.componentType === JSONComponentType.Policy && productData !== null && (
                  <React.Suspense fallback={ loading }>
                    <PolicyCoreStepComponent
                      productCode={ productCode }
                      lead={ lead! }
                      productData={ productData }
                      recalculationTariff={ recalculationTariff }
                      stepData={ item }
                      policyStepNames={ policyStepNames! }
                      isDisabledForm={ bookingFunnelModeView === 'true' ? true : false }
                    />
                  </React.Suspense>
                ) }
                { item.componentType === JSONComponentType.PersonalData && productData !== null && (
                  <React.Suspense fallback={ loading }>
                    <PersonalCoreStepComponent
                      productCode={ productCode }
                      subStep={ actionPersonalStep }
                      lead={ lead }
                      productData={ productData }
                      tenantSlug={ tenantSlug }
                      recalculationTariff={ recalculationTariff }
                      stepData={ item }
                      policyStepNames={ policyStepNames! }
                      isDisabledForm={ accountData ? true : false }
                    />
                  </React.Suspense>
                ) }
                { item.componentType === JSONComponentType.System && productData !== null && (
                  <React.Suspense fallback={ loading }>
                    <SystemCoreStepComponent
                      productCode={ productCode }
                      lead={ lead! }
                      productData={ productData }
                      recalculationTariff={ recalculationTariff }
                      stepData={ item }
                      policyStepNames={ policyStepNames! }
                    />
                  </React.Suspense>
                ) }
                { item.componentType === JSONComponentType.Payment && productData !== null && (
                  <React.Suspense fallback={ loading }>
                    <PaymentCoreStepComponent
                      lead={ lead }
                      productData={ productData }
                      productCode={ productCode }
                      stepData={ item }
                      recalculationTariff={ recalculationTariff }
                      policyStepNames={ policyStepNames! }
                    />
                  </React.Suspense>
                ) }
                { item.componentType === JSONComponentType.Summary && productData !== null && (
                  <React.Suspense fallback={ loading }>
                    <SummaryCoreStepComponent
                      productCode={ productCode }
                      lead={ lead }
                      productData={ productData }
                      screenType={ screenType }
                      recalculationTariff={ recalculationTariff }
                      stepData={ item }
                      policyStepNames={ policyStepNames! }
                      isDisabledForm={ bookingFunnelModeView === 'true' ? true : false }
                      listLeadStatuses={ leadStatuses ? JSON.parse( leadStatuses ) : undefined }
                    />
                  </React.Suspense>
                ) }
              </Tab>
            ) ) }
          </HashTabs>
        </Col>
      </Row>
      { isSuccess && (
        <CoreSuccessPage
          bfLogo={ bfLogo }
          pageContent={ productData?.stepsConfig?.successPageContent }
          homePageHandler={ homePageHandler }
          leadNumber={ leadNumber }
        />
      ) }
      { isInvalidLink && (
        <InvalidLinkPage
          addedClassName={ classNameToDesktopMode }
          homePageHandler={ homePageHandler }
        />
      ) }
      { isShowLoadPage && (
        <LoadPage
          addedClassName={ classNameToDesktopMode }
        />
      ) }
    </DynamicCoreActionsContext.Provider>
  );
};

export default CoreBookingFunnel;
