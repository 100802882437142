import React, { Fragment, ReactElement } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
import {
  FIELD_TYPE_BOOL,
  FIELD_TYPE_DATETIME,
  FIELD_TYPE_NUMBER,
  isObject,
} from '../../../../bf-hooks';
import { ContainerFieldItem, IBFProductVariable, ILeadData, SummarySettings } from 'Services/widgets/interfaces';
import { isEmpty } from 'lodash';
import { FieldType, ProductFieldTypeEntity, Separators } from 'Services/widgets/enums';
import { customDateFormat, defaultDateFormat } from 'App/ui-utils';
import {
  addressTypes,
  convertValueToNumber,
  currencyFormatter,
  defaultCurrency,
  keyMultiInsuredObjects,
} from '../../../core-hooks';
import { evalFunction } from '../../../../booking-funnel/functions';
import { JSONItemUIType } from 'App/components/widgets/booking-funnel/enum';
import { useLocale } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { AddressList } from '../../../ui-components/summary-views/AddressList';
import { ObjectList } from '../../../ui-components/summary-views/ObjectList';
import { StepInfoItem } from '../../../interfaces';
import { blankLink } from 'config';
import { useCoreActions } from '../../../DynamicCore';
import { SummaryLabel } from 'Services/widgets/interfaces';
import { PositionLabel } from 'Services/widgets/enums';
import { CoreLabel } from '../CoreLabel';
import { CoreViewDynamicField } from './CoreViewDynamicField';
import { CoreObjectList } from './CoreObjectList';
import { CoreAddressAutoCompleteVeiw } from './CoreAddressAutoCompleteVeiw';
import { CoreDateRangeField } from './CoreDateRangeField';

export interface CorePolicyInfoProps {
  isOpened: boolean;
  currentStep: StepInfoItem;
  productData: IDataFactorsAndVariables;
  lead: ILeadData;
  labels?: SummaryLabel[];
  settings: SummarySettings;
}

export const CorePolicyInfo: React.FC<CorePolicyInfoProps> = (
  { isOpened, currentStep, productData, lead, labels, settings },
) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const locale = useLocale();
  const actions = useCoreActions();
  const { factors, variables } = productData;
  const nameStep = currentStep.nameStep;
  const tariffData = lead[nameStep];
  const [ isCollapsed, setCollapsed ] = React.useState<boolean>( isOpened );
  const multiInsuredObjects = lead[keyMultiInsuredObjects];
  const { disbledExpandView, isShowStepEditButton } = settings || {};

  const collapseToggleHandler = React.useCallback( () => {
    setCollapsed( !isCollapsed );
  }, [ isCollapsed ] );

  const getValueFactorField = React.useCallback( (
    variable: IBFProductVariable, containerField: ContainerFieldItem,
  ): ReactElement | string => {
    const fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
    const selectedVal = tariffData && tariffData[fieldName];

    if ( selectedVal && containerField && containerField.uiType === JSONItemUIType.MultiSelect ) {
      const selectedValues: string[] = typeof selectedVal === 'string' ? JSON.parse( selectedVal ) : null;

      if ( selectedValues ) {
        const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
        if ( findFactor ) {
          const factorList = findFactor?.values.filter( ( f ) => selectedValues.includes( String( f.key ) ) );

          return factorList.map( ( item ) => {
            return item.name;
          } ).join( ', ' );
        }
      }

      if ( typeof selectedVal === 'object' ) {
        return selectedVal.map( ( item ) => {
          return item.name;
        } ).join( ', ' );
      }
    }

    if ( isObject( selectedVal ) && selectedVal['name'] ) {
      return selectedVal['name'];
    }

    if ( selectedVal ) {
      const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;
      if ( findFactor && findFactor.values ) {
        const factorInfo = findFactor.values.find( ( f ) => f.key === selectedVal )!;
        return factorInfo && factorInfo.name ? factorInfo.name : '-';
      }
    }

    return '-';
  }, [ factors, tariffData ] );

  const getValueBaseField = React.useCallback(
    ( variable: IBFProductVariable, containerField: ContainerFieldItem ): React.ReactElement => {
      let fieldName = `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;

      if ( containerField && containerField.groupName ) {
        fieldName = `${fieldName}_${containerField.groupName}`;
      }

      const selectedVal = tariffData && tariffData[fieldName];

      if ( variable.typeId === FIELD_TYPE_BOOL && typeof selectedVal === 'boolean' ) {
        return ( <p>{ selectedVal ? t( 'bookingFunnel.tariff.yes' ) : t( 'bookingFunnel.tariff.no' ) }</p> );
      }

      if ( variable.typeId === FIELD_TYPE_NUMBER && selectedVal ) {
        const isCurrency: boolean = containerField?.uiType === JSONItemUIType.Currency ? true : false;
        const suffix: string = containerField?.suffix ? containerField?.suffix : '';
        if ( isCurrency ) {
          const converValue = convertValueToNumber( selectedVal );

          return ( <p>{ `${ currencyFormatter( converValue, defaultCurrency, locale, false ) }${suffix}` }</p> );
        }

        return ( <p>{ `${selectedVal}${suffix}` }</p> );
      }

      if ( variable.typeId === FIELD_TYPE_DATETIME && selectedVal ) {
        return (
          <p
            className="date-value"
            custom-date-format={
              dayjs( selectedVal ).format( customDateFormat )
            }
          >
            <span>
              { dayjs( selectedVal ).format( defaultDateFormat ) }
            </span>
          </p>
        );
      }

      const resVal: string = selectedVal ? selectedVal.toString() : '';

      return ( <p>{ !isEmpty( resVal ) ? resVal : '-' }</p> );
    }, [ locale, t, tariffData ] );

  const onClickEditStep = React.useCallback ( (
    event: React.MouseEvent<HTMLAnchorElement>,
    groupName?: string,
  ) => {
    event.preventDefault();
    if ( groupName ) {
      actions.goToEditStep( nameStep, groupName, false );
    } else {
      actions.goToStep( nameStep, false );
    }
  }, [ actions, nameStep ] );

  const renderSectionClassName = React.useCallback( ( stepName ): string => {
    if ( stepName ) {
      let sectionClassName = stepName.replaceAll( Separators.Dot, Separators.Underscore );

      if ( sectionClassName && lead[sectionClassName] ) {
        const isEmptyData = Object.values( lead[sectionClassName] ).every( ( v ) => v === null || v === '' );

        if ( isEmptyData ) {
          sectionClassName = `${sectionClassName} empty-data`;
        }
      }

      return sectionClassName;
    }

    return '';
  }, [ lead ] );

  return (
    <Fragment>
      { currentStep && currentStep.steps.map( ( stepItem, itemIdx ) => {
        const topLabel
          = labels?.find( ( label ) => label.stepName === nameStep && label.position === PositionLabel.Top );
        const bottomLabel
          = labels?.find( ( label ) => label.stepName === nameStep && label.position === PositionLabel.Bottom );
        return (
          <Fragment key={ itemIdx }>
            { ( !stepItem.visibleCriteria || evalFunction( lead || [], stepItem.visibleCriteria ) ) && (
              <Accordion
                className={ renderSectionClassName( stepItem.name ) }
                defaultActiveKey={ isOpened ? nameStep : '' }
              >
                <Card className="p-0 m-0">
                  { !disbledExpandView && (
                    <Accordion.Toggle
                      className="py-2 px-3"
                      as={ Card.Header }
                      variant="link"
                      eventKey={ nameStep }
                      onClick={ collapseToggleHandler }
                    >
                      <span className="align-middle bf-title-text">{ currentStep.navigationTitle }</span>
                      <i className={ `material-icons chevron align-middle${ isCollapsed ? ' bf-open' : ' bf-close' }` }>
                        { isCollapsed ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }
                      </i>
                    </Accordion.Toggle>
                  ) }
                  { disbledExpandView && (
                    <Card.Header className="py-2 px-3 cursor-default">
                      <span className="align-middle bf-title-text">{ currentStep.navigationTitle }</span>
                    </Card.Header>
                  ) }
                  <Accordion.Collapse eventKey={ nameStep }>
                    <Card.Body className="p-3">
                      { topLabel && (
                        <CoreLabel stepItem={ topLabel as unknown as ContainerFieldItem } className="" />
                      ) }
                      { stepItem.containers.map( ( container, containerIdx ) => (
                        <Fragment key={ containerIdx }>
                          { evalFunction( lead || [], container.visibleCriteria ) && (
                            <Fragment>
                              { container['showTitleOnSummaryPreview'] && container.title && (
                                <div
                                  className="container-title"
                                  dangerouslySetInnerHTML={ { __html: `${container.title}` } }
                                >
                                </div>
                              ) }
                              { container.items && isShowStepEditButton && (
                                <div className="d-flex justify-content-space-between my-3">
                                  { container.navigationTitle && (
                                    <div className="bf-group-name"
                                      dangerouslySetInnerHTML={ { __html: `${container.navigationTitle}` } }
                                    >
                                    </div>
                                  ) }
                                  { containerIdx === 0 && (
                                    <a
                                      className="bf-link-edit align-self-end"
                                      href={ blankLink }
                                      role='button'
                                      onClick={ ( e: React.MouseEvent<HTMLAnchorElement> ) => {
                                        e.preventDefault();
                                        onClickEditStep( e, container.groupName! );
                                      } }
                                      title={ t( 'base:forms.edit' ) }
                                    >
                                      { t( 'base:forms.edit' ) }
                                    </a>
                                  ) }
                                </div>
                              ) }
                              { container.items && container.items.map( ( c, idx ) => (
                                <Fragment key={ idx }>
                                  { evalFunction( lead || [], c.visibleCriteria ) && (
                                    <Fragment>
                                      { !c.fieldName && c.isMulti && c.uiType &&
                                      multiInsuredObjects && multiInsuredObjects.length > 0 && (
                                        <Fragment>
                                          { addressTypes.includes( c.uiType ) && (
                                            <AddressList
                                              stepItem={ c }
                                              tariffData={ lead[nameStep]! }
                                              multiInsuredObjects={ multiInsuredObjects }
                                            />
                                          ) }
                                          { c.uiType === JSONItemUIType.AutoRenderObject && (
                                            <ObjectList
                                              stepItem={ c }
                                              factors={ factors }
                                              tariffData={ lead[nameStep]! }
                                              multiInsuredObjects={ multiInsuredObjects }
                                            />
                                          ) }
                                        </Fragment>
                                      ) }
                                      { c.type && c.type === FieldType.StateVariable && (
                                        <CoreViewDynamicField
                                          lead={ lead }
                                          stepItem={ c }
                                        />
                                      ) }
                                      { c.type && c.type === FieldType.MultiInsuredObject &&
                                      !c['hideOnSummaryPreview'] &&
                                      multiInsuredObjects && multiInsuredObjects.length > 0 && (
                                        <CoreObjectList
                                          stepItem={ c }
                                          factors={ factors }
                                          tariffData={ lead[nameStep]! }
                                          multiInsuredObjects={ multiInsuredObjects }
                                          insuredObjectName={ c.insuredObjectName }
                                          nameStep={ nameStep }
                                        />
                                      ) }
                                      { c.type && c.type === FieldType.AddressAutoComplete && (
                                        <CoreAddressAutoCompleteVeiw
                                          lead={ lead[nameStep]! }
                                          stepItem={ c }
                                        />
                                      ) }
                                      { c.type && c.type === FieldType.DateRange && (
                                        <CoreDateRangeField
                                          idx={ idx+1 }
                                          lead={ lead[nameStep]! }
                                          stepItem={ c }
                                        />
                                      ) }
                                      { c.type && c.type === FieldType.Policy && variables.filter(
                                        ( v ) => `${v.name}_${v.groupName}` === `${c.fieldName}_${c.insuredObjectName}`
                                        && !v.isHidden,
                                      )
                                        .map( ( variable, cIdx ) => (
                                          <div
                                            key={ cIdx }
                                            id={ `${variable.groupName}-${variable.name}-${cIdx+1}` }
                                            className={ `details-section mx-4 ${variable.groupName}-section` }
                                          >
                                            <p className="bf-selected-val f-14">{ variable.label }</p>
                                            { variable.typeEntity === ProductFieldTypeEntity.SystemProductFieldType &&
                                              ( <>{ getValueBaseField( variable, c ) }</> ) }
                                            { variable.typeEntity === ProductFieldTypeEntity.ProductFactorType && (
                                              <p>{ getValueFactorField( variable, c ) }</p>
                                            ) }
                                          </div>
                                        ) ) }
                                    </Fragment>
                                  ) }
                                </Fragment>
                              ) ) }
                            </Fragment>
                          ) }
                        </Fragment>
                      ) ) }
                      { bottomLabel && (
                        <CoreLabel stepItem={ bottomLabel as unknown as ContainerFieldItem } className="" />
                      ) }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ) }
          </Fragment>
        );
      } ) }
    </Fragment>
  );
};
