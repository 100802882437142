import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import jsonpath from 'jsonpath';
import { JsonObject, JsonValue, ProtoUtil } from '@cover42/protobuf-util';
import { cloneDeep, isEmpty, keyBy, camelCase, groupBy, chain } from 'lodash';
import {
  ContainerItem,
  IBFInsuredObjectItem,
  IBFProductVariable,
  ILeadData,
  IPolicyObject,
  IPolicyObjectPayload,
  IProductInvoice,
  FormValues,
  ContainerFieldItem,
  PayloadRule,
  IAccountLeadData,
  IStaticDocumentItem,
  IHTMLText,
  UploadedDocument,
  FileItem,
  StepsConfig,
  ICreateLeadData,
  ITariffDataStep,
} from 'Services/widgets/interfaces';
import { apiDateTimeFormat, durationInHours } from 'App/ui-utils';
import {
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_BOOL,
  FIELD_TYPE_DATETIME,
  isObject,
} from '../bf-hooks';
import {
  AccountDataType,
  AccountFields,
  AddressFields,
  FactorPremiumFormulaUnits,
  FieldType,
  FormType,
  InsuredObjectTypes,
  LayoutLevel,
  PaymentMethodName,
  PaymentProvider,
  PremiumFormulaUnits,
  ProductFieldTypeEntity,
  Title,
} from 'Services/widgets/enums';
import { IDataFactorsAndVariables } from '../booking-funnel/BookingFunnel';
import GenderOptions from '../genderOptions';
import { AccountType } from '@cover42/ts-contracts';
import { CriteriaItem, SubStepItem, StepItem } from 'Services/widgets/interfaces';
import { FieldsFunkCore } from './enums';
import { AccountFieldItem, PolicyFieldItem, StepInfoItem } from './interfaces';
import { evalFunction } from '../booking-funnel/functions';
import { CoreNavItem } from './ui-components/MobileNavCore';
import { PersonalDataStepCore } from './steps/personal-sub-steps/RegistrationStep';
import { JSONComponentType, JSONItemUIType } from '../booking-funnel/enum';
import { TFunction } from 'i18next';
import { ICFFactorType } from '../factor-service';
import { AllBFDataLoginStep } from './ui-components/WelcomePopup';
import { Separators } from 'Services/widgets/enums';
import { fileUploadKey } from './ui-components/FileUpload';
import { personalDataCoreKey } from './core-booking-funnel/steps/personal-sub-steps/RegistrationCoreStep';
import { paymentDataCoreKey } from './CoreBookingFunnel';
import { EmilPaymentSystem } from './core-booking-funnel/steps/payment-systems/EmilSystem';
import { LayoutConfigType, LayoutType } from 'Services/widgets/types';
import { PolicyEditData } from './PolicyEdit';
import { FilterInput, parseInputs } from './core-booking-funnel/core-ui-components/extra/list-hooks';

dayjs.extend( utc );

export const billingFrom = dayjs().add( 1, 'day' ).utc().format( 'YYYY-MM-DD' );
export const billingTo = dayjs( billingFrom ).add( 1, 'month' ).utc().format( apiDateTimeFormat );
export const formatDatePolicy = 'YYYY-MM-DD';
export const yesValue = '1';
export const noValue = '0';
export const defaultCurrency = 'EUR';
export const defaultLocale = 'de-DE';

const timeFormat = 'T00:00:00.000Z';
export const personalDataKey = 'personalData';
export const mainPolicyStep = 'PolicyStep';
export const keyMultiInsuredObjects = 'multiInsuredObjects';
export const keyProductInvoice = 'productInvoice';
export const fullAddressField = {
  name: FieldsFunkCore.FullAddress,
};
export const addressTypes: string[] = [
  JSONItemUIType.AddressSingleLine,
  JSONItemUIType.AddressMultiLines,
];

export const keyCustomFields = 'customFields';
export const keyAccountCode = 'accountCode';
export const keyIsDisabledSaveForLater = 'isDisabledSaveForLater';
export const keyCode = 'code';
export const limitRequest: number = 5;
export const partnerKey = 'partner';

const brokerIdKey = 'brokerIdField';

const initAccountData: IAccountLeadData = {
  gender: GenderOptions[0].value,
  title: Title.none,
  firstName: '',
  lastName: '',
  zipCode: '',
  city: '',
  street: '',
  houseNumber: '',
  phone: '',
  email: '',
  type: AccountType.Person,
  birthDate: '',
  customFields: {},
};

export const systemFields: string[] = [
  AddressFields.Country,
  AddressFields.City,
  AddressFields.Street,
  AddressFields.HouseNumber,
  AddressFields.Zip,
  AddressFields.IsAddressValid,
  FieldsFunkCore.FullAddress,
  AccountFields.Gender,
  AccountFields.AccountTitle,
  AccountFields.FirstName,
  AccountFields.LastName,
  AccountFields.Phone,
  AccountFields.Email,
  AccountFields.Type,
  AccountFields.BirthDate,
  'objectsCount',
  'accountFirstName',
  'accountLastName',
  'accountIban',
  'accountEmail',
];

const billingFrequencyUnitKey = 'billingFrequencyUnit';
export const invoiceFields = [ 'grossAmount', 'netAmount', 'taxAmount' ];

export const prepareDataForTariff = (
  data: JsonObject,
  variables: IBFProductVariable[],
  insuredObjects: IBFInsuredObjectItem[],
  isEncodeData: boolean = true,
): IPolicyObject[] => {
  const cloneData = cloneDeep( data );
  const policyObjects: IPolicyObject[] = [];
  const cloneInsuredObjects = cloneDeep( insuredObjects.filter( ( item ) => !item.isMultiInsuredObject ) );

  cloneInsuredObjects.forEach( ( insuredObject ) => {
    const groupVariables = variables.filter( ( f ) =>
      f.insuredObjectId === insuredObject.id,
    );

    if ( groupVariables.length ) {
      const dataField: JsonObject = {};

      groupVariables.forEach( ( field ) => {
        const fieldName = field.name;
        const keyName = `${fieldName}_${insuredObject.name}`;
        const itemValue = cloneData[keyName];
        const foundField = variables.find( ( item ) => item.name.toLowerCase() === fieldName.toLowerCase() );

        if ( isObject( itemValue ) && itemValue['key'] ) {
          dataField[fieldName] = itemValue['key'];
        } else {
          if ( itemValue !== undefined ) {
            let fieldValue = itemValue;

            if ( foundField ) {
              if ( foundField.typeId === FIELD_TYPE_NUMBER ) {
                let converValue: number = 0;

                if ( fieldValue && typeof fieldValue === 'string' ) {
                  converValue = convertValueToNumber( fieldValue );
                }

                if ( fieldValue && typeof fieldValue === 'number' ) {
                  converValue = fieldValue;
                }

                fieldValue = converValue;
              }

              if ( foundField.typeId === FIELD_TYPE_BOOL ) {
                if ( typeof fieldValue === 'string' ) {
                  fieldValue = fieldValue === 'true';
                }

                if ( typeof fieldValue === 'boolean' ) {
                  fieldValue = Boolean( fieldValue );
                }
              }

              if ( foundField.typeId === FIELD_TYPE_DATETIME && fieldValue ) {
                fieldValue = convertDateToApi( dayjs( fieldValue ).format() );
              }
            }

            if ( fieldValue !== null ) {
              dataField[fieldName] = fieldValue;
            }
          } else {
            if ( foundField ) {
              let defaultValue = foundField.defaultValue;

              if ( defaultValue === null ) {
                return;
              }

              if ( foundField.typeId === FIELD_TYPE_DATETIME ) {
                const objectDate = defaultValue as JsonObject;

                if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
                  defaultValue = objectDate.evaluated as string;
                }
              }

              if ( defaultValue !== null ) {
                dataField[fieldName] = defaultValue;
              }
            }
          }
        }
      } );

      const newPolicyObject = {
        insuredObjectId: insuredObject.id,
        data: isEncodeData ? ProtoUtil.encodeStruct( dataField ) : dataField,
      };

      policyObjects.push( newPolicyObject );
    }

  } );

  return policyObjects;
};

export const preparePersonaData = (
  savedData: JsonObject,
  data: IAccountLeadData,
  customData: JsonValue,
): PersonalDataStepCore => {
  const newData = cloneDeep( data );

  if ( customData && customData.hasOwnProperty( 'accountFieldsMap' ) ) {
    const accountFieldsMap = { ...customData['accountFieldsMap'] };

    Object.keys( accountFieldsMap ).forEach( ( fieldName ) => {
      const nameKey = accountFieldsMap[fieldName];
      const savedValue = savedData[nameKey];

      if ( savedValue ) {
        newData[fieldName] = savedValue;
      }
    } );
  }

  return newData;
};

export const getNestedValue = ( obj: AllBFDataLoginStep, keys: string ): string | undefined => {
  const keysArray = keys.split( Separators.Colon ); // Split keys by colons
  let current = obj;

  for ( const key of keysArray ) {
    if ( current && typeof current === 'object' && key in current ) {
      current = current[key];
    } else {
      return undefined; // Key not found in the nested structure
    }
  }

  return current as unknown as string;
};

export const replacePlaceholders = ( text: IHTMLText, defaultText: string, allData?: AllBFDataLoginStep ): string => {
  let textReplaced = text.htmlText;
  text.linkedItems?.forEach( ( linkedItem ) => {
    const { linkType, linkValue } = linkedItem;
    const valueBody = ( allData && linkType && getNestedValue( allData, `${linkType}:${linkValue}` ) )
      || ( allData && getNestedValue( allData, linkValue ) )
      || defaultText;
    textReplaced = textReplaced.replace( `{{${linkedItem.linkKey}}}`, valueBody );
  } );

  return textReplaced;
};

export const prepareAccountData = (
  personalDataStep: PersonalDataStepCore, stepsConfig?: StepsConfig,
): IAccountLeadData => {
  const newAccountData = {
    gender: GenderOptions[0].value,
    title: Title.none,
    firstName: '',
    lastName: '',
    zipCode: '',
    city: '',
    street: '',
    houseNumber: '',
    phone: '',
    email: '',
    type: AccountType.Person,
    birthDate: '',
    companyName: '',
    customFields: {},
  };

  const ignoreFields = [ 'addressType', 'address', keyAccountCode ];

  let accountFields: AccountFieldItem[] = [];
  if ( stepsConfig ) {
    accountFields = getAccountFields( stepsConfig! );
  }

  Object.keys( personalDataStep ).forEach( ( fieldName ) => {
    if ( accountFields.length > 0 && !accountFields.find( ( f ) => f.fieldName === fieldName ) ) {
      return;
    }

    let itemValue = personalDataStep[fieldName];

    if ( newAccountData[fieldName] === undefined ) {
      if ( !ignoreFields.includes( fieldName ) ) {
        const currentField = accountFields.find( ( f ) => f.fieldName === fieldName );

        newAccountData.customFields![fieldName] = currentField && currentField.dataType === AccountDataType.Number ?
          Number( itemValue ) : itemValue;
      }

      return;
    }

    if ( isObject( itemValue ) && itemValue.hasOwnProperty( 'name' ) ) {
      newAccountData[fieldName] = itemValue.name;
    } else {
      if ( fieldName === AccountFields.Phone ) {
        itemValue = formatPhoneNumber( itemValue );
      }

      if ( fieldName === AccountFields.BirthDate && itemValue && itemValue !== 'null' ) {
        itemValue = dayjs( itemValue ).format( apiDateTimeFormat );
      }

      newAccountData[fieldName] = itemValue === 'undefined' ? '' : itemValue;
    }
  } );

  return newAccountData;
};

export const generateVariables = ( insuredObjects: IBFInsuredObjectItem[] ): IBFProductVariable[] => {
  let variableList: IBFProductVariable[] = [];
  insuredObjects.forEach( ( object ) => {

    if ( object.productFields === undefined ) {
      return;
    }

    object.productFields!.forEach( ( item ) => {
      const newItem = {
        ...item,
        groupName: object.name,
      };
      variableList.push( newItem );
    } );
  } );

  return variableList;
};

export const formatPriceValue = ( price: number ): string => {
  if ( price ) {
    return ( price / 100 ).toFixed( 2 ).replace( '.', ',' );
  }

  return '0,00';
};

export const preparedDefaultGroup = (
  data: JsonObject,
  insuredObjects: IBFInsuredObjectItem[],
  isEncodeData: boolean = true,
): IPolicyObject[] => {
  const policyObjects: IPolicyObject[] = [];
  const cloneData = cloneDeep( data );
  const cloneInsuredObjects = cloneDeep( insuredObjects );
  const defaultGroup = cloneInsuredObjects.find(
    ( group ) => group.name.toLowerCase() === InsuredObjectTypes.Default.toLowerCase(),
  );

  if ( defaultGroup && defaultGroup.productFields ) {
    const dataField: JsonObject = {};

    defaultGroup.productFields.forEach( ( item ) => {
      const itemValue = cloneData[`${item.name}_${defaultGroup.name}`];
      let defaultValue = item.defaultValue;

      if ( itemValue ) {
        defaultValue = itemValue;
      } else {
        if ( ( item.typeId === FIELD_TYPE_DATETIME && defaultValue ) || isObject( defaultValue ) ) {
          const objectDate = defaultValue as JsonObject;
          if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
            defaultValue = objectDate.evaluated as string;
          }
        }
      }

      dataField[item.name] = defaultValue;
    } );

    const newPolicyObject = {
      insuredObjectId: defaultGroup.id,
      data: isEncodeData ? ProtoUtil.encodeStruct( dataField ) : dataField,
    };

    policyObjects.push( newPolicyObject );
  }

  return policyObjects;
};

export const prepareDefaulGroupAplication = (
  policyObjects: IPolicyObject[],
  productData: IDataFactorsAndVariables | PolicyEditData,
): IPolicyObjectPayload | null => {
  const defaultGroup = policyObjects[0];
  const cloneData = cloneDeep( defaultGroup.data );
  const insuredObject = productData.insuredObjects.find(
    ( insured ) => insured.id === defaultGroup.insuredObjectId,
  );

  if ( insuredObject && insuredObject.productFields ) {
    const defaultItem: IPolicyObjectPayload = {
      timesliceId: insuredObject.id!,
      insuredObjectName: insuredObject.name,
      insuredObject: insuredObject,
      data: cloneData as JsonObject,
    };

    return defaultItem;
  }

  return null;
};

export const convertDateToApi = ( dateValue: string ): string => {
  const dateToApi = dayjs( dateValue ).format( formatDatePolicy );

  return `${dateToApi}${timeFormat}`;
};

export const convertValueToNumber = ( value: string | number ): number => {
  if ( typeof value === 'number' ) {
    return value;
  }

  if ( value && typeof value === 'string' ) {
    const splitValue = value.split( '.' );
    const resVal = splitValue.join( '' ).replace( /,/g, '.' );

    return Number( resVal );
  }

  return 0;
};

export const currencyFormatter = (
  value: number,
  currency: string = defaultCurrency,
  locale: string = defaultLocale,
  isShowCurrency: boolean = true,
): string => {
  if ( typeof value === 'number' ) {
    const res = new Intl.NumberFormat( locale, {
      style: isShowCurrency ? 'currency' : undefined,
      currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    } ).format( value );

    return `${res.trim()}`;
  }

  return '0,00';
};

export const isBooleanVal = ( value: string ): boolean => {
  return Boolean( Number( value ) );
};

export const prepareUpdatedData = ( data: JsonObject, containers: ContainerItem[] ): JsonObject => {
  const cloneData = cloneDeep( data );

  containers.forEach( ( container ) => {
    if ( container.items === undefined ) {
      return;
    }

    container.items.forEach( ( item ) => {
      const fieldName = `${item.fieldName}_${item.insuredObjectName}`;

      cloneData[fieldName] = yesValue;
    } );
  } );

  return cloneData;
};

export const generatePolicyObject = (
  policyObjects: IPolicyObject[],
  insuredObjects: IBFInsuredObjectItem[],
): JsonObject => {
  let clonePolicyObjects = cloneDeep( policyObjects );
  let newPolicyObject: JsonObject = {};
  const multiInsuredObjectIds = insuredObjects.filter( ( f ) => f.isMultiInsuredObject ).map( ( item ) => item.id );
  const newMultiInsuredObjectIds: number[] = [];
  clonePolicyObjects.filter( ( o ) => multiInsuredObjectIds.includes( o.insuredObjectId ) )
    .forEach( ( object ) => {
      const multiItem = insuredObjects.find(
        ( item ) => item.isMultiInsuredObject && object.insuredObjectId === item.id );
      if ( multiItem ) {
        if ( object.data ) {
          let objectIdx: number = 1;

          const filterIds = newMultiInsuredObjectIds.filter( ( i ) => i === object.insuredObjectId );
          if ( filterIds.length ) {
            objectIdx = filterIds.length + 1;
          }

          Object.keys( object.data ).forEach( ( fieldName ) => {
            newPolicyObject[ `${fieldName}_${multiItem.name}_${objectIdx}` ] = object.data![fieldName];
          } );
          newMultiInsuredObjectIds.push( object.insuredObjectId );
        }
      }
    } );

  clonePolicyObjects.filter( ( o ) => !multiInsuredObjectIds.includes( o.insuredObjectId ) )
    .forEach( ( object ) => {
      const foundItem = insuredObjects.find( ( item ) => item.id === object.insuredObjectId );

      if ( foundItem && object.data ) {
        Object.keys( object.data ).forEach( ( fieldName ) => {
          newPolicyObject[ `${fieldName}_${foundItem.name}` ] = object.data![fieldName];
        } );
      }
    } );

  return newPolicyObject;
};

export const generateMultiObjects = (
  policyObjects: IPolicyObject[],
  insuredObjects: IBFInsuredObjectItem[],
): IBFInsuredObjectItem[] => {
  const clonePolicyObjects = cloneDeep( policyObjects );
  let multiPolicyObject: IBFInsuredObjectItem[] = [];

  clonePolicyObjects.forEach( ( object ) => {
    const multiItem = insuredObjects.find(
      ( item ) => item.isMultiInsuredObject && object.insuredObjectId === item.id );
    if ( multiItem ) {
      const objectIdx: number = 1;
      let nameObject = `${multiItem.name}_${objectIdx}`;

      const filterItem = multiPolicyObject?.filter(
        ( o ) => o.name.startsWith( `${multiItem.name}_` )
      ) || [];

      if ( filterItem.length ) {
        nameObject = `${multiItem.name}_${filterItem.length+1}`;
      }

      const newObject: IBFInsuredObjectItem = {
        ...multiItem,
        isMultiInsuredObject: false,
        minInsuredObjectsCount: null,
        maxInsuredObjectsCount: null,
        name: nameObject,
        productFields: [
          ...multiItem?.productFields!.map( ( item ) => {
            return {
              ...item,
              groupName: nameObject,
            };
          } ),
        ],
      };

      multiPolicyObject.push( newObject );
    }
  } );

  return multiPolicyObject;
};

export const generateSavedData = (
  savedData: JsonObject,
  items: SubStepItem[],
  multiInsuredObjects?: IBFInsuredObjectItem[],
): JsonObject | null => {
  let newData: JsonObject = {};

  items.forEach( ( step ) => {
    const containers = step.containers;
    containers.forEach( ( container ) => {
      container.items.forEach( ( item ) => {
        if ( ( item.isMulti || item.type === FieldType.MultiInsuredObject ) && multiInsuredObjects ) {
          multiInsuredObjects.forEach( ( multiObject ) => {
            const { name, productFields } = multiObject;

            if ( !productFields ) {
              return;
            }

            productFields.forEach( ( field ) => {
              const fieldName = `${field.name}_${name}`;
              if ( savedData.hasOwnProperty( fieldName ) ) {
                newData[fieldName] = savedData[fieldName];
              }
            } );
          } );
        } else {
          const fieldName = `${item.fieldName}_${item.insuredObjectName}`;
          if ( savedData.hasOwnProperty( fieldName ) ) {
            newData[fieldName] = savedData[fieldName];
          }
        }
      } );
    } );
  } );

  return !isEmpty( newData ) ? newData : null;
};

export const formatInvoiceItems = ( invoiceItems: IProductInvoice[] ): Record<string, IProductInvoice> => {
  return keyBy( invoiceItems, ( item ) => camelCase( item.name ) );
};

export const formatFormData = ( formData: FormValues ): FormValues => {
  const cloneData = cloneDeep( formData );

  Object.keys( cloneData ).forEach( ( fieldName ) => {
    const itemValue = cloneData[fieldName];

    if ( itemValue && isObject( itemValue ) && !itemValue.hasOwnProperty( 'label' )
    && !itemValue.hasOwnProperty( 'name' ) ) {
      const allValues: string[] = itemValue.map( ( item: { key: string } ) => item.key );

      cloneData[fieldName] = allValues.length ? JSON.stringify( allValues ) : '';
    }
  } );

  return cloneData;
};

export const preparePriceValue = ( priceValue: number ): number => {
  if ( priceValue ) {
    return ( priceValue / 100 );
  }

  return 0;
};

export const renderPrice = ( price: number, locale: string, currency: string, isShowCurrency: boolean ): string => {
  const newPrice = preparePriceValue( price );

  return currencyFormatter( newPrice, currency, locale, isShowCurrency );
};

export const getPriceToProduct = (
  stepItem: ContainerFieldItem,
  invoiceData: IProductInvoice[],
  locale: string,
  isShowCurrency: boolean = true,
): number | string => {
  if ( stepItem && stepItem.premiumName ) {
    const mainName = camelCase( stepItem.premiumName );
    const brokerageName = `${mainName}Courtage`;
    const taxName = `${mainName}Steuer`;

    const foundMainItem = invoiceData.find( ( item ) => camelCase( item.name ) === mainName );

    if ( foundMainItem ) {
      let grossAmount = foundMainItem.grossAmount;
      const foundBrokerageItem = invoiceData.find( ( item ) => camelCase( item.name ) === brokerageName );

      if ( foundBrokerageItem ) {
        grossAmount = grossAmount + foundBrokerageItem.grossAmount;
      }

      const foundTaxItem = invoiceData.find( ( item ) => camelCase( item.name ) === taxName );

      if ( foundTaxItem ) {
        grossAmount = grossAmount + foundTaxItem.grossAmount;
      }

      return `${ renderPrice( grossAmount, locale, defaultCurrency, isShowCurrency ) }`;
    }
  }

  return '0,00';
};

export const getBrokerId = ( savedData: JsonObject, customData: JsonValue ): string => {

  if ( customData && customData.hasOwnProperty( brokerIdKey ) ) {
    const brokerIdField = customData[brokerIdKey];
    const brokerIdValue = savedData[brokerIdField];

    return brokerIdValue as string || '';
  }

  return '';
};

export const renderFullAddress = ( savedData: JsonObject, nameGroup: string ): string => {
  let fullAddress = '';

  if ( isEmpty( savedData ) || !nameGroup ) {
    return fullAddress;
  }

  const streetField = `${AddressFields.Street}_${nameGroup}`;
  if ( savedData[streetField] ) {
    fullAddress = `${fullAddress}${savedData[streetField]} `;
  }

  const houseNumber = `${AddressFields.HouseNumber}_${nameGroup}`;
  if ( savedData[houseNumber] ) {
    fullAddress = `${fullAddress}${savedData[houseNumber]}, `;
  }

  const zipField = `${AddressFields.Zip}_${nameGroup}`;
  if ( savedData[zipField] ) {
    fullAddress = `${fullAddress}${savedData[zipField]}, `;
  }

  const cityField = `${AddressFields.City}_${nameGroup}`;
  if ( savedData[cityField] ) {
    fullAddress = `${fullAddress}${savedData[cityField]}, `;
  }

  const countryField = `${AddressFields.Country}_${nameGroup}`;
  if ( savedData[countryField] ) {
    fullAddress = `${fullAddress}${savedData[countryField]}`;
  }

  return fullAddress;
};

export const addFullAddressField = ( multiInsuredObjects: IBFInsuredObjectItem[] ): IBFInsuredObjectItem[] => {
  if ( !multiInsuredObjects ) {
    return [];
  }

  const cloneMultiInsuredObjects = cloneDeep( multiInsuredObjects );

  cloneMultiInsuredObjects.forEach( ( object ) => {
    if ( object && object.productFields ) {
      const foundAddress = object.productFields.find( ( item ) => item.name === FieldsFunkCore.FullAddress );
      if ( !foundAddress ) {
        object.productFields.push( fullAddressField );
      }
    }
  } );

  return cloneMultiInsuredObjects;
};

export const generateRules = (
  subSteps: SubStepItem[], stepName?: string, isSkipSaveForLaterValidation: boolean = false,
): CriteriaItem[] => {
  let rules: CriteriaItem[] = [];
  let allSubSteps = subSteps;

  if ( stepName ) {
    allSubSteps = allSubSteps.filter(
      ( s ) => s.name === stepName,
    );
  }

  allSubSteps.forEach( ( subStep ) => {
    if ( !subStep.containers ) {
      return;
    }

    subStep.containers.forEach( ( container ) => {
      if ( isSkipSaveForLaterValidation && container.saveForLaterIgnoreValidation ) {
        return;
      }

      if ( container.items === undefined ) {
        return;
      }

      container.items.forEach( ( item ) => {
        if ( item && item.ruleCriteria ) {
          rules.push( {
            ...item.ruleCriteria,
            itemId: item.id,
          } );
        }
      } );
    } );
  } );

  return rules;
};

export const preparePayloadRule = (
  leadStore: ILeadData, productData: IDataFactorsAndVariables | PolicyEditData, policyStepNames: string[],
): PayloadRule => {
  const tariffData = preparePolicyPayload( leadStore, policyStepNames );
  const cloneInsured = cloneDeep( productData.insuredObjects );
  const filteredObjects = cloneInsured.filter( ( item ) => item.name !== InsuredObjectTypes.Default );

  let tariffDataForMultiInsured: IPolicyObject[] = [];
  const multiInsuredObjects = leadStore[keyMultiInsuredObjects];

  if ( multiInsuredObjects ) {
    const variables = generateVariables( multiInsuredObjects );

    tariffDataForMultiInsured = prepareDataForTariff(
      tariffData,
      variables,
      multiInsuredObjects,
      false,
    );
  }

  const prepareTariff = prepareDataForTariff(
    tariffData,
    productData.variables,
    filteredObjects,
    false,
  );

  const policyObjectsData: IPolicyObject[] = [ ...prepareTariff, ...tariffDataForMultiInsured ];


  const policyObjects = policyObjectsData.map( ( item ) => {
    const insuredObject = productData.insuredObjects.find( ( obj ) => item.insuredObjectId === obj.id );

    if ( !insuredObject ) {
      return item;
    }

    return {
      ...item,
      insuredObjectName: insuredObject.name,
    };
  } );

  const formattedObjects = groupBy( policyObjects,
    ( policyObject: IPolicyObject ) => policyObject.insuredObjectName );

  const payloadRule: PayloadRule = {
    policy: {
      formattedObjects,
    },
    allAddressesAreValid: true,
  };

  return payloadRule;
};

export const generateSteps = ( steps: StepItem[] ): StepInfoItem[] => {
  let res: StepInfoItem[] = [];
  steps.forEach( ( item ) => {
    if ( !item.subSteps ) {
      return;
    }

    const checkedFields = [ 'componentType', 'name' ];
    let isError = false;

    checkedFields.forEach( ( nameField ) => {
      // Step has error
      if ( !item[nameField] ) {
        // eslint-disable-next-line no-console
        console.error( `This step does not have a correct ${nameField}: `, item );
        isError = true;
      }
    } );

    if ( isError ) {
      return;
    }

    const groups = chain( item.subSteps ).groupBy( 'name' ).map( ( v, i ) => {
      return {
        id: item.id,
        nameStep: i.replaceAll( Separators.Dot, Separators.Underscore ),
        title: item.title,
        subTitle: item.subTitle,
        navigationTitle: item.navigationTitle,
        componentType: item.componentType,
        visibleIfEmailValidated: item.visibleIfEmailValidated,
        visibleEmailSettings: item.visibleEmailSettings,
        steps: v,
        saveLeadOnComplete: item.saveLeadOnComplete,
        saveForLaterButton: {
          ...item.saveForLaterButton,
          ruleCriteriaSFL: item.ruleCriteriaSFL,
        },
      };
    } ).value();

    res.push( ...groups );
  } );

  if ( res ) {
    res.forEach( ( step, idx ) => {
      res[idx].previousStep = res[idx-1] ? res[idx-1].nameStep : '';
      res[idx].nextStep = res[idx+1] ? res[idx+1].nameStep : '';
    } );
  }

  return res;
};

export const isEnabledPremiumCalculation = ( stepItem: StepInfoItem, leadData: ILeadData ): boolean => {
  let res: boolean = true;
  const { steps } = stepItem;

  steps.forEach( ( element ) => {
    if ( !element.visibleCriteria || evalFunction( leadData || [], element.visibleCriteria ) ) {
      if ( element && 'enabledPremiumCalculation' in element ) {
        res = element.enabledPremiumCalculation as boolean;
      }
    }
  } );

  return res;
};

export const preparePolicyPayload = ( leadStore: ILeadData, allStepNames: string[], stepsConfig?: StepsConfig ) => {
  let policyPayload = {};

  allStepNames.forEach( ( policyName ) => {
    let storeData = leadStore[policyName];
    if ( storeData ) {

      if ( stepsConfig && stepsConfig.steps ) {
        storeData = updatedRadioButtonFields( stepsConfig.steps, policyName, storeData );
      }

      policyPayload = {
        ...policyPayload,
        ...storeData,
      };
    }
  } );

  return policyPayload;
};

export const prepareMobilNav = ( stepItems: StepInfoItem[] ): CoreNavItem[] => {
  let mobileNavList: CoreNavItem[] = [];

  stepItems.forEach( ( item, idx ) => {
    const newItem: CoreNavItem = {
      numberStep: idx+1,
      hashStep: item.nameStep,
      navigationName: item.navigationTitle,
      componentType: item.componentType,
    };

    mobileNavList.push( newItem );
  } );

  return mobileNavList;
};

export const prepareProductFields = (
  productFields: IBFProductVariable[], groupName: string,
): IBFProductVariable[] => {
  let resProductFields: IBFProductVariable[] = [];

  productFields.forEach( ( item ) => {
    const newItem = {
      ...item,
      groupName,
    };

    resProductFields.push( newItem );
  } );

  return resProductFields;
};

export const prepareAccountForInvoice = ( data: JsonObject ): JsonObject => {
  const accountData: JsonObject = {
    ...initAccountData,
  };

  Object.keys( data ).forEach( ( fieldName ) => {
    const itemValue = data[fieldName];

    if ( accountData[fieldName] === undefined ) {
      accountData.customFields![fieldName] = itemValue;
      return;
    }

    if ( isObject( itemValue ) && itemValue.hasOwnProperty( 'name' ) ) {
      accountData[fieldName] = itemValue['name'];
    } else {
      accountData[fieldName] = itemValue;
    }
  } );

  return accountData;
};

export const filterDocumentsByProductSlug = (
  documents: IStaticDocumentItem[], productSlug: string,
): IStaticDocumentItem[] => {

  if ( documents && productSlug ) {
    return documents.filter( ( d ) => d.productSlug === productSlug );
  }

  return documents;
};

export const calculateBillingInterval = ( productInvoice: IProductInvoice ): string => {
  const { billingIntervalTo, billingIntervalFrom } = productInvoice;

  const units = [
    { unit: PremiumFormulaUnits.Year, duration: durationInHours[PremiumFormulaUnits.Year] },
    { unit: PremiumFormulaUnits.HalfYear, duration: durationInHours[PremiumFormulaUnits.HalfYear] },
    { unit: PremiumFormulaUnits.Quarter, duration: durationInHours[PremiumFormulaUnits.Quarter] },
    { unit: PremiumFormulaUnits.Month, duration: durationInHours[PremiumFormulaUnits.Month] },
    { unit: PremiumFormulaUnits.Week, duration: durationInHours[PremiumFormulaUnits.Week] },
    { unit: PremiumFormulaUnits.Day, duration: durationInHours[PremiumFormulaUnits.Day] },
    { unit: PremiumFormulaUnits.Hour, duration:durationInHours[PremiumFormulaUnits.Hour] },
  ];

  const difference = dayjs( billingIntervalTo ).diff( billingIntervalFrom, PremiumFormulaUnits.Hour );

  for ( const { unit, duration } of units ) {
    if ( difference >= duration ) {
      return unit;
    }
  }

  return PremiumFormulaUnits.Month;
};

export const isCheckedByField = ( propertyName: string, stepItem: ContainerFieldItem ): boolean => {
  if ( stepItem && propertyName in stepItem ) {
    return stepItem[propertyName] as boolean;
  }

  return false;
};

export const prepareContainerItems = ( containers: ContainerItem[] ): ContainerFieldItem[] => {
  const items: ContainerFieldItem[] = [];

  if ( !containers ) {
    return items;
  }

  containers.forEach( ( container ) => {
    items.push(
      ...container.items.map( ( item ) => {
        return {
          ...item,
          fullName: item.fieldName && item.insuredObjectName ?
            [ item.fieldName, item.insuredObjectName ].join( Separators.Underscore ) : '',
        };
      } ),
    );
  } );

  return items;
};

export const getInfoMessage = (
  fieldInfo: string, containers: ContainerItem[], leadStore: ILeadData,
): string | null => {
  let infoMessage: string = '';
  if ( fieldInfo && containers ) {
    const items: ContainerFieldItem[] = prepareContainerItems( containers );

    if ( items ) {
      const foundItem = items.find( ( item ) => item['fullName'] === fieldInfo && item.ruleCriteria );

      if ( foundItem && foundItem.ruleCriteria ) {
        const infoData = evalFunction( leadStore || [], foundItem.ruleCriteria );

        if ( infoData && infoData['infoMessage'] ) {
          infoMessage = infoData['infoMessage'];
        }
      }
    }
  }

  return infoMessage;
};

export const renderContainerItemClassName = ( stepItem: ContainerFieldItem ): string => {
  let classContainerItem = 'col-md-12 mb-4';

  if ( stepItem && stepItem.classContainerItem ) {
    classContainerItem = stepItem.classContainerItem;
  }

  return `${classContainerItem}`;
};

export const formatPhoneNumber = ( phoneNumber: string ): string => {
  if ( !phoneNumber ) {
    return '';
  }

  return phoneNumber.replace( / /g, '' ).replaceAll( '-', '' );
};

export const getPaymentProvider = ( containers: ContainerItem[] ): PaymentProvider | null => {
  let paymentProvider: PaymentProvider | null = null;
  if ( containers ) {

    containers.forEach( ( container ) => {
      if ( !container.items ) {
        return null;
      }

      const foundItem = container.items.find( ( item ) => item.type === FieldType.PaymentSettings && item.provider );

      if ( foundItem && foundItem.provider ) {
        paymentProvider = foundItem.provider;
      }
    } );
  }

  return paymentProvider;
};

const getValueFactorField = (
  selectedVal: string | number,
  factors: ICFFactorType[],
  variable: IBFProductVariable,
) => {

  if ( factors === null ) {
    return;
  }

  if ( selectedVal ) {
    const findFactor = factors.find( ( f ) => f.id === variable.typeId )!;

    if ( findFactor && findFactor.values ) {
      const factorInfo = findFactor.values.find( ( f ) => f.key === selectedVal );

      return factorInfo ? factorInfo.name : selectedVal;
    }
  }

  return selectedVal;
};

const getValueBaseField = ( selectedVal, variable: IBFProductVariable, t: TFunction ) => {
  if ( variable.typeId === FIELD_TYPE_BOOL && selectedVal ) {
    return selectedVal ? t( 'bookingFunnel.tariff.yes' ) : t( 'bookingFunnel.tariff.no' );
  }

  return selectedVal;
};

export const prepareValuesForDocument = (
  policyObjects: IPolicyObject[],
  productData: IDataFactorsAndVariables | PolicyEditData,
  t: TFunction ): IPolicyObject[] => {
  const clonePolicyObjects = cloneDeep( policyObjects );

  clonePolicyObjects.forEach( ( object ) => {

    if ( !object.data ) {
      return;
    }

    const data = object.data;

    Object.keys( data ).forEach( ( fieldName ) => {
      const itemValue = data[fieldName];

      if ( itemValue ) {
        const foundField = productData.variables.find( ( v ) => v.name === fieldName );

        if ( foundField ) {
          if ( foundField.typeEntity === ProductFieldTypeEntity.SystemProductFieldType ) {
            data[fieldName] = getValueBaseField( itemValue, foundField, t );
          }

          if ( foundField.typeEntity === ProductFieldTypeEntity.ProductFactorType ) {
            data[fieldName] = getValueFactorField( itemValue, productData.factors, foundField );
          }
        }
      }
    } );
  } );

  return clonePolicyObjects;
};

export const prepareUploadedDocument = ( leadCode: string, leadStore: ILeadData ): UploadedDocument | null => {
  const storeKey = `${fileUploadKey}_${leadCode}`;
  const storeData = leadStore[storeKey];

  if ( storeData ) {
    const codes: string[] = [];

    Object.keys( storeData ).forEach( ( itemKey ) => {
      const fileList: FileItem[] = storeData[itemKey];

      if ( fileList ) {
        fileList.forEach( ( fileItem ) => {
          if ( fileItem && fileItem.code ) {
            codes.push( fileItem.code );
          }
        } );
      }
    } );

    if ( codes.length ) {
      const res: UploadedDocument = {
        codes,
      };

      return res;
    }
  }

  return null;
};

export const renderClassNameBox = ( stepItem?: ContainerFieldItem ): string => {
  let classNameBox = '';

  if ( stepItem && stepItem.classNameBox ) {
    classNameBox = stepItem.classNameBox;
  }

  return `${classNameBox}`;
};

export const renderPlaceholder = ( fieldLabel: string, stepItem?: ContainerFieldItem ): string => {
  if ( stepItem && stepItem.placeholder ) {
    return stepItem.placeholder;
  }

  return fieldLabel;
};

export const getAllStepNames = ( stepsData: StepsConfig ): string[] => {
  const steps = generateSteps( stepsData.steps as StepItem[] );

  return steps.filter( ( p ) => p.componentType !== JSONComponentType.System )
    .map( ( item ) => item.nameStep );
};

export const getAccountFields = ( stepsData: StepsConfig ): AccountFieldItem[] => {
  const allFields: AccountFieldItem[] = [
    {
      fieldName: AddressFields.City,
      dataType: AccountDataType.String,
    },
    {
      fieldName: AddressFields.Street,
      dataType: AccountDataType.String,
    },
    {
      fieldName: AddressFields.HouseNumber,
      dataType: AccountDataType.String,
    },
    {
      fieldName: AddressFields.ZipCode,
      dataType: AccountDataType.String,
    },
  ];

  const steps = generateSteps( stepsData.steps as StepItem[] );
  const personalDataStep = steps.find( ( p ) => p.componentType === JSONComponentType.PersonalData );

  if ( personalDataStep ) {
    const registrationStep = personalDataStep.steps.find( ( stepItem ) => stepItem.formType === FormType.Registration );

    registrationStep?.containers.forEach( ( element ) => {
      if ( element && element.items ) {
        const accountFields = element.items.filter( ( i ) => i.type === FieldType.Account )
          .map( ( item ) => {
            return {
              fieldName: item.fieldName,
              dataType: item.dataType!,
            };
          } );

        if ( accountFields ) {
          allFields.push( ...accountFields );
        }
      }
    } );
  } else {
    return [];
  }

  return allFields;
};

export const filteredSteps = ( stepItem: StepItem[] ): StepItem[] => {
  const allSteps = stepItem.filter(
    ( step ) => ![ JSONComponentType.Payment, JSONComponentType.System ].includes( step.componentType! ),
  );

  const personalStep = allSteps.find( ( p ) => p.componentType === JSONComponentType.PersonalData );

  if ( personalStep ) {
    const foundLoginStep = personalStep.subSteps.find( ( item ) => item.formType === FormType.Login );

    if ( foundLoginStep ) {
      const registrationStep = personalStep.subSteps.filter( ( item ) => item.formType === FormType.Registration );
      const personalStepIdx = allSteps.findIndex( ( p ) => p.componentType === JSONComponentType.PersonalData );

      allSteps[personalStepIdx].subSteps = registrationStep;
    }
  }

  return allSteps;
};

export const isIgnoreOnRecalculationFieldCore = (
  field: string, stepItems: SubStepItem, steps: StepItem[],
): boolean => {
  const items: ContainerFieldItem[] = prepareContainerItems( stepItems.containers );
  if ( items ) {
    let findItem = items.find( ( item ) => item.fieldName === field );

    if( !findItem ) {
      const allRadioButons = getRadioButtons( steps, stepItems.name );
      const findRadioButtonItem = allRadioButons.find( ( item ) => item.fullName === field );

      if ( findRadioButtonItem && 'ignoreOnRecalculation' in findRadioButtonItem ) {
        return findRadioButtonItem.ignoreOnRecalculation! as boolean;
      }
    }

    findItem = items.find( ( item ) => item['fullName'] === field );

    if ( findItem && 'ignoreOnRecalculation' in findItem ) {
      return findItem.ignoreOnRecalculation! as boolean;
    }
  }

  return false;
};

export const preparePayloadForLead = (
  leadData: ILeadData,
  productData: IDataFactorsAndVariables,
  leadStatus: string,
  productCode: string,
  validate: boolean = true,
  defaultAccountEmail?: string,
): ICreateLeadData => {
  let validateData = validate;
  const allStepNames: string[] = getAllStepNames( productData.stepsConfig! );
  const tariffData = preparePolicyPayload( leadData, allStepNames, productData.stepsConfig );

  let tariffDataForMultiInsured: IPolicyObject[] = [];
  const multiInsuredObjects = leadData[keyMultiInsuredObjects];

  if ( multiInsuredObjects ) {
    const variables = generateVariables( multiInsuredObjects );

    tariffDataForMultiInsured = prepareDataForTariff(
      tariffData,
      variables,
      multiInsuredObjects,
      false,
    );
  }
  const preparedPolicyData = prepareDataForTariff(
    tariffData,
    productData.variables,
    productData.insuredObjects,
    false,
  );

  const personalData = leadData[personalDataCoreKey];
  let preparedAccountData = {};

  if ( personalData ) {
    preparedAccountData = prepareAccountData( personalData as PersonalDataStepCore, productData.stepsConfig );
  } else {
    validateData = false;

    if ( defaultAccountEmail ) {
      validateData = true;
      preparedAccountData = {
        gender: GenderOptions[0].value,
        firstName: '',
        lastName: '',
        zipCode: '',
        city: '',
        street: '',
        houseNumber: '',
        phone: '',
        email: defaultAccountEmail,
        type: AccountType.Person,
      };
    }
  }

  let payloadLead: ICreateLeadData = {
    code: productData.leadData.leadCode,
    productCode: productCode,
    policyObjects: [ ...preparedPolicyData, ...tariffDataForMultiInsured ],
    account: preparedAccountData,
    status: leadStatus,
    validate: validateData,
  };

  const { partnerCode, partnerRoleCode } = leadData[partnerKey] || {};
  if ( partnerCode && partnerRoleCode ) {
    payloadLead.partner = {
      partnerCode,
      partnerRoleCode,
      startDate: convertDateToApi( dayjs().format() ),
    };
  }

  const uploadedDocument = prepareUploadedDocument( productData.leadData.leadCode, leadData );

  if ( uploadedDocument ) {
    payloadLead.uploadedDocument = uploadedDocument;
  }

  const paymentDataCore = leadData[paymentDataCoreKey] as EmilPaymentSystem;

  if ( paymentDataCore && paymentDataCore.isSystemPSP ) {
    payloadLead.paymentMethod = {
      type: paymentDataCore.paymentMethod as PaymentMethodName,
      sepa: paymentDataCore.paymentMethod === PaymentMethodName.Sepa ? {
        iban: paymentDataCore.accountIban!,
        holderName: `${paymentDataCore.accountFirstName} ${paymentDataCore.accountLastName}`,
      } : undefined,
    };
  }

  return payloadLead;
};

export const renderGridStyles = (
  layoutData: LayoutType[] | null,
  layoutLevel: LayoutLevel,
  subStepId: string,
  container?: ContainerItem,
  itemId?: string,
): JsonObject => {
  const foundLayout: LayoutConfigType = layoutData && layoutData[subStepId];

  if ( foundLayout && layoutLevel === LayoutLevel.SubStep ) {
    return {
      gridTemplateColumns: `repeat(${foundLayout.columns}, 1fr)`,
    };
  }

  const containerId = container && container.id ? container.id : undefined;

  if ( foundLayout && layoutLevel === LayoutLevel.TitelContainer && containerId ) {
    const foundContainer: LayoutConfigType = layoutData && layoutData[containerId];

    if ( foundContainer ) {
      return {
        gridColumnStart: `span ${foundContainer.columns}`,
      };
    }
  }

  if ( layoutData && foundLayout && layoutLevel === LayoutLevel.Container && containerId ) {
    let res = {};
    const foundContainer: LayoutConfigType = layoutData && layoutData[containerId];
    if ( foundContainer ) {
      res = {
        ...res,
        gridTemplateColumns: `repeat(${foundContainer.columns}, 1fr)`,
      };
    }
    const foundContainerInfo = foundLayout.layout.find( ( item ) => item.id === containerId );
    if ( foundContainerInfo ) {
      res = {
        ...res,
        gridRowStart: foundContainerInfo.autoRow ? 'auto' : foundContainerInfo.y + 1,
        gridRowEnd: foundContainerInfo.autoRow ? 'auto' : foundContainerInfo.y + foundContainerInfo.h + 1,
        gridColumnStart: foundContainerInfo.x + 1,
        gridColumnEnd: foundContainerInfo.x + foundContainerInfo.w + 1,
      };
    }
    return res;
  }

  if ( layoutData && foundLayout && layoutLevel === LayoutLevel.Item && containerId && itemId ) {
    const foundContainer: LayoutConfigType = layoutData && layoutData[containerId];
    const foundItem = foundContainer && foundContainer.layout.find( ( item ) => item.id === itemId );
    if ( foundItem ) {
      const containerTitle = container && container.title ? container.title : undefined;
      const containerSubTitle = container && container.subTitle ? container.subTitle : undefined;
      const startIdx = containerTitle || containerSubTitle ? 2 : 1;

      return {
        gridRowStart: foundItem.autoRow ? 'auto' : foundItem.y + startIdx,
        gridRowEnd: foundItem.autoRow ? 'auto' : foundItem.y + foundItem.h + 1,
        gridColumnStart: foundItem.x + 1,
        gridColumnEnd: foundItem.x + foundItem.w + 1,
      };
    }
  }
  return {};
};

export const generateCrossPolicyFields = (
  steps: StepItem[], currentStep: string, fieldName: string,
): PolicyFieldItem[] => {
  const newPolicyFields: PolicyFieldItem[] = [];

  if ( !steps ) {
    return newPolicyFields;
  }

  steps.forEach( ( step ) => {
    const subSteps = step.subSteps.filter( ( s ) => s.name !== currentStep );

    if ( subSteps ) {
      subSteps.forEach( ( subStep ) => {
        subStep.containers.forEach( ( container ) => {
          const policyFields = container.items.filter(
            ( item ) => item.type === FieldType.Policy
            && `${item.fieldName}_${item.insuredObjectName}` === fieldName )
            .map( ( f ) => {
              return {
                stepName: subStep.name.replaceAll( Separators.Dot, Separators.Underscore ),
                fieldName: [ f.fieldName, f.insuredObjectName ].join( Separators.Underscore ),
              };
            } );

          let mioPolicyFields: PolicyFieldItem[] = [];
          const mio = container.items.filter( ( item ) => item.type === FieldType.MultiInsuredObject );

          if ( mio && mio.length > 0 ) {
            const mioItems = mio[0]['items'] as unknown as ContainerFieldItem[];

            mioPolicyFields = mioItems.filter(
              ( item ) => item.type === FieldType.Policy
              && fieldName.startsWith( `${item.fieldName}_${item.insuredObjectName}` ) )
              .map( ( f ) => {
                return {
                  stepName: subStep.name.replaceAll( Separators.Dot, Separators.Underscore ),
                  fieldName,
                };
              } );
          }

          newPolicyFields.push(
            ...policyFields,
            ...mioPolicyFields,
          );
        } );
      } );
    }
  } );

  return newPolicyFields;
};

export const getRadioButtons = ( steps: StepItem[], currentStep: string ): PolicyFieldItem[] => {
  const policyFields: PolicyFieldItem[] = [];

  if ( !steps ) {
    return policyFields;
  }

  steps.forEach( ( step ) => {
    const subSteps = step.subSteps.filter( ( s ) => s.name === currentStep );

    if ( subSteps ) {
      subSteps.forEach( ( subStep ) => {
        subStep.containers.forEach( ( container ) => {
          policyFields.push(
            ...container.items.filter(
              ( item ) => item.type === FieldType.Policy && item.uiType === JSONItemUIType.Radio && item.groupName )
              .map( ( f ) => {
                return {
                  stepName: subStep.name.replaceAll( Separators.Dot, Separators.Underscore ),
                  fieldName: [ f.fieldName, f.insuredObjectName ].join( Separators.Underscore ),
                  uiType: f.uiType,
                  groupName: f.groupName,
                  ignoreOnRecalculation: f.ignoreOnRecalculation,
                  fullName: [ f.fieldName, f.insuredObjectName, f.groupName ].join( Separators.Underscore ),
                };
              } ),
          );
        } );
      } );
    }
  } );

  return policyFields;
};

export const updatedRadioButtonFields = (
  steps: StepItem[], currentStep: string, storeData: ITariffDataStep,
): ITariffDataStep => {
  const allRadioButons = getRadioButtons( steps, currentStep );

  if ( allRadioButons ) {
    allRadioButons.forEach( ( item ) => {
      const keyRadioField = [ item.fieldName, item.groupName ].join( Separators.Underscore );
      const radioField = storeData[keyRadioField];
      if ( typeof radioField === 'boolean' ) {
        storeData[item.fieldName] = radioField;

        delete storeData[keyRadioField];
      }
    } );
  }

  return storeData;
};

export const renderContainerClass = ( container: ContainerItem, defaultLayoutData: LayoutType[] | null ): string => {
  let resultClassName = container.name ? container.name : '';

  if ( defaultLayoutData && defaultLayoutData[container.id] ) {
    resultClassName = `${resultClassName} layout-container`;
  }

  return resultClassName;
};

const getBillingFrequencyUnitFromStore = ( storeData: ILeadData ): string | undefined => {
  const pathToField = `${billingFrequencyUnitKey}_${InsuredObjectTypes.Default}`;

  return jsonpath.query( storeData || {}, `$..${pathToField}` )[0];
};

const getBillingFrequencyUnitFromInsuredObjects = ( insuredObjects: IBFInsuredObjectItem[] ): string | undefined => {
  let resBillingFrequencyUnit: string | undefined = undefined;

  const pathToField = `[?(@.name=='${InsuredObjectTypes.Default}')][*][?(@.name=='${billingFrequencyUnitKey}')]`;
  const foundBillingFrequencyUnit: IBFProductVariable = jsonpath.query( insuredObjects || {}, `$..${pathToField}` )[0];

  if ( foundBillingFrequencyUnit ) {
    resBillingFrequencyUnit = foundBillingFrequencyUnit.defaultValue as string;
  }

  return resBillingFrequencyUnit;
};

export const premiumCalculationYearly = (
  storeLeadData: ILeadData,
  insuredObjects: IBFInsuredObjectItem[],
  grossAmount: number,
): number => {
  let countValue = 1;
  let billingFrequencyUnit = getBillingFrequencyUnitFromStore( storeLeadData );

  if ( !billingFrequencyUnit ) {
    billingFrequencyUnit = getBillingFrequencyUnitFromInsuredObjects( insuredObjects );
  }

  if ( billingFrequencyUnit ) {
    switch( billingFrequencyUnit ) {
      case FactorPremiumFormulaUnits.Yearly:
      case PremiumFormulaUnits.Year:
        countValue = 1;
        break;
      case FactorPremiumFormulaUnits.HalfYearly:
      case PremiumFormulaUnits.HalfYear:
        countValue = 2;
        break;
      case FactorPremiumFormulaUnits.Quarterly:
      case PremiumFormulaUnits.Quarter:
        countValue = 4;
        break;
      case FactorPremiumFormulaUnits.Monthly:
      case PremiumFormulaUnits.Month:
        countValue = 12;
        break;
    }
  }

  return grossAmount * countValue;
};

export const preparePolicyData = (
  leadStore: ILeadData,
  allStepNames: string[],
  productData: PolicyEditData,
): IPolicyObject[] => {
  const tariffData = preparePolicyPayload( leadStore, allStepNames, productData.stepsConfig );

  let tariffDataForMultiInsured: IPolicyObject[] = [];
  const multiInsuredObjects = leadStore[keyMultiInsuredObjects];

  if ( multiInsuredObjects ) {
    const variables = generateVariables( multiInsuredObjects );

    tariffDataForMultiInsured = prepareDataForTariff(
      tariffData,
      variables,
      multiInsuredObjects,
      false,
    );
  }
  const preparedPolicyData = prepareDataForTariff(
    tariffData,
    productData.variables,
    productData.insuredObjects,
    false,
  );

  return [ ...preparedPolicyData, ...tariffDataForMultiInsured ];
};

export const renderLabelHtml = ( html: string, locale: string, leadData?: ILeadData ): string => {
  if ( !leadData ) {
    return html;
  }

  const regex = new RegExp( /\{(.*?)\}/, 'gi' );
  const placeholders = html.match( regex );

  let resultHtml = html;

  if ( placeholders ) {
    placeholders.forEach( ( placeholder ) => {
      const placeholderVal = placeholder.slice( 1, -1 );
      const splitPlaceholderFilterDate = placeholderVal.split( '|' );
      const splitPlaceholder = splitPlaceholderFilterDate[0].split( Separators.Dot );

      if ( splitPlaceholder && splitPlaceholder.length > 1 ) {
        if ( splitPlaceholder[0].toLowerCase() === 'quote' ) {
          let foundValue = jsonpath.query( leadData || {}, `$.${placeholderVal}` )[0];
          const lastWord = placeholderVal ? ( placeholderVal as string ).split( Separators.Dot ).pop() : '';

          if ( typeof foundValue === 'number' && lastWord && invoiceFields.includes( lastWord ) ) {
            const fieldValue = foundValue / 100;
            const currency = leadData && leadData.quote ? leadData.quote.currency : defaultCurrency;

            foundValue = currencyFormatter( fieldValue, currency, locale, false );
          }

          if ( splitPlaceholderFilterDate && splitPlaceholderFilterDate[1] && foundValue ) {
            foundValue = dayjs( foundValue ).format( splitPlaceholderFilterDate[1] );
          }

          resultHtml = resultHtml.replaceAll( placeholder, foundValue ? foundValue : 0 );

        } else {
          const pathToField = `${splitPlaceholder[1]}_${splitPlaceholder[0]}`;
          let storeValue = jsonpath.query( leadData || {}, `$..${pathToField}` )[0];

          if ( typeof storeValue === 'object' && storeValue.hasOwnProperty( 'name' ) ) {
            storeValue = storeValue.name;
          }

          if ( splitPlaceholderFilterDate && splitPlaceholderFilterDate[1] && storeValue ) {
            storeValue = dayjs( storeValue ).format( splitPlaceholderFilterDate[1] );
          }

          resultHtml = resultHtml.replaceAll( placeholder, storeValue ? storeValue : '-' );
        }
      }
    } );
  }

  return resultHtml;
};

export const renderFieldLabel = ( variable: IBFProductVariable, stepItem?: ContainerFieldItem ): string => {
  if ( stepItem && stepItem.label ) {
    return stepItem.label;
  }

  if ( variable.bfLabel ) {
    return variable.bfLabel;
  }

  return variable.label;
};

export const getIgnoreFieldsInContainers = ( step: SubStepItem ): string[] => {
  const ignoreFields: string[] = [];

  const allowedTypes = [ FieldType.Account, FieldType.AccountSelect, FieldType.List, FieldType.Policy ];

  step.containers.forEach( ( container ) => {
    if ( container.items === undefined || !container.saveForLaterIgnoreValidation ) {
      return;
    }

    container.items.forEach( ( item ) => {
      if ( item.type && allowedTypes.includes( item.type ) ) {
        let fieldName = `${item.fieldName}_${item.insuredObjectName}`;

        if ( item.type === FieldType.List ) {
          const inputs: FilterInput[] = parseInputs( item.input ?? '' );
          fieldName = `${item.id}${inputs[0] && inputs[0].columnName ? `-${inputs[0].columnName}`: ''}`;
        }

        ignoreFields.push( fieldName );
      }
    } );
  } );

  return ignoreFields;
};
