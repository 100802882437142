import React, { Fragment } from 'react';
import documentIcon from 'assets/bf-images/document_icon.svg';
import jsonpath from 'jsonpath';
import cardIconUrl from 'assets/bf-images/card.svg';
import sepaIconUrl from 'assets/bf-images/sepa.svg';
import { Row, Col, Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useWidgetService } from 'Services/widget';
import { Controller, FormProvider } from 'react-hook-form';
import {
  CardNumberElement,
  IbanElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  ConfirmCardSetupData,
  ConfirmSepaDebitSetupData,
  SetupIntentResult,
  StripeCardNumberElement,
  StripeIbanElement,
} from '@stripe/stripe-js/types/stripe-js';
import { FieldType, LayoutLevel, PaymentMethodName } from 'Services/widgets/enums';
import {
  CompleteEmailVerification,
  ContainerFieldItem,
  DocumentsSettings,
  FileUploadSettings,
  IInitiatePaymentSetup,
  ILeadData,
  IPaymentSystem,
  IPaymentSystemComplete,
  ITariffDataStep,
  PremiumSettings,
} from 'Services/widgets/interfaces';
import { IbanFields } from './IbanFields';
import { LoadingSpinner, useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { CardFields } from './CardFields';
import {
  formatFormData,
  generateRules,
  renderContainerItemClassName,
  systemFields,
  isIgnoreOnRecalculationFieldCore,
  preparePayloadForLead,
  generateCrossPolicyFields,
  renderGridStyles,
  renderContainerClass,
} from '../../../../../core-hooks';
import { StripeSystemCoreProps } from '../../StripeSystem';
import { LeadStatus } from '@cover42/ts-contracts';
import { useCoreActions } from 'App/components/widgets/dynamic-core/DynamicCore';
import { PaymentCoreStepItem } from '../../../PaymentCoreStep';
import { COREBFStep, LayoutTypes } from 'App/components/widgets/dynamic-core/enums';
import { personalDataCoreKey } from '../../../personal-sub-steps/RegistrationCoreStep';
import { CoreFieldPanel } from '../../../../core-ui-components/CoreFieldPanel';
import { CoreTariffInfo } from '../../../../core-ui-components/CoreTariffInfo';
import { DownloadLink } from 'App/components/widgets/dynamic-core/ui-elements/DownloadLink';
import { evalFunction } from 'App/components/widgets/booking-funnel/functions';
import { CoreCheckbox } from '../../../../core-ui-components/CoreCheckbox';
import { paymentDataCoreKey } from 'App/components/widgets/dynamic-core/CoreBookingFunnel';
import { CoreLabel } from '../../../../core-ui-components/CoreLabel';
import { CoreCirclesNavigationMobile } from '../../../../core-ui-components/CoreCirclesNavigationMobile';
import { FileUpload } from 'App/components/widgets/dynamic-core/ui-components/FileUpload';
import { CoreDynamicField } from '../../../../core-ui-components/CoreDynamicField';
import { isEmpty } from 'lodash';
import { CFDefaultValueType } from 'App/components/widgets/bf-hooks';
import { getFieldDiff } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { TooltipPopupCore } from 'App/components/widgets/dynamic-core/ui-components/TooltipPopupCore';
import { CoreMultiInsuredObject } from '../../../../core-ui-components/CoreMultiInsuredObject';
import { CoreDocumentDownloadInfo } from '../../../../core-ui-components/summary-views/CoreDocumentDownloadInfo';
import { CoreSavedDataPopup } from '../../../../core-ui-components/CoreSavedDataPopup';
import { htmlTagsRegex } from '../../../../core-ui-components/extra/CoreAddressAutoComplete';
import { useAppLogger } from 'Services/logger';
import { CoreSaveLeadNav } from '../../../../core-ui-components/CoreSaveLeadNav';
import { StepInfoItem } from 'App/components/widgets/dynamic-core/interfaces';
import { JSONPageType } from 'App/components/widgets/booking-funnel/enum';

export const CoreStripeForm: React.FC<StripeSystemCoreProps> = ( {
  lead, productData, productCode, formContext, stepData, policyStepNames, recalculationTariff,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();
  const logger = useAppLogger();
  const previousIsFirstLoad = React.useRef<boolean>( true );
  const [ paymentMethodName, setPaymentMethodName ] = React.useState<string>( '' );
  const [ initiatePaymentSetup, setInitiatePaymentSetup ] = React.useState<IInitiatePaymentSetup | null>( null );
  const [ isLoading, setLoading ] = React.useState<boolean>( false );
  const [ isValidEmail, setValidEmail ] = React.useState<boolean>( true );
  const [ messageError, setMessageError ] = React.useState<string>( '' );
  const [ isShowErrorPopup, setShowErrorPopup ] = React.useState<boolean>( false );
  const [ isRejected, setIsRejected ] = React.useState<boolean>( false );
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const { saveForLaterButton } = stepData || {};
  const { leadData, isSetupPayment, configuration } = productData;

  const [ leadState, setLeadState ] = React.useState<ILeadData>( lead );
  const [ itemErrorId, setItemErrorId ] = React.useState<string>( '' );
  const [ itemErrorIds, setItemErrorIds ] = React.useState<string[]>( lead[`${paymentDataCoreKey}_error_ids`] || [] );

  const isDisabledForm = false;
  const { fieldsDefaultValues } = productData.configuration || {};
  const previousFormData = React.useRef<ITariffDataStep>( {} );
  const isIgnoreOnRecalculation = React.useRef<boolean>( false );
  const currentStep = stepData && stepData.steps.find( ( subStep ) => subStep.name === paymentDataCoreKey );
  const defaultLayoutData = productData.stepsConfig && productData.stepsConfig.layout
    ? productData.stepsConfig.layout[LayoutTypes.Default] : null;

  const stripe = useStripe();
  const elements = useElements();

  let paymentDataFields: PaymentCoreStepItem = {
    paymentMethod: '',
    accountFirstName: lead[personalDataCoreKey]?.firstName,
    accountLastName: lead[personalDataCoreKey]?.lastName,
    accountEmail: lead[personalDataCoreKey]?.email,
  };

  if ( lead[paymentDataCoreKey] !== null ) {
    paymentDataFields = lead[paymentDataCoreKey] as PaymentCoreStepItem;
  }

  const { errors, control, formState, reset, getValues } = formContext;

  const isDisabledField = React.useCallback ( ( item: ContainerFieldItem ): boolean => {
    if( isDisabledForm ) {
      return isDisabledForm;
    }

    if ( item.disableCriteria ) {
      return evalFunction( lead || [], item.disableCriteria ) as boolean;
    }

    return false;
  }, [ isDisabledForm, lead ] );

  React.useEffect( () => {
    if ( !previousIsFirstLoad.current ) {
      return;
    }

    let isMounted = true;

    if ( isSetupPayment ) {
      if ( lead[paymentDataCoreKey] && lead[paymentDataCoreKey].paymentMethod ) {
        setPaymentMethodName( lead[paymentDataCoreKey].paymentMethod );
      }

      return;
    }

    const loadPaymetData = async () => {
      try {
        const paymentData: IPaymentSystem = {
          stripe: {
            leadCode: leadData.leadCode,
          },
          productSlug: productData.productSlug,
        };

        const initiatePayment = await service.initiatePaymentSetup( paymentData );

        if ( isMounted ) {
          if ( lead[paymentDataCoreKey] && lead[paymentDataCoreKey].paymentMethod ) {
            setPaymentMethodName( lead[paymentDataCoreKey].paymentMethod );
          }

          if ( initiatePayment ) {
            setInitiatePaymentSetup( initiatePayment );
          }

          previousIsFirstLoad.current = false;
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          if ( isMounted ) {
            showAlert( {
              message: t( 'base:forms.messages.errorSave' ),
              type: 'danger',
            } );
          }
        } else {
          throw e;
        }
      }
    };

    loadPaymetData();

    return () => {
      isMounted = false;
    };
  }, [ service, reset, leadData.leadCode, showAlert, t, isSetupPayment, lead, productData.productSlug ] );

  const rejectionPopup = React.useCallback(
    async ( isClickNextBtn: boolean = false, isSkipSaveForLaterValidation: boolean = false,
    ): Promise<void> => {
      actions.setValidForm( isEmpty( formState.errors ) );

      if ( stepData && stepData.steps ) {
        const leadStore: ILeadData = await service.getLead();
        const rules = generateRules( stepData.steps, currentStep?.name, isSkipSaveForLaterValidation );

        const { enabled, ruleCriteriaSFL } = stepData.saveForLaterButton || {};
        if ( enabled && ruleCriteriaSFL && isSkipSaveForLaterValidation ) {
          rules.unshift( ruleCriteriaSFL );
        }

        actions.setRejected( false );
        setIsRejected( false );

        if ( rules ) {
          rules.forEach( async ( item ) => {
            const rejectionData = evalFunction( leadStore || [], item );
            if ( rejectionData && rejectionData['rejectionMessage'] ) {
              setIsRejected( true );
              const rejectionMessage = rejectionData['rejectionMessage'];
              if ( !rejectionData['suppressPopup'] || isClickNextBtn ) {
                if ( !isClickNextBtn && item.itemId && itemErrorIds.includes( item.itemId ) ) {
                  actions.setRejected( true );
                  return;
                }

                setItemErrorId( item.itemId! );
                setMessageError( rejectionMessage );
                setShowErrorPopup( true );
              } else {
                const infoMess = rejectionMessage.replace( htmlTagsRegex, '' );
                logger.warn( infoMess );
              }
              actions.setRejected( true );

              return;
            } else {
              const cloneIds = itemErrorIds;

              if ( cloneIds && item.itemId && cloneIds.includes( item.itemId ) ) {
                const errorIds = [ ...cloneIds.filter( ( id ) => id !== item.itemId ) ];
                setItemErrorIds( errorIds );

                await service.savedInfo( `${paymentDataCoreKey}_error_ids`, errorIds );
              }
            }
          } );
        }
      }
    }, [ actions, currentStep, formState.errors, itemErrorIds, logger, service, stepData ] );

  const changePaymentType = React.useCallback( ( paymetType: PaymentMethodName ) => {
    setPaymentMethodName( paymetType );
  }, [] );

  const onCloseErrorPopup = React.useCallback ( async ( ): Promise<void> => {
    if ( itemErrorId ) {
      const errorIds = [ ...itemErrorIds, itemErrorId ];
      setItemErrorIds( errorIds );

      await service.savedInfo( `${paymentDataCoreKey}_error_ids`, errorIds );
    }

    setShowErrorPopup( false );
    setMessageError( '' );
  }, [ itemErrorId, itemErrorIds, service ] );

  const onCloseSavedPopup = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    actions.reloadLead();
    setShowSavedStep( isShow );
  }, [ actions ] );

  const onSubmit = React.useCallback( async ( formData: PaymentCoreStepItem ) => {
    rejectionPopup( true );

    if ( isRejected || actions.isRejected() ) {
      return;
    }

    if ( isSetupPayment ) {
      actions.goToStep( stepData.previousStep!, false );

      return;
    }

    if ( !stripe || !elements ) {
      return;
    }

    const clientSecret = initiatePaymentSetup?.stripe?.pspSecretToken!;
    let setupIntentResult: SetupIntentResult | null = null;

    if ( paymentMethodName === PaymentMethodName.Card ) {
      const cardNumberElement: StripeCardNumberElement | null = elements.getElement( CardNumberElement );
      const dataCard: ConfirmCardSetupData = {
        payment_method: {
          card: cardNumberElement!,
          billing_details: {
            name: `${formData.accountFirstName!} ${formData.accountLastName!}`,
            email: formData.accountEmail!,
          },
        },
      };

      const confirmCard = await stripe.confirmCardSetup(
        clientSecret,
        dataCard,
      );

      setupIntentResult = confirmCard;
    }

    if ( paymentMethodName === PaymentMethodName.Sepa ) {
      const ibanElement: StripeIbanElement | null = elements.getElement( IbanElement );
      const dataSepa: ConfirmSepaDebitSetupData = {
        payment_method: {
          sepa_debit: ibanElement!,
          billing_details: {
            name: `${formData.accountFirstName!} ${formData.accountLastName!}`,
            email: formData.accountEmail!,
          },
        },
      };

      const confirmSepaDebit = await stripe.confirmSepaDebitSetup(
        clientSecret,
        dataSepa,
      );
      setupIntentResult = confirmSepaDebit;
    }

    if ( setupIntentResult && !setupIntentResult.error ) {
      const paymendData: IPaymentSystemComplete = {
        stripe: {
          firstName: formData.accountFirstName!,
          lastName: formData.accountLastName!,
          email: formData.accountEmail!,
          leadCode: leadData.leadCode,
          pspCustomerId: initiatePaymentSetup?.stripe?.pspCustomerId!,
          pspPaymentMethodId: setupIntentResult.setupIntent.payment_method!,
        },
        productSlug: productData.productSlug,
      };

      const completePayment = await service.completePaymentSetup( paymendData, formData );
      let nextStepInfo: StepInfoItem | undefined = undefined;
      if ( actions.getStepDataByName && stepData.nextStep ) {
        nextStepInfo = actions.getStepDataByName( stepData.nextStep );
      }

      if ( completePayment && stepData.nextStep && nextStepInfo
        && nextStepInfo.pageType !== JSONPageType.SuccessPage ) {
        actions.goToStep( stepData.nextStep, true );
      } else {
        if ( completePayment ) {
          try {
            const leadStore: ILeadData = await service.getLead();
            const initialLeadStatus = productData.stepsConfig?.initialLeadStatus;
            const leadStatus = initialLeadStatus ? initialLeadStatus : LeadStatus.Created;
            let defaultAccountEmail = '';

            if ( stepData && stepData.saveLeadOnComplete ) {
              defaultAccountEmail = stepData.saveLeadOnComplete.defaultAccountEmail;
            }

            let payloadLead = preparePayloadForLead(
              leadStore, productData, leadStatus, productCode, true, defaultAccountEmail,
            );

            const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );
            if ( savedLeadData ) {
              payloadLead.productVersionId = savedLeadData.policy.productVersionId;
            } else if ( configuration && configuration.productVersionId ) {
              payloadLead.productVersionId = configuration.productVersionId;
            }

            let isClearLead = lead.isLogin ? false : true;

            if ( nextStepInfo && nextStepInfo.pageType === JSONPageType.SuccessPage ) {
              isClearLead = false;
            }

            const result = savedLeadData ? await service.updateLead( payloadLead, isClearLead ) :
              await service.createLead( payloadLead, isClearLead );

            if ( result ) {
              const resLead = result.lead;
              if ( stepData.nextStep && nextStepInfo && nextStepInfo.pageType === JSONPageType.SuccessPage ) {
                actions.goToStep( stepData.nextStep, true );
                setTimeout( () => {
                  actions.onSubmit( resLead.leadNumber, false );
                }, 300 );
              } else {
                actions.onSubmit( resLead.leadNumber, true );
              }
            } else {
              showAlert( {
                message: t( 'base:forms.messages.errorSave' ),
                type: 'danger',
              } );
            }
          } catch( e ) {
            if ( e instanceof Error ) {
              showAlert( {
                message: t( 'base:forms.messages.errorSave' ),
                type: 'danger',
              } );
            } else {
              throw e;
            }
          }
        } else {
          showAlert( {
            message: t( 'base:forms.messages.errorSave' ),
            type: 'danger',
          } );
        }
      }
    } else {
      showAlert( {
        message: setupIntentResult ? setupIntentResult.error.message : t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
      actions.goToTopPage();
    }
  }, [ actions, configuration, elements, initiatePaymentSetup, isRejected, isSetupPayment,
    lead.isLogin, leadData.leadCode, paymentMethodName, productCode, productData,
    rejectionPopup, service, showAlert, stepData, stripe, t ] );

  React.useEffect( () => {
    let isMounted = true;
    const emailVerification = async () => {
      try {
        const { visibleIfEmailValidated, visibleEmailSettings, previousStep } = stepData;
        if ( isMounted && visibleIfEmailValidated && !lead.isLogin ) {
          setLoading( true );

          const email = visibleEmailSettings && visibleEmailSettings.emailName ?
            jsonpath.query( lead, `$.${visibleEmailSettings.emailName}` )[0] : undefined;
          const token = visibleEmailSettings && visibleEmailSettings.codeName ?
            jsonpath.query( lead, `$.${visibleEmailSettings.codeName}` )[0] : undefined;

          if ( token && email ) {
            const payload: CompleteEmailVerification = {
              email,
              token,
            };
            const response = await service.completeEmailVerification( payload );

            if ( response && response.result ) {
              setValidEmail( true );
            } else {
              let previousStepName = previousStep!;
              if ( visibleEmailSettings && visibleEmailSettings.codeName ) {
                const splitToken = visibleEmailSettings.codeName.split( '.' );

                if ( splitToken.length >= 2 ) {
                  previousStepName = splitToken[0];
                }
              }

              actions.goToStep( previousStepName, false );
            }
          }
          setLoading( false );
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          if ( isMounted ) {
            setLoading( false );
          }
        } else {
          throw e;
        }
      }
    };

    emailVerification();

    return () => {
      isMounted = false;
    };
  }, [ service, t, stepData, lead, actions ] );

  React.useEffect( () => {
    let isMounted = true;

    const formValues = formatFormData( getValues() );

    const saveDataToLocalStore = async ( ) => {
      if ( isEmpty( previousFormData.current ) ) {
        previousFormData.current = formValues;

        if ( !lead[paymentDataCoreKey] || isEmpty( lead[paymentDataCoreKey] ) ) {
          setLeadState( ( prevLead ) => ( {
            ...prevLead,
            [paymentDataCoreKey]: {
              ...prevLead[paymentDataCoreKey],
              ...previousFormData.current } as CFDefaultValueType,
          } ) );

          await service.savedInfo( paymentDataCoreKey, previousFormData.current );
        }

        rejectionPopup();

        return;
      }
    };

    const saveFormValues = async ( fieldInfo: string ) => {
      try {
        await service.savedInfo( paymentDataCoreKey, previousFormData.current );
        const storeLeadData = await service.getLead( 0 );

        setLeadState( storeLeadData );

        rejectionPopup();
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };

    saveDataToLocalStore();

    const changedField = getFieldDiff( formValues, previousFormData.current );

    if ( changedField && isMounted ) {
      previousFormData.current = formValues;

      const isSystemField = systemFields.some( ( item ) => changedField.startsWith( item ) );
      if ( isSystemField ) {
        isIgnoreOnRecalculation.current = true;
      } else {
        isIgnoreOnRecalculation.current = isIgnoreOnRecalculationFieldCore(
          changedField, currentStep!, productData.stepsConfig?.steps! );
      }

      const crossPolicyFields = generateCrossPolicyFields(
        productData.stepsConfig?.steps!, paymentDataCoreKey, changedField,
      );

      if ( actions.updatedStoreData && crossPolicyFields ) {
        actions.updatedStoreData( crossPolicyFields, previousFormData.current[changedField] );
      }

      saveFormValues( changedField );
    }

    return () => {
      isMounted = false;
    };
  }, [ actions, currentStep, formState.isValid, formState.isValidating, getValues, lead,
    productData.stepsConfig, rejectionPopup, service, showAlert, t ] );

  if ( initiatePaymentSetup === null && !isSetupPayment ) {
    return <div className="bf-loading col-auto py-4" style={ { height: 'auto' } }><LoadingSpinner /></div>;
  }

  if ( lead[stepData.previousStep!] === null ) {
    actions.goToStep( stepData.previousStep!, false );
  }

  return (
    <FormProvider { ...formContext }>
      <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
        { isLoading && loadingOnFullContainer }
        <Fragment>
          { currentStep && (
            <Fragment>
              { currentStep.title && (
                <Row>
                  <Col className="p-0" md={ 12 }
                    dangerouslySetInnerHTML={ { __html: `${currentStep.title}` } }
                  >
                  </Col>
                </Row>
              ) }
              { currentStep.subTitle && (
                <Row>
                  <Col className="p-0" md={ 12 }
                    dangerouslySetInnerHTML={ { __html: `${currentStep.subTitle}` } }
                  >
                  </Col>
                </Row>
              ) }
              <div
                className={ `${defaultLayoutData && defaultLayoutData[stepData.id] ? 'layout-container' : ''}` }
                style={
                  renderGridStyles(
                    defaultLayoutData,
                    LayoutLevel.SubStep,
                    currentStep.id,
                  )
                }
              >
                { currentStep.containers.map( ( container, index ) => (
                  <Fragment key={ index }>
                    { evalFunction( leadState || [], container.visibleCriteria ) && (
                      <Row
                        id={ `${ container.groupName ? `${container.groupName}-${index}` : index }-box` }
                        className={ renderContainerClass( container, defaultLayoutData ) }
                        style={
                          renderGridStyles(
                            defaultLayoutData,
                            LayoutLevel.Container,
                            currentStep.id,
                            container,
                          )
                        }
                      >
                        { ( container.title || container.subTitle ) && (
                          <Col
                            md={ 12 }
                            className="p-0 title-box"
                            style={
                              renderGridStyles(
                                defaultLayoutData,
                                LayoutLevel.TitelContainer,
                                currentStep.id,
                                container,
                              )
                            }
                          >
                            { container.title && (
                              <div
                                className="container-title"
                                dangerouslySetInnerHTML={ { __html: `${container.title}` } }
                              >
                              </div>
                            ) }
                            { container.subTitle && (
                              <div
                                className="container-sub-title"
                                dangerouslySetInnerHTML={ { __html: `${container.subTitle}` } }
                              >
                              </div>
                            ) }
                          </Col>
                        ) }
                        { container.items && container.items.map( ( item, itemIdx ) => (
                          <Fragment key={ itemIdx }>
                            { evalFunction( leadState || [], item.visibleCriteria ) && (
                              <Fragment>
                                { item.type && item.type === FieldType.DownloadLink && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <DownloadLink
                                      downloadLinks={ item.downloadLinks }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.Label && (
                                  <CoreLabel
                                    leadData={ leadState }
                                    className={ renderContainerItemClassName( item ) }
                                    stepItem={ item }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  />
                                ) }
                                { item.type && item.type === FieldType.PremiumPreview && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreTariffInfo
                                      lead={ leadState }
                                      productData={ productData }
                                      productCode={ productCode }
                                      isIgnoreOnRecalculation={ isIgnoreOnRecalculation.current }
                                      recalculationTariff={ recalculationTariff }
                                      subSteps={ stepData.steps }
                                      keyStep={ paymentDataCoreKey }
                                      policyStepNames={ policyStepNames }
                                      settings={ item.settings as PremiumSettings }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.DownloadInfo && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreDocumentDownloadInfo
                                      isOpened={ false }
                                      iconCard={ documentIcon }
                                      productCode={ productCode }
                                      lead={ leadState }
                                      productData={ productData! }
                                      policyStepNames={ policyStepNames }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.DownloadInfoExtended && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreDocumentDownloadInfo
                                      isOpened={ false }
                                      iconCard={ documentIcon }
                                      productCode={ productCode }
                                      lead={ leadState }
                                      productData={ productData! }
                                      policyStepNames={ policyStepNames }
                                      settings={ item.settings as DocumentsSettings }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.Consent && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreCheckbox
                                      stepItem={ item }
                                      productCode={ productCode }
                                      productData={ productData }
                                      formData={ leadState[paymentDataCoreKey]! }
                                      isDisabled={ isDisabledField( item ) }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.FileUpload && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <FileUpload
                                      stepItem={ item }
                                      productData={ productData }
                                      stepKey={ paymentDataCoreKey }
                                      settings={ item.settings as FileUploadSettings }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.StateVariable && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreDynamicField
                                      lead={ leadState }
                                      stepItem={ item }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.MultiInsuredObject && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreMultiInsuredObject
                                      productData={ productData }
                                      stepItem={ item }
                                      leadData={ leadState }
                                      formData={ leadState[paymentDataCoreKey]! }
                                      currentStepId={ currentStep.id }
                                      container={ container }
                                      nameStep={ paymentDataCoreKey }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.PaymentSettings && (
                                  <div className="bf-personal-core p-0 col-md-12">
                                    <div
                                      className={ renderContainerItemClassName( item ) }
                                      style={
                                        renderGridStyles(
                                          defaultLayoutData,
                                          LayoutLevel.Item,
                                          currentStep.id,
                                          container,
                                          item.id,
                                        )
                                      }
                                    >
                                      <Controller
                                        name="paymentMethod"
                                        control={ control }
                                        rules={ { required: true } }
                                        defaultValue={ paymentDataFields.paymentMethod }
                                        render={ ( props ) => (
                                          <FormGroup className="custom-btn-radio my-2">
                                            { item.settings && (
                                              <Fragment>
                                                { item.settings['hasSEPA'] && (
                                                  <div className="mb-4">
                                                    <FormControl
                                                      { ...props }
                                                      className="form-check-input"
                                                      type="radio"
                                                      id={ PaymentMethodName.Sepa }
                                                      value={ PaymentMethodName.Sepa }
                                                      checked={ props.value === PaymentMethodName.Sepa }
                                                      onClick={ () => changePaymentType( PaymentMethodName.Sepa ) }
                                                      onBlur={ props.onBlur }
                                                    />
                                                    <Form.Label
                                                      className="btn btn-border-radio"
                                                      htmlFor={ PaymentMethodName.Sepa }
                                                    >
                                                      { t( 'bookingFunnel.paymentData.sepaMandate' ) }
                                                      <img className="mr-3" src={ sepaIconUrl }
                                                        alt={ PaymentMethodName.Sepa }
                                                      />
                                                    </Form.Label>
                                                  </div>
                                                ) }
                                                { item.settings['hasCreditCard'] && (
                                                  <div className="mb-4">
                                                    <FormControl
                                                      { ...props }
                                                      className="form-check-input"
                                                      type="radio"
                                                      id={ PaymentMethodName.Card }
                                                      value={ PaymentMethodName.Card }
                                                      checked={ props.value === PaymentMethodName.Card }
                                                      onClick={ () => changePaymentType( PaymentMethodName.Card ) }
                                                      onBlur={ props.onBlur }
                                                    />
                                                    <Form.Label
                                                      className="btn btn-border-radio"
                                                      htmlFor={ PaymentMethodName.Card }
                                                    >
                                                      { t( 'bookingFunnel.paymentData.creditCard' ) }
                                                      <img className="mr-3" src={ cardIconUrl }
                                                        alt={ PaymentMethodName.Card }
                                                      />
                                                    </Form.Label>
                                                  </div>
                                                ) }
                                                { item.settings['hasInvoice'] && (
                                                  <div className="mb-4">
                                                    <FormControl
                                                      { ...props }
                                                      className="form-check-input"
                                                      type="radio"
                                                      id={ PaymentMethodName.Invoice }
                                                      value={ PaymentMethodName.Invoice }
                                                      checked={ props.value === PaymentMethodName.Invoice }
                                                      onClick={ () => changePaymentType( PaymentMethodName.Invoice ) }
                                                      onBlur={ props.onBlur }
                                                    />
                                                    <Form.Label
                                                      className="btn btn-border-radio"
                                                      htmlFor={ PaymentMethodName.Invoice }
                                                    >
                                                      { t( 'bookingFunnel.paymentData.invoice' ) }
                                                      <img className="mr-3" src={ sepaIconUrl }
                                                        alt={ PaymentMethodName.Invoice }
                                                      />
                                                    </Form.Label>
                                                  </div>
                                                ) }
                                              </Fragment>
                                            ) }
                                            <Form.Control
                                              type="hidden"
                                              isInvalid={ errors.paymentMethod !== undefined }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              { t( 'base:forms.messages.fieldRequired',
                                                { fieldLabel: t( 'bookingFunnel.paymentData.paymentMethod' ) } ) }
                                            </Form.Control.Feedback>
                                          </FormGroup>
                                        ) }
                                      />
                                    </div>
                                    { paymentMethodName === PaymentMethodName.Card && (
                                      <CardFields paymentFields={ paymentDataFields } />
                                    ) }
                                    { paymentMethodName === PaymentMethodName.Sepa && (
                                      <IbanFields
                                        paymentFields={ paymentDataFields }
                                        isSetupPayment={ isSetupPayment }
                                      />
                                    ) }
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.Policy && productData.variables.filter(
                                  ( v ) =>
                                    `${v.name}_${v.groupName}` === `${item.fieldName}_${item.insuredObjectName}`,
                                )
                                  .map( ( variable, idx ) => (
                                    <div
                                      key={ idx }
                                      className={ renderContainerItemClassName( item ) }
                                      style={
                                        renderGridStyles(
                                          defaultLayoutData,
                                          LayoutLevel.Item,
                                          currentStep.id,
                                          container,
                                          item.id,
                                        )
                                      }
                                    >
                                      <CoreFieldPanel
                                        key={ idx }
                                        formData={ leadState[paymentDataCoreKey]! }
                                        factors={ productData.factors }
                                        variable={ variable }
                                        stepItem={ item }
                                        fieldDefaultValue={
                                          fieldsDefaultValues && fieldsDefaultValues.find(
                                            ( f ) =>
                                              f.insuredObjectName.toLowerCase() === variable.groupName?.toLowerCase() &&
                                            f.productFieldName.toLowerCase() === variable.name.toLowerCase() )
                                        }
                                        isDisabled={ isDisabledField( item ) }
                                      />
                                    </div>
                                  ) ) }
                              </Fragment>
                            ) }
                          </Fragment>
                        ) ) }
                      </Row>
                    ) }
                  </Fragment>
                ) ) }
              </div>
            </Fragment>
          ) }
        </Fragment>
        <Row className="justify-content-center align-items-center mt-0 mx-0">
          <CoreCirclesNavigationMobile lead={ lead } />
        </Row>
        <Row className="bf-footer-btn-panel justify-content-center align-items-center mt-4 mx-0">
          { !isDisabledForm && saveForLaterButton && saveForLaterButton.enabled && (
            <>
              { saveForLaterButton.suppressEmailDialog ? (
                <CoreSaveLeadNav
                  currentStep={ currentStep! }
                  stepData={ stepData }
                  productCode={ productCode }
                  productData={ productData! }
                  disabled={ formState.isSubmitting }
                  rejectionPopup={ rejectionPopup }
                  isRejected={ isRejected }
                />
              ) : (
                <Button
                  id={ `${stepData.nextStep ? stepData.nextStep+'_' : ''}save_for_later` }
                  type="button"
                  variant="primary"
                  disabled={ formState.isSubmitting }
                  onClick={ () => onSaved( true ) }
                  className="col-sm-12 mr-0"
                >
                  { saveForLaterButton.buttonText ?
                    saveForLaterButton.buttonText : t( 'bookingFunnel.saveForLaterBtn' ) }
                </Button>
              ) }
            </>
          ) }
          <Button
            id={ `${COREBFStep.PaymentStep}_next` }
            type="submit"
            variant="primary"
            disabled={ formState.isSubmitting || !isValidEmail }
            className="col-sm-12 mr-0"
          >
            { stepData.nextStep ? t( 'bookingFunnel.nextBtn' ) : t( 'bookingFunnel.applyNowBtn' ) }
          </Button>
          <Button
            id={ `${COREBFStep.PaymentStep}_back` }
            type="button"
            variant="link"
            className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
            onClick={ () => actions.goToStep( stepData.previousStep!, true ) }
          >
            { t( 'bookingFunnel.previousBtn' ) }
          </Button>
        </Row>
      </Form>
      { isShowErrorPopup && messageError && (
        <TooltipPopupCore
          tooltipInfo={ messageError }
          onClose={ () => onCloseErrorPopup( ) }
        />
      ) }
      { isShowSavedStep && (
        <CoreSavedDataPopup
          currentStep={ stepData.nameStep }
          productCode={ productCode }
          productData={ productData! }
          onClose={ () => onCloseSavedPopup( ) }
          goNextStep={ goNextStep }
          policyStepNames={ policyStepNames }
        />
      ) }
    </FormProvider>
  );
};
