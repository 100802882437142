/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isValidIBAN } from 'ibantools';

export interface IIbanHook {
  isValidAccountIban( value: string ): string | boolean;
  getStripeBankIcon( hasError: boolean ): JSX.Element;
}

export const useIbanHook = (): IIbanHook => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const validIbanFormat = new RegExp(
    /\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b/,
  );

  const isValidAccountIban = React.useCallback( ( value: string ): string | boolean => {
    const iban = value.replace( /\s+/g, '' );
    const isValidFormat = validIbanFormat.test( iban );

    if( !isValidFormat ) {
      const errMessage = t( 'bookingFunnel.paymentData.accountIbanFormatError' );

      return errMessage;
    }

    if( !isValidIBAN( iban ) ) {
      const errMessage = t( 'bookingFunnel.ibanInvalid' );

      return errMessage;
    }

    return true;
  }, [ t, validIbanFormat ] );

  const getStripeBankIcon = React.useCallback( ( hasError: boolean ): JSX.Element => {
    return (
      <>
        { hasError ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
            <path d="M9.51 2.29l7.21 4.61a.6.6 0 0 1-.34 1.1H.62a.6.6 0 0 1-.34-1.1l7.21-4.61a1.89 1.89 0 0 1 2.02 0zM1.08 16.47l-.83 2c-.12.28 0 .57.28.57h15.66c.27 0 .41-.29.29-.57l-.84-2A.73.73 0 0 0 15 16H1.73a.74.74 0 0 0-.65.47z" opacity=".4" className="icon-fill" role="presentation"></path>
            <path d="M2 9h2.5v6H2zm10.5 0H15v6h-2.5zM7.25 9h2.5v6h-2.5z" opacity=".2" className="icon-fill"></path>
            <path d="M15.5 13A6.5 6.5 0 1 1 22 6.5a6.5 6.5 0 0 1-6.5 6.5z" className="icon-fill"></path>
            <path d="M14.57 2.79a.93.93 0 1 1 1.86 0V6.5a.93.93 0 0 1-1.86 0z" fill="#fff"></path>
            <circle cx="15.5" cy="9.75" r="1.16" fill="#fff"></circle>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2.5 0 22 22" role="presentation">
            <path className="icon-fill" d="M9.51 2.29l7.21 4.61a.6.6 0 0 1-.34 1.1H.62a.6.6 0 0 1-.34-1.1l7.21-4.61a1.89 1.89 0 0 1 2.02 0zM1.08 16.47l-.83 2c-.12.28 0 .57.28.57h15.66c.27 0 .41-.29.29-.57l-.84-2A.73.73 0 0 0 15 16H1.73a.74.74 0 0 0-.65.47z" opacity=".4"></path>
            <path className="icon-fill" d="M2 9h2.5v6H2zm10.5 0H15v6h-2.5zM7.25 9h2.5v6h-2.5z" opacity=".2"></path>
          </svg>
        ) }
      </>
    );
  }, [] );

  const utils = React.useMemo<IIbanHook>( () => {
    return {
      isValidAccountIban: ( value: string ): string | boolean => {
        return isValidAccountIban( value );
      },
      getStripeBankIcon: ( hasError: boolean ): JSX.Element => {
        return getStripeBankIcon( hasError );
      },
    };
  }, [ getStripeBankIcon, isValidAccountIban ] );

  return utils;
};
