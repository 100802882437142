import React, { Fragment } from 'react';
import { CorePolicyInfo } from './summary-views/CorePolicyInfo';
import { ILeadData, SummarySettings } from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { CorePersonalInfo } from './summary-views/CorePersonalInfo';
import { useCoreActions } from '../../DynamicCore';
import { JSONComponentType } from 'App/components/widgets/booking-funnel/enum';
import { CorePaymentInfo } from './summary-views/CorePaymentInfo';
import { SummaryLabel } from 'Services/widgets/interfaces';

interface CoreViewSummaryProps {
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  policyStepNames: string[];
  settings: SummarySettings;
  labels: SummaryLabel[];
}

export const CoreViewSummary: React.FC<CoreViewSummaryProps> = ( { lead, productData, settings, labels } ) => {
  const actions = useCoreActions();
  const allSteps = actions.getStepsInfo();
  const { disbledExpandView, autoExpand } = settings || {};

  let isOpened: boolean = false;

  if ( autoExpand ) {
    isOpened = autoExpand;
  }

  if ( disbledExpandView ) {
    isOpened = true;
  }

  return (
    <Fragment>
      { allSteps && allSteps.map( ( step, idx ) => (
        <Fragment key={ idx }>
          { step.componentType === JSONComponentType.Policy && (
            <CorePolicyInfo
              isOpened={ isOpened }
              currentStep={ step }
              labels={ labels }
              productData={ productData }
              lead={ lead! }
              settings={ settings }
            />
          ) }
          { step.componentType === JSONComponentType.PersonalData && (
            <CorePersonalInfo
              isOpened={ isOpened }
              currentStep={ step }
              labels={ labels }
              productData={ productData }
              lead={ lead! }
              settings={ settings }
            />
          ) }
          { step.componentType === JSONComponentType.Payment && (
            <CorePaymentInfo
              isOpened={ isOpened }
              currentStep={ step }
              labels={ labels }
              productData={ productData }
              lead={ lead! }
              settings={ settings }
            />
          ) }
        </Fragment>
      ) ) }
    </Fragment>
  );
};
