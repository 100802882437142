
import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import DateUtil from '../../../date-util';
import { blankLink } from 'config';
import { Form, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContainerFieldItem, ITariffDataStep } from 'Services/widgets/interfaces';
import { Separators } from 'Services/widgets/enums';
import {
  checkedRecalculation,
  checkedUnderwriting,
  formatDefaultDate,
  formatInputDate,
} from '../../../../booking-funnel/booking-funnel-hooks';
import { DateRangeComponent } from './DateRangeComponent';
import { isCheckedByField, renderClassNameBox } from '../../../core-hooks';
import { useAppLogger } from 'Services/logger';
import { useCoreActions } from '../../../DynamicCore';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { PolicyEditData } from '../../../PolicyEdit';
import { TooltipCore } from '../TooltipCore';
import { htmlTagsRegex } from './CoreAddressAutoComplete';
import { HiddenField } from '../../steps/personal-sub-steps/form-fields/HiddenField';
import { JsonObject } from '@cover42/protobuf-util';
import { isObject } from 'App/components/widgets/bf-hooks';

export interface CoreDateRangeProps {
  stepItem: ContainerFieldItem;
  formData: ITariffDataStep;
  productData: IDataFactorsAndVariables | PolicyEditData;
  isDisabled?: boolean;
}

interface HiddenFieldDate {
  insuredObjectName: string;
  fieldName: string;
  fieldKey: string;
}

export const fieldNameStartDate = 'startDateField';
export const fieldNameEndDate = 'endDateField';

const getHiddenFields = ( stepItem: ContainerFieldItem ): HiddenFieldDate[] => {
  const hiddenFields: HiddenFieldDate[] = [];

  if ( stepItem && stepItem[fieldNameStartDate] ) {
    const [ insuredObjectName, fieldName ] = stepItem && stepItem[fieldNameStartDate].split( '.' );

    hiddenFields.push( { insuredObjectName, fieldName, fieldKey: fieldNameStartDate } );
  }

  if ( stepItem && stepItem[fieldNameEndDate] ) {
    const [ insuredObjectName, fieldName ] = stepItem && stepItem[fieldNameEndDate].split( '.' );

    hiddenFields.push( { insuredObjectName, fieldName, fieldKey: fieldNameEndDate } );
  }

  return hiddenFields;
};

export const CoreDateRange: React.FC<CoreDateRangeProps> = ( {
  stepItem,
  formData,
  productData,
  isDisabled,
} ) => {
  const { t, i18n } = useTranslation( [ 'widgets', 'base' ] );
  const logger = useAppLogger();
  const actions = useCoreActions();
  const { errors, control, setValue, setError, getValues } = useFormContext();
  const { variables } = productData;
  const allFields = getValues();

  const separatorDot = Separators.Dot;
  const lng = i18n.language;
  const formatDateText = lng === 'de' ? 'TT.MM.JJJJ - TT.MM.JJJJ' : 'DD.MM.YYYY - DD.MM.YYYY';
  const { label: labelField, placeholder, tooltip } = stepItem;
  const controlName = stepItem.fieldName;
  const dateField = formData ? formData[controlName] : '';
  const hiddenFields = getHiddenFields( stepItem );

  const [ showTooltip, setShowTooltip ] = React.useState<boolean>( false );
  const [ bfTooltip, setBfTooltip ] = React.useState<string>( '' );
  const [ targetLink, setTargetLink ] = React.useState<React.ReactInstance | undefined>( undefined );

  const onShowTooltip = React.useCallback ( (
    isShow: boolean, tooltipHtml: string, target?: React.ReactInstance,
  ): void => {
    setShowTooltip( isShow );
    setBfTooltip( tooltipHtml );
    if ( target ) {
      setTargetLink( target );
    }
  }, [] );

  const getFromDate = React.useCallback( ( ): string => {
    if ( stepItem && stepItem['minValue'] ) {
      const minValue = DateUtil.convertValueToDateTime( stepItem['minValue'] );
      if ( minValue ) {
        return minValue;
      }
    }

    return '';
  }, [ stepItem ] );

  const getToDate = React.useCallback( ( ): string => {
    if ( stepItem && stepItem['maxValue'] ) {
      const maxValue = DateUtil.convertValueToDateTime( stepItem['maxValue'] );
      if ( maxValue ) {
        return maxValue;
      }
    }

    return '';
  }, [ stepItem ] );

  const getDateByFieldName = React.useCallback( ( currentFieldName, propertyField: string ): string => {
    if ( stepItem && stepItem[currentFieldName] ) {
      const [ , fieldName ] = stepItem && stepItem[currentFieldName].split( '.' );
      const foundField = variables.find( ( v ) => v.name === fieldName );

      if ( foundField && isObject( foundField[propertyField] ) ) {
        const objectDate = foundField[propertyField] as JsonObject;
        if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
          return objectDate.evaluated as string;
        }
      }
    }

    return '';
  }, [ stepItem, variables ] );

  const setHiddenFields = React.useCallback( ( startDate: string, endDate: string ): void => {
    if ( isDisabled ) {
      return;
    }

    for ( const { insuredObjectName, fieldName, fieldKey } of hiddenFields ) {
      const inputName = `${fieldName}_${insuredObjectName}`;

      if ( fieldKey === fieldNameStartDate ) {
        setValue(
          inputName,
          startDate ? dayjs( startDate ).format( formatDefaultDate ) : '',
          { shouldValidate: true },
        );
      }

      if ( fieldKey === fieldNameEndDate ) {
        setValue(
          inputName,
          endDate ? dayjs( endDate ).format( formatDefaultDate ) : '',
          { shouldValidate: true },
        );
      }
    }
  }, [ hiddenFields, isDisabled, setValue ] );

  const isValidDateField = React.useCallback( ( [ newStartDate, newEndDate ] ): boolean => {
    if ( isDisabled ) {
      return true;
    }

    const currentStartDate = dayjs( newStartDate, formatDefaultDate, false );
    const currentEndDate = dayjs( newEndDate, formatDefaultDate, false );
    const fromDateVal = getFromDate() ? dayjs( getFromDate(), formatDefaultDate, false ) : null;
    const toDateVal = getToDate() ? dayjs( getToDate(), formatDefaultDate, false ) : null;

    if ( currentStartDate && currentEndDate && currentEndDate.isBefore( currentStartDate, 'date' ) ) {
      return false;
    }

    if ( currentStartDate && currentEndDate && currentStartDate.isAfter( currentEndDate, 'date' ) ) {
      return false;
    }

    if ( fromDateVal && fromDateVal.isAfter( currentStartDate, 'date' ) ) {
      return false;
    }

    if ( toDateVal && toDateVal.isBefore( currentEndDate, 'date' ) ) {
      return false;
    }

    return true;
  }, [ getFromDate, getToDate, isDisabled ] );

  const isValidStartDateField = React.useCallback( ( newStartDate ): string => {
    let resErrMessage = '';
    if ( isDisabled ) {
      return resErrMessage;
    }

    const currentStartDate = dayjs( newStartDate, formatDefaultDate, false );

    const minValue = getDateByFieldName( fieldNameStartDate, 'minValue' );
    const maxValue = getDateByFieldName( fieldNameStartDate, 'maxValue' );

    const fromDateVal = minValue ? dayjs( minValue, formatDefaultDate, false ) : null;
    const toDateVal = maxValue ? dayjs( maxValue, formatDefaultDate, false ) : null;

    const errMessage = t( 'base:forms.messages.startDateRangeError', {
      minDate: dayjs( minValue ).format( formatInputDate ),
      maxDate: dayjs( maxValue ).format( formatInputDate ),
    } );

    if ( fromDateVal && fromDateVal.isAfter( currentStartDate, 'date' ) ) {
      resErrMessage = errMessage;
    }

    if ( toDateVal && toDateVal.isBefore( currentStartDate, 'date' ) ) {
      resErrMessage = errMessage;
    }

    return resErrMessage;
  }, [ getDateByFieldName, isDisabled, t ] );

  const isValidEndDateField = React.useCallback( ( newEndDate ): string => {
    let resErrMessage = '';
    if ( isDisabled ) {
      return resErrMessage;
    }

    const currentEndDate = dayjs( newEndDate, formatDefaultDate, false );
    const minValue = getDateByFieldName( fieldNameEndDate, 'minValue' );
    const maxValue = getDateByFieldName( fieldNameEndDate, 'maxValue' );
    const fromDateVal = minValue ? dayjs( minValue, formatDefaultDate, false ) : null;
    const toDateVal = maxValue ? dayjs( maxValue, formatDefaultDate, false ) : null;

    const errMessage = t( 'base:forms.messages.startDateRangeError', {
      minDate: dayjs( minValue ).format( formatInputDate ),
      maxDate: dayjs( maxValue ).format( formatInputDate ),
    } );

    if ( fromDateVal && fromDateVal.isAfter( currentEndDate, 'date' ) ) {
      resErrMessage = errMessage;
    }

    if ( toDateVal && toDateVal.isBefore( currentEndDate, 'date' ) ) {
      resErrMessage = errMessage;
    }

    return resErrMessage;
  }, [ getDateByFieldName, isDisabled, t ] );

  const dateRangeHandler = React.useCallback( ( dateVal: string, nameField: string ) => {
    const [ startDate, endDate ] = dateVal.split( ' - ' );

    if ( startDate === 'null' || endDate === 'null' ) {
      setValue( nameField, '', { shouldValidate: true } );
    }

    if ( !isValidDateField( [ startDate, endDate ] ) ) {
      const errMessage = t( 'base:forms.messages.dateError', {
        minDate: dayjs( getFromDate() ).format( formatInputDate ),
        maxDate: dayjs( getToDate() ).format( formatInputDate ),
      } );

      setError( nameField, { shouldFocus: true, message: errMessage } );
    }

    if ( !startDate && !endDate && isValidDateField( [ startDate, endDate ] ) ) {
      const dateRangeVal =
      `${dayjs( startDate ).format( formatDefaultDate )} - ${dayjs( endDate ).format( formatDefaultDate )}`;
      setValue( nameField, dateRangeVal, { shouldValidate: true } );
      setHiddenFields( startDate, endDate );

      const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
      const isIgnoreOnRecalculation = checkedRecalculation( stepItem! );
      actions.recalculationPremium( isCheckedUnderwriting, isIgnoreOnRecalculation );

      return;
    }

    const selectedStartDate = dayjs( startDate );
    const selectedEndDate = dayjs( endDate );

    if ( selectedStartDate.isValid() && selectedEndDate.isValid() ) {
      const dateRangeVal =
      `${selectedStartDate.format( formatDefaultDate )} - ${selectedEndDate.format( formatDefaultDate )}`;
      setValue( nameField, dateRangeVal, { shouldValidate: true } );
      setHiddenFields( startDate, endDate );

      const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
      const isIgnoreOnRecalculation = checkedRecalculation( stepItem! );
      actions.recalculationPremium( isCheckedUnderwriting, isIgnoreOnRecalculation );
    }
  }, [ actions, getFromDate, getToDate, isValidDateField, setError, setHiddenFields, setValue, stepItem, t ] );

  const isValidFormatDate = React.useCallback( ( inputDate: string ): boolean => {
    if ( inputDate ) {
      const [ startDate, endDate ] = inputDate.split( ' - ' );
      const resStartDate = startDate.replace( Separators.Underscore, '' );
      const resEndDate = endDate.replace( Separators.Underscore, '' );

      if ( resStartDate.length < 10 || resEndDate.length < 10 ) {
        return false;
      }

      const formatStartDate = resStartDate.split( separatorDot ).reverse().join( Separators.Dash );
      const formatEndDate = resEndDate.split( separatorDot ).reverse().join( Separators.Dash );

      return dayjs( formatStartDate ).isValid() && dayjs( formatEndDate ).isValid();
    }

    return false;
  }, [ separatorDot ] );

  const dateRangeBlurHandler = React.useCallback( ( dateVal: string, nameField: string ): void => {
    if ( !isValidFormatDate( dateVal ) ) {
      const errMessage = t( 'base:forms.messages.dateRangeInvalid',
        {
          fieldFormat: formatDateText,
        },
      );

      setError( nameField, { shouldFocus: true, message: errMessage } );
    }
  }, [ formatDateText, isValidFormatDate, setError, t ] );

  const getDefaultValue = React.useCallback( ( valField: string ): string => {
    if ( valField ) {
      const fromDateVal = getFromDate() ? dayjs( getFromDate(), formatDefaultDate, false ) : null;
      const toDateVal = getToDate() ? dayjs( getToDate(), formatDefaultDate, false ) : null;
      const errMessage = t( 'base:forms.messages.dateError', {
        minDate: dayjs( fromDateVal ).format( formatInputDate ),
        maxDate: dayjs( toDateVal ).format( formatInputDate ),
      } );

      const [ startDate, endDate ] = valField.split( ' - ' );
      const formatStartDefaultVal = dayjs( startDate ).format( formatInputDate );
      const formatEndDefaultVal = dayjs( endDate ).format( formatInputDate );

      if ( fromDateVal && fromDateVal.isAfter( startDate, 'date' ) ) {
        logger.error( `${errMessage} Default date: ${formatStartDefaultVal}` );

        return `${fromDateVal.format( formatDefaultDate )} - ${endDate}`;
      }

      if ( toDateVal && toDateVal.isBefore( endDate, 'date' ) ) {
        logger.error( `${errMessage} Default date: ${formatEndDefaultVal}` );

        return `${startDate} - ${toDateVal.format( formatDefaultDate )}`;
      }

      return valField;
    }

    let startDate = '';
    let endDate = '';
    if ( stepItem && stepItem[fieldNameStartDate] ) {
      const [ insuredObjectName, fieldName ] = stepItem && stepItem[fieldNameStartDate].split( '.' );
      const startDateField = `${fieldName}_${insuredObjectName}`;

      if ( formData && formData[startDateField] ) {
        startDate = dayjs( formData[startDateField] ).format( formatDefaultDate );
      }
    }

    if ( stepItem && stepItem[fieldNameEndDate] ) {
      const [ insuredObjectName, fieldName ] = stepItem && stepItem[fieldNameEndDate].split( '.' );
      const endDateField = `${fieldName}_${insuredObjectName}`;

      if ( formData && formData[endDateField] ) {
        endDate = dayjs( formData[endDateField] ).format( formatDefaultDate );
      }
    }

    if ( startDate && endDate ) {
      return `${startDate} - ${endDate}`;
    }

    return '';
  }, [ formData, getFromDate, getToDate, logger, stepItem, t ] );

  const isFieldSetInForm = React.useCallback( ( fieldName: string ): boolean => {
    Object.keys( allFields ).forEach( ( fieldKey ) => {
      if ( fieldKey === fieldName ) {
        return true;
      }
    } );

    return false;
  }, [ allFields ] );

  return (
    <div className="core-date-range">
      <Controller
        name={ controlName }
        control={ control }
        rules={ {
          required: isDisabled ? false : isCheckedByField( 'isRequired', stepItem! ),
          validate: ( value ) => {
            if ( isDisabled ) {
              return true;
            }

            const [ startDate, endDate ] = value.split( ' - ' );
            if( !isValidDateField( [ startDate, endDate ] ) ) {
              const errMessage = t( 'base:forms.messages.dateError', {
                minDate: dayjs( getFromDate() ).format( formatInputDate ),
                maxDate: dayjs( getToDate() ).format( formatInputDate ),
              } );

              return errMessage;
            }

            const errStartDateMessage = isValidStartDateField( startDate );

            if( errStartDateMessage ) {
              return errStartDateMessage;
            }

            const errEndDateMessage = isValidEndDateField( endDate );

            if( errEndDateMessage ) {
              return errEndDateMessage;
            }
          },
        } }
        defaultValue={ getDefaultValue( dateField ) }
        render={ ( props ) => (
          <FormGroup
            className={
              `${renderClassNameBox( stepItem )} core-date-box date-picker-field
              ${errors[props.name] ? ' core-date-error' : ''}`
            }
            controlId={ props.name }
          >
            { labelField && (
              <Form.Label id={ `${controlName}-label` } className={ tooltip ? 'tooltip-label' : '' }>
                <div
                  className="d-inline-block"
                  dangerouslySetInnerHTML={ { __html: `${labelField}` } }
                />
                { tooltip && (
                  <a
                    className="tooltip-info"
                    href={ blankLink }
                    role='button'
                    onClick={ ( e ) => {
                      e.preventDefault();
                      onShowTooltip( true, tooltip!, e.target as unknown as React.ReactInstance );
                    } }
                  >
                    { t( 'bookingFunnel.tooltipHelp' ) }
                  </a>
                ) }
              </Form.Label>
            ) }
            <DateRangeComponent
              currentValue={ props.value }
              onChangeDate={ ( date: string ) => dateRangeHandler( date, props.name ) }
              onBlurDate={ ( date: string ) => dateRangeBlurHandler( date, props.name ) }
              fromDate={ getFromDate() }
              toDate={ getToDate() }
              separator={ separatorDot }
              placeholderText={ placeholder }
              isReadOnly={ false }
              showMonthYearPicker={ false }
              showFullMonthYearPicker={ false }
              isDisabled={ isDisabled }
            />
            <Form.Control
              { ...props }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired', {
                    fieldLabel: labelField?.replace( htmlTagsRegex, '' ),
                  } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>

            { hiddenFields && hiddenFields.map( ( field, idx ) => {
              const { fieldName, insuredObjectName } = field;

              const hiddenName = `${fieldName}_${insuredObjectName}`;

              const isSetField = isFieldSetInForm( hiddenName );

              if ( !isSetField ) {
                return (
                  <React.Fragment key={ idx }>
                    <HiddenField
                      isRequired={ false }
                      fieldName={ hiddenName }
                      fieldValue={ formData && formData[hiddenName] }
                    />
                  </React.Fragment>
                );
              }

              return null;
            } ) }
          </FormGroup>
        ) }
      />
      { showTooltip && bfTooltip && (
        <TooltipCore
          tooltipInfo={ bfTooltip }
          onClose={ () => onShowTooltip( false, '' ) }
          targetLink={ targetLink }
        />
      ) }
    </div>
  );
};

