import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBookingFunnelStepProps } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { loadStripe, Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CoreStripeForm } from './forms/stripe-v2/CoreStripeForm';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { UseFormMethods } from 'react-hook-form';
import { PaymentCoreStepItem } from '../PaymentCoreStep';
import { RecalculationTariff } from '../../../DynamicCore';
import { StepInfoItem } from '../../../interfaces';

export interface StripeSystemV2CoreProps extends IBookingFunnelStepProps {
  productCode: string;
  formContext: UseFormMethods<PaymentCoreStepItem>;
  stepData: StepInfoItem;
  policyStepNames: string[];
  recalculationTariff: RecalculationTariff;
}

export const StripeSystemV2: React.FC<StripeSystemV2CoreProps> = (
  { lead, productData, productCode, formContext, stepData, policyStepNames, recalculationTariff },
) => {
  const { i18n } = useTranslation( [ 'widgets', 'base' ] );
  const { leadData } = productData;

  const [ stripePromise, setStripePromise ] = React.useState<Stripe | null>( null );

  React.useEffect( () => {
    let isMounted = true;

    const fetchStripe = async () => {
      const publicKey = leadData.data.publicKey;
      const lng: StripeElementLocale = i18n.language as StripeElementLocale;
      const res = await loadStripe(
        publicKey,
        {
          locale: lng,
        },
      );

      if ( isMounted ) {
        setStripePromise( res );
      }
    };

    fetchStripe();

    return () => {
      isMounted = false;
    };
  }, [ i18n.language, leadData.data.publicKey ] );

  if ( stripePromise === null ) {
    return <div className="bf-loading"><LoadingSpinner /></div>;
  }

  return (
    <Elements stripe={ stripePromise }>
      <CoreStripeForm
        formContext={ formContext }
        lead={ lead }
        productData={ productData }
        productCode={ productCode }
        stepData={ stepData }
        policyStepNames={ policyStepNames }
        recalculationTariff={ recalculationTariff }
      />
    </Elements>
  );
};
