import React, { Fragment } from 'react';
import cardIconUrl from 'assets/bf-images/card.svg';
import sepaIconUrl from 'assets/bf-images/sepa.svg';
import { Row, Col, Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useWidgetService } from 'Services/widget';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import {
  CardNumberElement,
  IbanElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  ConfirmCardSetupData,
  ConfirmSepaDebitSetupData,
  SetupIntentResult,
  StripeCardNumberElement,
  StripeIbanElement,
} from '@stripe/stripe-js/types/stripe-js';
import { PaymentMethodName } from 'Services/widgets/enums';
import { IInitiatePaymentSetup, IPaymentSystem, IPaymentSystemComplete } from 'Services/widgets/interfaces';
import {
  IBookingFunnelStepProps,
  IDataFactorsAndVariables,
  useBookingFunnelActions,
} from 'App/components/widgets/booking-funnel/BookingFunnel';
import { IPaymentDataStep } from '../../../PaymentStep3';
import { IbanFields } from './IbanFields';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { CardFields } from './CardFields';
import { BFStep } from 'App/components/widgets/booking-funnel/enum';

const mainStepName = 'PaymentStep';
const subStep1Name = 'payment1.1';

// TODO: move to configuration lvl
const PaymentOptionsPlaceholder = {
  [PaymentMethodName.Card]: {
    icon: cardIconUrl,
    title: 'bookingFunnel.paymentData.bankTransfer',
  },
  [PaymentMethodName.Sepa]: {
    icon: sepaIconUrl,
    title: 'bookingFunnel.paymentData.sepaMandate',
  },
};

export const StripeForm: React.FC<IBookingFunnelStepProps> = ( { lead, isCustomLayout, productData } ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useBookingFunnelActions();
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();
  const [ paymentMethodName, setPaymentMethodName ] = React.useState<string>( '' );
  const [ initiatePaymentSetup, setInitiatePaymentSetup ] = React.useState<IInitiatePaymentSetup | null>( null );
  const { leadData, isSetupPayment } = productData;
  const steps = productData.stepsConfig?.steps?.find( ( s ) => s.name === mainStepName );
  const subStep1Data = steps?.subSteps.find( ( item ) => item.name === subStep1Name );

  const stripe = useStripe();
  const elements = useElements();

  let paymentDataFields: IPaymentDataStep = {
    paymentMethod: '',
    accountFirstName: lead.personalData?.firstName,
    accountLastName: lead.personalData?.lastName,
    accountEmail: lead.personalData?.email,
  };

  if ( lead.paymentData !== null ) {
    paymentDataFields = lead.paymentData as IPaymentDataStep;
  }

  const formContext = useForm<IPaymentDataStep>( {
    mode: 'onChange',
  } );

  const { errors, control, formState, reset } = formContext;

  React.useEffect( () => {
    let isMounted = true;

    if ( isSetupPayment ) {
      if ( lead.paymentData?.paymentMethod ) {
        setPaymentMethodName( lead.paymentData?.paymentMethod );
      }

      return;
    }

    const loadPaymetData = async () => {
      try {
        const paymentData: IPaymentSystem = {
          stripe: {
            leadCode: leadData.leadCode,
          },
          productSlug: productData.productSlug,
        };

        const initiatePayment = await service.initiatePaymentSetup( paymentData );

        if ( isMounted ) {
          if ( lead.paymentData?.paymentMethod ) {
            setPaymentMethodName( lead.paymentData?.paymentMethod );
          }

          if ( initiatePayment ) {
            setInitiatePaymentSetup( initiatePayment );
          }
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          if ( isMounted ) {
            showAlert( {
              message: t( 'base:forms.messages.errorSave' ),
              type: 'danger',
            } );
          }
        } else {
          throw e;
        }
      }
    };

    loadPaymetData();

    return () => {
      isMounted = false;
    };
  }, [ service, reset, lead.paymentData, leadData.leadCode, showAlert, t, isSetupPayment, productData.productSlug ] );

  const changePaymentType = React.useCallback( ( paymetType: PaymentMethodName ) => {
    setPaymentMethodName( paymetType );
  }, [] );

  const onSubmit = React.useCallback( async ( formData: IPaymentDataStep ) => {
    if ( isSetupPayment ) {
      actions.goToStep( 'summary', true );

      return;
    }

    if ( !stripe || !elements ) {
      return;
    }

    const clientSecret = initiatePaymentSetup?.stripe?.pspSecretToken!;
    let setupIntentResult: SetupIntentResult | null = null;

    if ( paymentMethodName === PaymentMethodName.Card ) {
      const cardNumberElement: StripeCardNumberElement | null = elements.getElement( CardNumberElement );
      const dataCard: ConfirmCardSetupData = {
        payment_method: {
          card: cardNumberElement!,
          billing_details: {
            name: `${formData.accountFirstName!} ${formData.accountLastName!}`,
            email: formData.accountEmail!,
          },
        },
      };

      const confirmCard = await stripe.confirmCardSetup(
        clientSecret,
        dataCard,
      );

      setupIntentResult = confirmCard;
    }

    if ( paymentMethodName === PaymentMethodName.Sepa ) {
      const ibanElement: StripeIbanElement | null = elements.getElement( IbanElement );
      const dataSepa: ConfirmSepaDebitSetupData = {
        payment_method: {
          sepa_debit: ibanElement!,
          billing_details: {
            name: `${formData.accountFirstName!} ${formData.accountLastName!}`,
            email: formData.accountEmail!,
          },
        },
      };

      const confirmSepaDebit = await stripe.confirmSepaDebitSetup(
        clientSecret,
        dataSepa,
      );
      setupIntentResult = confirmSepaDebit;
    }

    if ( setupIntentResult && !setupIntentResult.error ) {
      const paymendData: IPaymentSystemComplete = {
        stripe: {
          firstName: formData.accountFirstName!,
          lastName: formData.accountLastName!,
          email: formData.accountEmail!,
          leadCode: leadData.leadCode,
          pspCustomerId: initiatePaymentSetup?.stripe?.pspCustomerId!,
          pspPaymentMethodId: setupIntentResult.setupIntent.payment_method!,
        },
        productSlug: productData.productSlug,
      };

      const completePayment = await service.completePaymentSetup( paymendData, formData );
      if ( completePayment ) {
        actions.goToStep( 'summary', true );

        const updatedProduct: IDataFactorsAndVariables = {
          ...productData,
          isSetupPayment: true,
        };

        actions.reload( updatedProduct );
      } else {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    } else {
      showAlert( {
        message: setupIntentResult ? setupIntentResult.error.message : t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
      actions.goToTopPage();
    }
  }, [ actions, elements, initiatePaymentSetup, isSetupPayment, leadData.leadCode, paymentMethodName,
    productData, service, showAlert, stripe, t ] );

  if ( lead.personalData === null ) {
    actions.goToStep( 'personal-data', false );
  }

  if ( initiatePaymentSetup === null && !isSetupPayment ) {
    return <div className="my-5"><LoadingSpinner /></div>;
  }

  return (
    <FormProvider { ...formContext }>
      <Row>
        <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
          <Row>
            <Col sm={ 12 } className="mb-4">
              <Controller
                name="paymentMethod"
                control={ control }
                rules={ { required: true } }
                defaultValue={ paymentDataFields.paymentMethod }
                render={ ( props ) => (
                  <FormGroup className="custom-btn-radio my-2">
                    { subStep1Data && subStep1Data.containers ? subStep1Data.containers.map( ( container ) => (
                      container.items.map( ( item, index ) => {
                        return (
                          <Fragment key={ index }>
                            <FormControl
                              { ...props }
                              className="form-check-input"
                              type="radio"
                              id={ item.paymentType }
                              value={ item.paymentType }
                              checked={ props.value === item.paymentType }
                              onClick={ () => changePaymentType( item.paymentType! as PaymentMethodName ) }
                              onBlur={ props.onBlur }
                            />
                            <Form.Label className="btn btn-border-radio" htmlFor={ item.paymentType }>
                              <img className="mr-3"
                                src={ PaymentOptionsPlaceholder[item.paymentType!].icon }
                                alt={ item.paymentType }
                              />
                              { t( PaymentOptionsPlaceholder[item.paymentType!].title ) }
                            </Form.Label>
                          </Fragment>
                        );
                      },
                      )
                    ) ) : (
                      <Fragment>
                        <FormControl
                          { ...props }
                          className="form-check-input"
                          type="radio"
                          id={ PaymentMethodName.Sepa }
                          value={ PaymentMethodName.Sepa }
                          checked={ props.value === PaymentMethodName.Sepa }
                          onClick={ () => changePaymentType( PaymentMethodName.Sepa ) }
                          onBlur={ props.onBlur }
                        />
                        <Form.Label className="btn btn-border-radio" htmlFor={ PaymentMethodName.Sepa }>
                          <img className="mr-3" src={ sepaIconUrl } alt={ PaymentMethodName.Sepa } />
                          { t( 'bookingFunnel.paymentData.sepaMandate' ) }
                        </Form.Label>
                        <Form.Control
                          type="hidden"
                          isInvalid={ errors.paymentMethod !== undefined }
                        />
                      </Fragment>
                    )
                    }
                    <Form.Control.Feedback type="invalid">
                      { t( 'base:forms.messages.fieldRequired',
                        { fieldLabel: t( 'bookingFunnel.paymentData.paymentMethod' ) } ) }
                    </Form.Control.Feedback>
                  </FormGroup>
                ) }
              />
            </Col>
            { paymentMethodName === PaymentMethodName.Card && (
              <CardFields paymentFields={ paymentDataFields } />
            ) }
            { paymentMethodName === PaymentMethodName.Sepa && (
              <IbanFields
                paymentFields={ paymentDataFields }
                isSetupPayment={ isSetupPayment }
              />
            ) }
            <Col sm={ 12 } className="mb-4">
              <p className="text-c-black text-left">
                { t( 'bookingFunnel.paymentData.confirmNote' ) }
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center m-0 mt-5">
            <Button
              id={ `${BFStep.PaymentStep}_next` }
              variant="primary"
              disabled={ formState.isSubmitting }
              className="col-sm-12 mr-0"
              type="submit"
            >
              { t( 'bookingFunnel.nextBtn' ) }
            </Button>
            { isCustomLayout && (
              <button
                id={ `${BFStep.PaymentStep}_back` }
                type="button"
                className="btn btn-link mt-2 mb-2 mx-0 p-0 text-c-black f-16 custom-back-button"
                onClick={ () => actions.goToStep( 'personal-data', false ) }
              >
                <span className="back-text align-middle">
                  { t( 'bookingFunnel.previousBtn' ) }
                </span>
              </button>
            ) }
          </Row>
        </Form>
      </Row>
    </FormProvider>
  );
};
