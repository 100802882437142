import React, { Fragment } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ILeadData } from 'Services/widgets/interfaces';
import { AppAlertUI } from 'App/components/utils/alerts/AppAlertService';
import { RecalculationTariff, useCoreActions } from '../../../DynamicCore';
import { IDataFactorsAndVariables } from '../../../../booking-funnel/BookingFunnel';
import { evalFunction } from '../../../../booking-funnel/functions';
import { StepInfoItem } from '../../../interfaces';
import { CoreRenderContainer } from '../../core-ui-components/CoreRenderContainer';
import { useWidgetService } from 'Services/widget';

export interface SuccessPageStepProps {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  stepData: StepInfoItem;
  recalculationTariff: RecalculationTariff;
  policyStepNames: string[];
}

export const SuccessPageStep: React.FC<SuccessPageStepProps> = ( dataProps ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const service = useWidgetService();

  const { productCode, lead, recalculationTariff, productData, stepData, policyStepNames } = dataProps;

  const recalculationConfig: RecalculationTariff = {
    ...recalculationTariff,
    isCheckedUnderwriting: true,
  };

  const actions = useCoreActions();
  const currentStep = stepData && stepData.steps.find(
    ( stepItem ) => !stepItem.visibleCriteria || evalFunction( lead || [], stepItem.visibleCriteria ),
  );

  const goToHomePage = React.useCallback ( async (): Promise<void> => {
    if ( actions.goToHomePage ) {
      await service.resetLead();
      actions.goToHomePage( stepData.homePageUrl );
    }
  }, [ actions, service, stepData.homePageUrl ] );

  return (
    <div id={ stepData.nameStep } className="system-step">
      <Row className="mt-3 mx-0">
        <Col md={ 12 } className="px-0" id={ `alert-${stepData.nameStep}` }>
          <AppAlertUI />
        </Col>
        <Col md={ 12 } className="px-0">
          <Fragment>
            { currentStep && (
              <Fragment>
                { stepData.title && (
                  <Row>
                    <Col className="p-0" md={ 12 }
                      dangerouslySetInnerHTML={ { __html: `${stepData.title}` } }
                    >
                    </Col>
                  </Row>
                ) }
                { stepData.subTitle && (
                  <Row>
                    <Col className="p-0" md={ 12 }
                      dangerouslySetInnerHTML={ { __html: `${stepData.subTitle}` } }
                    >
                    </Col>
                  </Row>
                ) }
                { currentStep.title && (
                  <Row>
                    <Col className="p-0" md={ 12 }
                      dangerouslySetInnerHTML={ { __html: `${currentStep.title}` } }
                    >
                    </Col>
                  </Row>
                ) }
                { currentStep.subTitle && (
                  <Row>
                    <Col className="p-0" md={ 12 }
                      dangerouslySetInnerHTML={ { __html: `${currentStep.subTitle}` } }
                    >
                    </Col>
                  </Row>
                ) }
                <CoreRenderContainer
                  storeLeadData={ lead }
                  leadData={ lead }
                  productData={ productData }
                  productCode={ productCode }
                  stepData={ stepData }
                  currentStep={ currentStep }
                  recalculationTariff={ recalculationConfig }
                  containerName={ '' }
                  policyStepNames={ policyStepNames }
                  isIgnoreOnRecalculation={ true }
                  isDisabledForm={ false }
                />
              </Fragment>
            ) }
          </Fragment>
          <Row className="bf-footer-btn-panel justify-content-center align-items-center mt-4 mx-0">
            <Button
              id={ `${stepData.nextStep}_next` }
              type="button"
              variant="primary"
              onClick={ ( e ) => goToHomePage() }
              className="col-sm-12 mr-0"
            >
              { stepData.nextButtonText ? stepData.nextButtonText : t( 'bookingFunnel.nextBtn' ) }
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
