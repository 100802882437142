import React from 'react';
import dayjs from 'dayjs';
import LocaleDe from 'date-fns/locale/de';
import DatePicker, { registerLocale } from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { useTranslation } from 'react-i18next';
import { Separators } from 'Services/widgets/enums';
import { isDateString } from 'class-validator';

export interface DatePickerProps {
  currentValue: string;
  fromDate?: string;
  toDate?: string;
  separator: Separators;
  dateFormat?: string;
  placeholderText?: string;
  isReadOnly?: boolean;
  showMonthYearPicker: boolean;
  showFullMonthYearPicker: boolean;
  onChangeDate: ( dateValue ) => void;
  onBlurDate?: ( dateValue ) => void;
  isDisabled?: boolean;
  isAgeType?: boolean;
}

export const DatePickerComponent: React.FC<DatePickerProps> = ( {
  currentValue,
  fromDate,
  toDate,
  separator,
  dateFormat = 'dd/MM/yyyy',
  isReadOnly,
  placeholderText = 'TT/MM/JJJJ',
  showMonthYearPicker,
  showFullMonthYearPicker,
  onChangeDate,
  onBlurDate,
  isDisabled,
  isAgeType,
} ) => {
  const { i18n } = useTranslation( [ 'widgets', 'base' ] );
  const lng = i18n.language;
  const maskDate = [ /[0-3]/, /[0-9]/, `${separator}`, /[0-1]/, /[0-9]/, `${separator}`, /\d/, /\d/, /\d/, /\d/ ];
  const minYear = fromDate ? dayjs( fromDate ).format( 'YYYY' ) : '';
  const maxYear = toDate ? dayjs( toDate ).format( 'YYYY' ) : '';
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe( `dd${separator}mm${separator}yyyy`, {
    minYear,
    maxYear,
  } );

  const [ inputValue, setInputValue ] = React.useState<Date | string | null>(
    currentValue ? new Date( currentValue ) : '' );

  if ( lng === 'de' ) {
    registerLocale( lng, LocaleDe );
  }

  // Pipe function to customize the masked value
  const datePipe = ( conformedValue, config ) => {
    if ( conformedValue ) {
      const corrected = autoCorrectedDatePipe( conformedValue, config );
      if ( corrected === false ) return false;
    }

    const validateDate = ( dateStr ) => {
      if ( !dateStr || dateStr.length < 10 ) {
        if ( dateStr.length >= 5 && dateStr.includes( separator ) ) {
          const [ , monthPart ] = dateStr.split( separator );
          if ( monthPart ) {
            const month = Number( monthPart );
            if ( month > 12 || month < 0 ) return false;
          }
        }
        return true;
      }

      const [ day, month ] = dateStr.split( separator ).map( Number );
      if ( month < 1 || month > 12 ) return false;
      if ( day < 1 || day > 31 ) return false;
      return true;
    };

    if ( !validateDate( conformedValue ) ) return false;

    return conformedValue;
  };

  const formatDateValue = React.useCallback( ( inputDate: string ): string => {
    if ( inputDate ) {
      const resDate = inputDate.replace( Separators.Underscore, '' );

      if ( resDate.length === 10 ) {
        return resDate.split( separator ).reverse().join( Separators.Dash );
      }
    }

    return '';
  }, [ separator ] );

  const handleChangeDate = React.useCallback( ( selectedDate: string ): void => {
    onChangeDate( selectedDate );
    setInputValue( selectedDate );
  }, [ onChangeDate ] );

  const handleChangeInput = React.useCallback( ( inputEvent ): void => {
    const dateVal = inputEvent.target.value;

    if ( !dateVal ) {
      onChangeDate( dateVal );
      setInputValue( null );

      return;
    }

    const formatDate = formatDateValue( dateVal );
    if ( formatDate ) {
      onChangeDate( formatDate );

      setInputValue( new Date( formatDate ) );
    }
  }, [ formatDateValue, onChangeDate ] );

  const handleBlur = React.useCallback( ( inputEvent ): void => {
    if ( onBlurDate ) {
      const dateVal = inputEvent.target.value;
      onBlurDate( dateVal );
    }
  }, [ onBlurDate ] );

  const getAge = React.useCallback( ( dateValue: string ): string => {
    if ( dateValue ) {
      const selectedDate = dateValue.split( Separators.Slash ).reverse().join( Separators.Dash );
      const today = dayjs();
      const dateField = dayjs( selectedDate );
      const age = today.diff( dateField, 'years', false ) as unknown as string;

      return age;
    }

    return '';
  }, [] );

  // eslint-disable-next-line react/display-name
  const ReadOnlyInput = React.forwardRef( ( inputProps: Record<string, any>, ref: React.Ref<HTMLInputElement> ) => (
    <div
      className="input-group"
      onClick={ isAgeType ? inputProps.onClick : undefined }
      style={ { cursor: isAgeType ? 'pointer' : 'default' } }
    >
      <input
        ref={ ref }
        className="form-control date-custom-input"
        onClick={ inputProps.onClick }
        defaultValue={ isAgeType ? getAge( inputProps.value ) : inputProps.value }
        type="text"
        readOnly={ isReadOnly }
        placeholder={ placeholderText }
        disabled={ isDisabled || isAgeType }
      />
      <div onClick={ inputProps.onClick } className="ws-icon-select">
        <span className="input-group-text">
          <i className="material-icons material-icons-outlined calendar-icon">calendar_today</i>
        </span>
      </div>
    </div>
  ) );

  // eslint-disable-next-line react/display-name
  const CustomInputMask = React.forwardRef( ( inputProps: Record<string, any>, ref: React.Ref<HTMLInputElement> ) => (
    <div className="input-group">
      <MaskedInput
        ref={ ref }
        className="form-control date-custom-input"
        type="text"
        value={ inputProps.value || '' }
        pipe={ datePipe }
        mask={ maskDate }
        keepCharPositions= { true }
        guide = { true }
        placeholder={ placeholderText }
        onChange={ handleChangeInput }
        onBlur={ handleBlur }
        disabled={ isDisabled }
      />
      <div onClick={ inputProps.onClick } className="ws-icon-select">
        <span className="input-group-text">
          <i className="material-icons material-icons-outlined calendar-icon">calendar_today</i>
        </span>
      </div>
    </div>
  ) );

  React.useEffect( () => {
    if ( currentValue && isDateString( currentValue ) ) {
      setInputValue( new Date( currentValue ) );
    }
  }, [ currentValue ] );

  return (
    <DatePicker
      customInput={ isReadOnly || isDisabled || isAgeType ? <ReadOnlyInput /> : <CustomInputMask /> }
      selected={ inputValue }
      onChange={ handleChangeDate }
      minDate={ fromDate ? new Date( fromDate ) : '' }
      maxDate={ toDate ? new Date( toDate ) : '' }
      dateFormat={ dateFormat }
      showMonthYearPicker={ showMonthYearPicker }
      showFullMonthYearPicker={ showFullMonthYearPicker }
      showYearDropdown={ true }
      placeholderText={ placeholderText }
      locale={ lng }
      disabled={ isDisabled }
      yearDropdownItemNumber={ 200 }
      scrollableYearDropdown={ true }
    />
  );
};
