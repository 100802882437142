import React from 'react';
import dayjs from 'dayjs';
import LocaleDe from 'date-fns/locale/de';
import DatePicker, { registerLocale } from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { useTranslation } from 'react-i18next';
import { Separators } from 'Services/widgets/enums';
import useScreenType from 'react-screentype-hook';


export interface DateRangeProps {
  currentValue: string;
  fromDate?: string;
  toDate?: string;
  separator: Separators;
  dateFormat?: string;
  placeholderText?: string;
  isReadOnly?: boolean;
  showMonthYearPicker: boolean;
  showFullMonthYearPicker: boolean;
  onChangeDate: ( dateValue ) => void;
  onBlurDate?: ( dateValue ) => void;
  isDisabled?: boolean;
}

export const DateRangeComponent: React.FC<DateRangeProps> = ( {
  currentValue,
  fromDate,
  toDate,
  separator,
  dateFormat = 'dd/MM/yyyy',
  isReadOnly,
  placeholderText = 'TT/MM/JJJJ - TT/MM/JJJJ',
  showMonthYearPicker,
  showFullMonthYearPicker,
  onChangeDate,
  onBlurDate,
  isDisabled,
} ) => {
  const { i18n } = useTranslation( [ 'widgets', 'base' ] );
  const screenType = useScreenType();
  const lng = i18n.language;
  const maskDate = [
    /[0-3]/, /[0-9]/, `${separator}`, /[0-1]/, /[0-9]/, `${separator}`, /\d/, /\d/, /\d/, /\d/,
    ' ', '-', ' ',
    /[0-3]/, /[0-9]/, `${separator}`, /[0-1]/, /[0-9]/, `${separator}`, /\d/, /\d/, /\d/, /\d/,
  ];

  const minYear = fromDate ? dayjs( fromDate ).format( 'YYYY' ) : '';
  const maxYear = toDate ? dayjs( toDate ).format( 'YYYY' ) : '';
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe( `dd${separator}mm${separator}yyyy`, {
    minYear,
    maxYear,
  } );

  const [ startDateStore, endDateStore ] = currentValue.split( ' - ' );

  const defaultStartDate = startDateStore ? new Date( startDateStore ) : '';
  const defaultEndDate = endDateStore ? new Date( endDateStore ) : '';

  const [ startDate, setStartDate ] = React.useState( defaultStartDate );
  const [ endDate, setEndDate ] = React.useState( defaultEndDate );

  if ( lng === 'de' ) {
    registerLocale( lng, LocaleDe );
  }

  // Pipe function to customize the masked value
  const dateRangePipe = ( conformedValue, config ) => {
    const [ startDateInput, endDateInput ] = conformedValue.split( ' - ' ).map( ( part ) => part.trim() );

    if ( startDateInput ) {
      const correctedStart = autoCorrectedDatePipe( startDateInput, config );
      if ( correctedStart === false ) return false;
    }

    if ( endDateInput ) {
      const correctedEnd = autoCorrectedDatePipe( endDateInput, config );
      if ( correctedEnd === false ) return false;
    }

    const validateDate = ( dateStr ) => {
      if ( !dateStr || dateStr.length < 10 ) {
        if ( dateStr.length >= 5 && dateStr.includes( separator ) ) {
          const [ , monthPart ] = dateStr.split( separator );
          if ( monthPart ) {
            const month = Number( monthPart );
            if ( month > 12 || month < 0 ) return false;
          }
        }
        return true;
      }

      const [ day, month ] = dateStr.split( separator ).map( Number );
      if ( month < 1 || month > 12 ) return false;
      if ( day < 1 || day > 31 ) return false;
      return true;
    };

    if ( !validateDate( startDateInput ) ) return false;
    if ( endDateInput && !validateDate( endDateInput ) ) return false;

    return conformedValue;
  };

  const formatDateValue = React.useCallback( ( inputDate: string ): string => {
    if ( inputDate ) {
      const separatorRange = ' - ';
      const [ startInputDate, endInputDate ] = inputDate.split( separatorRange );
      const resStartDate = startInputDate.replace( Separators.Underscore, '' );
      const resEndDate = endInputDate.replace( Separators.Underscore, '' );

      if ( resStartDate.length === 10 && resEndDate.length === 10 ) {
        const formatStartDate = resStartDate.split( separator ).reverse().join( Separators.Dash );
        const formatEndDate = resEndDate.split( separator ).reverse().join( Separators.Dash );

        return `${formatStartDate}${separatorRange}${formatEndDate}`;
      }
    }

    return '';
  }, [ separator ] );

  const handleChangeDate = React.useCallback( ( [ newStartDate, newEndDate ] ): void => {
    onChangeDate( `${newStartDate} - ${newEndDate}` );

    setStartDate( newStartDate );
    setEndDate( newEndDate );
  }, [ onChangeDate ] );

  const handleChangeInput = React.useCallback( ( inputEvent ): void => {
    const dateVal = inputEvent.target.value;

    if ( !dateVal ) {
      onChangeDate( dateVal );

      return;
    }

    const formatDate = formatDateValue( dateVal );
    if ( formatDate ) {
      onChangeDate( formatDate );

      const separatorRange = ' - ';
      const [ startInputDate, endInputDate ] = formatDate.split( separatorRange );

      if ( startInputDate && dayjs( startInputDate ).isValid() ) {
        setStartDate( new Date( startInputDate ) );
      }

      if ( endInputDate && dayjs( endInputDate ).isValid() ) {
        setEndDate( new Date( endInputDate ) );
      }
    }
  }, [ formatDateValue, onChangeDate ] );

  const handleBlur = React.useCallback( ( inputEvent ): void => {
    if ( onBlurDate ) {
      const dateVal = inputEvent.target.value;
      onBlurDate( dateVal );
    }
  }, [ onBlurDate ] );

  // eslint-disable-next-line react/display-name
  const ReadOnlyInput = React.forwardRef( ( inputProps: Record<string, any>, ref: React.Ref<HTMLInputElement> ) => (
    <div className="input-group">
      <input
        ref={ ref }
        className="form-control date-custom-input"
        onClick={ inputProps.onClick }
        defaultValue={ inputProps.value }
        type="text"
        readOnly={ isReadOnly }
        placeholder={ placeholderText }
        disabled={ isDisabled }
      />
      <div onClick={ inputProps.onClick } className="ws-icon-select">
        <span className="input-group-text">
          <i className="material-icons material-icons-outlined calendar-icon">calendar_today</i>
        </span>
      </div>
    </div>
  ) );

  // eslint-disable-next-line react/display-name
  const CustomInputMask = React.forwardRef( ( inputProps: Record<string, any>, ref: React.Ref<HTMLInputElement> ) => (
    <div className="input-group">
      <MaskedInput
        ref={ ref }
        className="form-control date-custom-input"
        type="text"
        value={ inputProps.value }
        pipe={ dateRangePipe }
        mask={ maskDate }
        keepCharPositions= { true }
        guide = { true }
        placeholder={ placeholderText }
        onChange={ handleChangeInput }
        onBlur={ handleBlur }
        disabled={ isDisabled }
      />
      <div onClick={ inputProps.onClick } className="ws-icon-select">
        <span className="input-group-text">
          <i className="material-icons material-icons-outlined calendar-icon">calendar_today</i>
        </span>
      </div>
    </div>
  ) );

  return (
    <DatePicker
      selectsRange
      monthsShown={ screenType.isMobile ? 1 : 2 }
      customInput={ isReadOnly || isDisabled ? <ReadOnlyInput /> : <CustomInputMask /> }
      startDate={ startDate }
      endDate={ endDate }
      onChange={ handleChangeDate }
      minDate={ fromDate ? new Date( fromDate ) : '' }
      maxDate={ toDate ? new Date( toDate ) : '' }
      dateFormat={ dateFormat }
      showMonthYearPicker={ showMonthYearPicker }
      showFullMonthYearPicker={ showFullMonthYearPicker }
      showYearDropdown={ false }
      placeholderText={ placeholderText }
      locale={ lng }
      disabled={ isDisabled }
    />
  );
};
