import React, { Fragment } from 'react';
import { RecalculationTariff } from '../../DynamicCore';
import { Row, Col } from 'react-bootstrap';
import { PaymentProvider, PaymentType } from 'Services/widgets/enums';
import { AppAlertUI } from 'App/components/utils/alerts/AppAlertService';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { ILeadData } from 'Services/widgets/interfaces';
import { FormProvider, useForm } from 'react-hook-form';
import { StepInfoItem } from '../../interfaces';
import { LoadingSpinner } from 'App/components/utils/LoadingSpinner';
import { getPaymentProvider } from '../../core-hooks';
import { paymentDataCoreKey } from '../../CoreBookingFunnel';

const EmilSystemComponent = React.lazy( () =>
  import( './payment-systems/EmilSystem' )
    .then( ( { EmilSystem } ) => ( { default: EmilSystem } ) ),
);

const StripeSystemComponent = React.lazy( () =>
  import( './payment-systems/StripeSystem' )
    .then( ( { StripeSystem } ) => ( { default: StripeSystem } ) ),
);

const StripeSystemV2Component = React.lazy( () =>
  import( './payment-systems/StripeSystemV2' )
    .then( ( { StripeSystemV2 } ) => ( { default: StripeSystemV2 } ) ),
);

const AdyenSystemComponent = React.lazy( () =>
  import( './payment-systems/AdyenSystem' )
    .then( ( { AdyenSystem } ) => ( { default: AdyenSystem } ) ),
);

export interface PaymentCoreStepItem {
  paymentMethod: string;
  accountFirstName?: string;
  accountLastName?: string;
  accountEmail?: string;
  paymentDataValid?: boolean;
  accountIban?: string;
}

export interface PaymentCoreStepProps {
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  productCode: string;
  stepData: StepInfoItem;
  recalculationTariff: RecalculationTariff;
  policyStepNames: string[];
}

export const PaymentCoreStep: React.FC<PaymentCoreStepProps> = (
  { lead, productData, productCode, recalculationTariff, stepData, policyStepNames },
) => {
  const formContext = useForm<PaymentCoreStepItem>( {
    mode: 'onChange',
  } );

  let paymentProvider = PaymentProvider.Stripe;
  const currentStep = stepData && stepData.steps.find( ( subStep ) => subStep.name === paymentDataCoreKey );
  const foundPaymentProvider = getPaymentProvider( currentStep?.containers! );

  if ( foundPaymentProvider ) {
    paymentProvider = foundPaymentProvider;
  }

  const { leadData } = productData;
  const loading = <div className="bf-loading dynamic-loading col-auto d-flex py-4"><LoadingSpinner /></div>;

  return (
    <div id="payment-data-step">
      <Row className="mt-3 mx-0">
        <Col md={ 12 } className="mt-3 p-0" id="alert-payment-page">
          <AppAlertUI />
        </Col>
        { stepData && (
          <Fragment>
            { stepData.title && (
              <Col className="p-0" md={ 12 }
                dangerouslySetInnerHTML={ { __html: `${stepData.title}` } }
              >
              </Col>
            ) }
            { stepData.subTitle && (
              <Col className="p-0" md={ 12 }
                dangerouslySetInnerHTML={ { __html: `${stepData.subTitle}` } }
              >
              </Col>
            ) }
          </Fragment>
        ) }
        <Col md={ 12 }>
          { paymentProvider === PaymentProvider.Emil && (
            <React.Suspense fallback={ loading }>
              <EmilSystemComponent
                lead={ lead }
                productData={ productData }
                productCode={ productCode }
                stepData={ stepData }
                recalculationTariff={ recalculationTariff }
                policyStepNames={ policyStepNames }
              />
            </React.Suspense>
          ) }
          { paymentProvider === PaymentProvider.Stripe && leadData.psp === PaymentType.Stripe && (
            <React.Suspense fallback={ loading }>
              <FormProvider { ...formContext }>
                <StripeSystemComponent
                  formContext={ formContext }
                  lead={ lead }
                  productData={ productData }
                  productCode={ productCode }
                  stepData={ stepData }
                  recalculationTariff={ recalculationTariff }
                  policyStepNames={ policyStepNames }
                />
              </FormProvider>
            </React.Suspense>
          ) }
          { paymentProvider === PaymentProvider.StripeV2 && leadData.psp === PaymentType.Stripe && (
            <React.Suspense fallback={ loading }>
              <FormProvider { ...formContext }>
                <StripeSystemV2Component
                  formContext={ formContext }
                  lead={ lead }
                  productData={ productData }
                  productCode={ productCode }
                  stepData={ stepData }
                  recalculationTariff={ recalculationTariff }
                  policyStepNames={ policyStepNames }
                />
              </FormProvider>
            </React.Suspense>
          ) }
          { paymentProvider === PaymentProvider.Adyen && leadData.psp === PaymentType.Adyen && (
            <React.Suspense fallback={ loading }>
              <FormProvider { ...formContext }>
                <AdyenSystemComponent
                  formContext={ formContext }
                  lead={ lead }
                  productData={ productData }
                  productCode={ productCode }
                  stepData={ stepData }
                  recalculationTariff={ recalculationTariff }
                  policyStepNames={ policyStepNames }
                />
              </FormProvider>
            </React.Suspense>
          ) }
        </Col>
      </Row>
    </div>
  );
};
