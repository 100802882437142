import React, { Fragment } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { Button, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useWidgetService } from 'Services/widget';
import { FileItem, ContainerFieldItem, FileUploadSettings, ILeadData } from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from '../../booking-funnel/BookingFunnel';
import { useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { Line } from 'rc-progress';
import { isCheckedByField } from '../core-hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { htmlTagsRegex } from '../core-booking-funnel/core-ui-components/extra/CoreAddressAutoComplete';

const ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/jpg',
  'text/csv',
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip',
  '.zip',
];

const defaultMaxFiles = 30;

export interface FileUploadProps {
  stepItem: ContainerFieldItem;
  productData: IDataFactorsAndVariables;
  stepKey: string;
  settings: FileUploadSettings;
  isDisabled?: boolean;
}

export const fileUploadKey = 'fileUpload';

export const FileUpload: React.FC<FileUploadProps> = (
  { stepItem, productData, stepKey, settings, isDisabled },
) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const service = useWidgetService();
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const [ errorMessage, setErrorMessage ] = React.useState<string | null>( null );
  const inputFile = React.useRef<HTMLInputElement>( null );
  const [ isLoading, setIsLoading ] = React.useState( false );
  const [ isFileLoading, setIsFileLoading ] = React.useState( false );
  const [ fileList, setFileList ] = React.useState<FileItem[]>( [] );
  const [ uploadProgress, setUploadProgress ] = React.useState<number>( 0 );
  const leadCode = productData.leadData.leadCode;
  const storeKey = `${fileUploadKey}_${leadCode}`;
  const nameField = stepItem.name || storeKey;
  const filesItemKey = `${stepKey}_${nameField}`;
  const maxFiles = settings.maxFiles || defaultMaxFiles;

  const { control, errors, setValue } = useFormContext();

  const extractContentTypeFromFile = ( file: Blob ): string => {
    const splittedFileType = file.type.split( '/' );
    const lastPartOfMime = splittedFileType[splittedFileType.length - 1];

    if( lastPartOfMime === 'jpeg' ) {
      return 'jpg';
    }

    return lastPartOfMime;
  };

  const getFileName = ( file: File ): string => {
    const splittedFileName = file.name.split( '.' );

    if( splittedFileName && splittedFileName[0] ) {
      return splittedFileName[0];
    }

    return file.name;
  };

  const handleFileDelete = React.useCallback( async ( fileCode: string ): Promise<void> => {
    try {
      await service.deleteUploadedDocumentByLeadCode( fileCode, leadCode );
    } catch ( e ) {
      setErrorMessage( t( 'base:forms.messages.error' ) );
    }
  }, [ leadCode, service, t ] );

  const handleOnClickFileDelete = React.useCallback( async ( fileCode: string ): Promise<void> => {
    try {
      setIsLoading( true );

      const leadData: ILeadData = await service.getLead();
      const storeData = leadData[storeKey];

      if ( storeData && fileCode ) {
        const allFileList: FileItem[] = storeData[filesItemKey];

        if ( allFileList ) {
          const filteredFiles = allFileList.filter( ( f ) => f.code !== fileCode );
          const updatedData = {
            ...storeData,
            [filesItemKey]: [ ...filteredFiles ],
          };

          await service.deleteUploadedDocumentByLeadCode( fileCode, leadCode );
          await service.savedInfo( storeKey, updatedData );

          setFileList( [ ...filteredFiles ] );
          setValue( nameField, filteredFiles.length > 0 ? filteredFiles.length : '', { shouldValidate: true } );
        }
      }

      setIsLoading( false );
    } catch ( e ) {
      setErrorMessage( t( 'base:forms.messages.error' ) );
      setIsLoading( false );
    }
  }, [ filesItemKey, leadCode, nameField, service, setValue, storeKey, t ] );

  const uploadFiles = React.useCallback( async ( acceptedFiles, fileRejections: FileRejection[] ) => {
    if ( fileRejections.length ) {
      return;
    }

    if ( acceptedFiles.length > maxFiles ) {
      setErrorMessage( t( 'fileUpload.errorMaxFiles', { 'maxFileNumber': maxFiles } ) );

      return;
    }

    if ( acceptedFiles.length ) {
      setErrorMessage( null );
    }

    const leadData: ILeadData = await service.getLead();
    const storeData = leadData[storeKey];

    if ( fileList.length ) {
      const previousFiles = storeData[filesItemKey] as FileItem[];
      if ( previousFiles ) {
        previousFiles.forEach( ( f ) => {
          handleFileDelete( f.code );
        } );
      }

      setFileList( [] );
      setValue( nameField, '', { shouldValidate: true } );

      await service.savedInfo( storeKey, [] );
    }

    try {
      setIsFileLoading( true );
      const newFiles: FileItem[] = [];

      await Promise.all(
        acceptedFiles.map( async ( file ) => {
          const response = await service.getPreSignedRequestUrl( {
            description: getFileName( file ),
            contentType: extractContentTypeFromFile( file ),
            isoContentType: file.type,
            entityType: 'bf-document',
            requester: 'bookingFunnel',
            templateSlug: 'document-upload-bf',
            filename: file.name,
            leadCode: leadCode,
          } );

          const {
            fields: {
              filename,
              code,
              description,
              contentType,
            },
          } = response;

          newFiles.push( {
            contentType,
            code,
            description,
            filename,
            createdAt: new Date().toISOString(),
          } );

          return service.uploadDocumentUsingPreSignedUrl(
            response,
            file,
            ( progressEvent ) => {
              setUploadProgress( Math.round( progressEvent.loaded * 100 / progressEvent.total ) );
            },
          );
        } ),
      );

      if ( storeData ) {
        const newData = {
          ...storeData,
          [filesItemKey]: [ ...newFiles ],
        };

        await service.savedInfo( storeKey, newData );
      }

      setFileList( newFiles );

      setValue( nameField, newFiles.length > 0 ? newFiles.length : '', { shouldValidate: true } );

      setIsFileLoading( false );
      setUploadProgress( 0 );
    } catch ( error ) {
      setErrorMessage( t( 'base:forms.messages.error' ) );
      setIsFileLoading( false );
      setUploadProgress( 0 );
    }
  }, [ fileList.length, filesItemKey, handleFileDelete,
    leadCode, maxFiles, nameField, service, setValue, storeKey, t ] );

  const handleOpenFileInput = async ( event ) => {
    const files: File[] = [];

    for( let i = 0; i < event.target.files.length; i++ ) {
      files.push( event.target.files[i] );
    }

    await uploadFiles( files, [] );
  };

  React.useEffect( () => {
    let isMounted = true;

    const fetchData = async () => {
      const leadData: ILeadData = await service.getLead();
      const storeData = leadData[storeKey] || null;

      if ( storeData === null ) {
        await service.savedInfo( storeKey, {} );

        return;
      }

      const fileData = storeData[filesItemKey];
      if ( fileData && isMounted ) {
        setFileList( fileData );
        setValue( nameField, fileData.length > 0 ? fileData.length : '', { shouldValidate: true } );
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [ filesItemKey, leadCode, nameField, service, setValue, stepKey, storeKey ] );

  return (
    <Fragment>
      { stepItem['title'] && (
        <Col md={ 12 } className="px-0">
          <div
            className="file-upload-title"
            dangerouslySetInnerHTML={ { __html: `${stepItem['title']}` } }
          >
          </div>
        </Col>
      ) }
      <Dropzone
        onDrop={ uploadFiles }
        accept={ ACCEPTED_FILE_TYPES }
        multiple={ settings.isMulti }
        maxFiles={ maxFiles }
      >
        { ( { getRootProps, isDragActive, isDragAccept, isDragReject } ) => {
          return (
            <div
              className={
                `dropzone ${isDragReject || errors[nameField] ? 'reject' : ''} ${isDragAccept ? 'accept' : ''}`
              }
              { ...getRootProps() }
            >
              { isFileLoading ? (
                <div className="loading-container" style={ {
                  opacity: '0.5',
                  display: 'flex',
                  justifyContent: 'center',
                } }
                >
                  <Line
                    percent={ uploadProgress }
                    strokeWidth={ 4 }
                  />
                </div>
              ) : (
                <div className="dz-default dz-message">
                  <span>
                    { isDragActive && isDragAccept
                      ? t( 'fileUpload.acceptDropZone' )
                      : t( 'fileUpload.textDropZoneFiles' ) }
                  </span>
                  <Button
                    onClick={ () => {
                      if ( inputFile.current ) {
                        inputFile.current.click();
                      }
                    } }
                  >
                    { t( 'fileUpload.btnChooseFile' ) }
                    <input
                      type="file"
                      disabled={ isFileLoading }
                      id={ nameField }
                      ref={ inputFile }
                      className="d-none"
                      onInput={ handleOpenFileInput }
                      accept={ ACCEPTED_FILE_TYPES.join( ',' ) }
                      multiple={ settings.isMulti }
                    />
                  </Button>
                  <Controller
                    name={ nameField }
                    control={ control }
                    rules={ { required: isDisabled ? false : isCheckedByField( 'isRequired', stepItem! ) } }
                    defaultValue={ fileList.length > 0 ? fileList.length : '' }
                    render={ ( props ) => (
                      <Form.Control
                        { ...props }
                        type="hidden"
                        isInvalid={ errors[props.name] !== undefined }
                      />
                    ) }
                  />
                  { errors[nameField] && (
                    <span className="text-danger info mt-2 mb-0">
                      { t( 'base:forms.messages.fieldRequired', {
                        fieldLabel: stepItem['title'] ? stepItem['title'].replace( htmlTagsRegex, '' ) : '',
                      } ) }
                    </span>
                  ) }
                  { isDragReject && (
                    <Fragment>
                      <span className="text-danger info mt-2 mb-0">
                        { settings.errorMessage || t( 'fileUpload.onRejectFileExtension' ) }
                      </span>
                      <span className="text-danger info mt-0 f-14">
                        ({ t( 'fileUpload.hintMessageDropZone', { 'maxFileNumber': maxFiles } ) })
                      </span>
                    </Fragment>
                  ) }
                </div>
              ) }
            </div>
          );
        } }
      </Dropzone>
      { settings.hintMessage &&
        <Col md={ 12 } className="px-0">
          <p className="mt-1 dropdown-hint">{ settings.hintMessage }</p>
        </Col>
      }
      { errorMessage && (
        <Col md={ 12 } className="px-0">
          <p className="text-danger mt-2">{ errorMessage }</p>
        </Col>
      ) }
      { fileList && (
        <Col md={ 12 } className="px-0">
          { isLoading && loadingOnFullContainer }
          <ul className="file-list my-3 px-0">
            { fileList.map( ( item, itemIdx ) => (
              <li className="my-2" key={ itemIdx }>
                <div className="d-flex justify-content-space-between">
                  <span className="d-inline-flex align-items-center">{ item.filename }</span>
                  <Button
                    type="button"
                    variant="link"
                    title={ t( 'fileUpload.deleteBtn' ) }
                    className="btn-delete m-0 p-0 d-inline-flex align-items-center text-c-black"
                    onClick={ () => handleOnClickFileDelete( item.code ) }
                  >
                    <i className="material-icons material-icons-outlined f-18 mr-0">delete</i>
                  </Button>
                </div>
              </li>
            ) ) }
          </ul>
        </Col>
      ) }
    </Fragment>
  );
};
