import React, { Fragment } from 'react';
import * as qs from 'query-string';
import jsonpath from 'jsonpath';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ILeadData,
  ITariffDataStep,
  CompleteEmailVerification,
  StepItem,
} from 'Services/widgets/interfaces';
import { AppAlertUI, useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useWidgetService } from 'Services/widget';
import { isEmpty } from 'lodash';
import { RecalculationTariff, useCoreActions } from '../../DynamicCore';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { TooltipPopupCore } from '../../ui-components/TooltipPopupCore';
import {
  formatFormData,
  generateRules,
  getInfoMessage,
  systemFields,
  isIgnoreOnRecalculationFieldCore,
  preparePayloadForLead,
  generateCrossPolicyFields,
  keyMultiInsuredObjects,
  generatePolicyObject,
  generateMultiObjects,
  generateSteps,
  generateSavedData,
  limitRequest,
  keyCustomFields,
} from '../../core-hooks';
import { evalFunction } from '../../../booking-funnel/functions';
import { getFieldDiff } from '../../../booking-funnel/booking-funnel-hooks';
import { StepInfoItem } from '../../interfaces';
import { JSONComponentType } from '../../../booking-funnel/enum';
import { useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { CoreCirclesNavigationMobile } from '../core-ui-components/CoreCirclesNavigationMobile';
import { CoreSavedDataPopup } from '../core-ui-components/CoreSavedDataPopup';
import { useAppLogger } from 'Services/logger';
import { CoreRenderContainer } from '../core-ui-components/CoreRenderContainer';
import { personalDataCoreKey } from './personal-sub-steps/RegistrationCoreStep';
import { htmlTagsRegex } from '../core-ui-components/extra/CoreAddressAutoComplete';
import { CoreSaveLeadNav } from '../core-ui-components/CoreSaveLeadNav';

export interface PolicyCoreStepProps {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  stepData: StepInfoItem;
  recalculationTariff: RecalculationTariff;
  policyStepNames: string[];
  isDisabledForm: boolean;
}

export const PolicyCoreStep: React.FC<PolicyCoreStepProps> = ( dataProps ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { productCode, lead, productData, recalculationTariff, stepData, policyStepNames, isDisabledForm } = dataProps;
  const { configuration } = productData;
  const keyPolicyStep = stepData.nameStep;

  const actions = useCoreActions();
  const service = useWidgetService();
  const logger = useAppLogger();
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const previousIsReloadData = React.useRef<boolean>( true );
  const previousResultData = React.useRef<ITariffDataStep>( {} );
  const isIgnoreOnRecalculation = React.useRef<boolean>( false );
  const previousLimitRequest = React.useRef<number>( 0 );
  const { showAlert } = useAppAlertService();
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const [ messageError, setMessageError ] = React.useState<string>( '' );
  const [ isShowErrorPopup, setShowErrorPopup ] = React.useState<boolean>( false );
  const [ leadData, setLeadData ] = React.useState<ILeadData>( lead );
  const [ isRejected, setIsRejected ] = React.useState<boolean>( false );

  const [ isLoading, setLoading ] = React.useState<boolean>( false );
  const [ isValidEmail, setValidEmail ] = React.useState<boolean>( true );
  const [ isUpdatedLead, setUpdatedLead ] = React.useState<boolean>( false );
  const [ isLoadingPremiumData, setLoadingPremiumData ] = React.useState<boolean>( false );
  const [ itemErrorId, setItemErrorId ] = React.useState<string>( '' );
  const [ itemErrorIds, setItemErrorIds ] = React.useState<string[]>( lead[`${keyPolicyStep}_error_ids`] || [] );
  const previousIsLoadingPremiumData = React.useRef<boolean>( false );

  const currentStep = stepData && stepData.steps.find(
    ( stepItem ) => !stepItem.visibleCriteria || evalFunction( leadData || [], stepItem.visibleCriteria ),
  );

  const { saveForLaterButton } = stepData || {};

  const formContext = useForm( {
    mode: 'onChange',
    shouldUnregister: true,
  } );

  const { formState, errors, getValues, trigger } = formContext;

  const getContainerParam = React.useCallback( ( ): string | undefined => {
    const parsedUrl = qs.parse( window.location.href, { decode: false } );
    return parsedUrl['containerGroup'];
  }, [] );

  const containerName = getContainerParam();

  const isCheckedProduct = React.useCallback( ( ): boolean => {
    return formState.isValid;
  }, [ formState.isValid ] );

  const rejectionPopup = React.useCallback(
    async ( isClickNextBtn: boolean = false, isSkipSaveForLaterValidation: boolean = false,
    ): Promise<void> => {
      if ( isDisabledForm ) {
        actions.setValidForm( true );
        return;
      }
      actions.setValidForm( isEmpty( formState.errors ) );

      if ( stepData && stepData.steps ) {
        const leadStore: ILeadData = await service.getLead( 0 );
        const rules = generateRules( stepData.steps, currentStep?.name, isSkipSaveForLaterValidation );

        const { enabled, ruleCriteriaSFL } = stepData.saveForLaterButton || {};
        if ( enabled && ruleCriteriaSFL && isSkipSaveForLaterValidation ) {
          rules.unshift( ruleCriteriaSFL );
        }

        actions.setRejected( false );
        setIsRejected( false );

        if ( rules ) {
          rules.forEach( async ( item ) => {
            const rejectionData = evalFunction( leadStore || [], item );
            if ( rejectionData && rejectionData['rejectionMessage'] ) {
              setIsRejected( true );
              const rejectionMessage = rejectionData['rejectionMessage'];
              if ( !rejectionData['suppressPopup'] || isClickNextBtn ) {
                if ( !isClickNextBtn && item.itemId && itemErrorIds.includes( item.itemId ) ) {
                  actions.setRejected( true );
                  return;
                }

                setItemErrorId( item.itemId! );
                setMessageError( rejectionMessage );
                setShowErrorPopup( true );
              } else {
                const infoMess = rejectionMessage.replace( htmlTagsRegex, '' );
                logger.warn( infoMess );
              }
              actions.setRejected( true );

              return;
            } else {
              const cloneIds = itemErrorIds;

              if ( cloneIds && item.itemId && cloneIds.includes( item.itemId ) ) {
                const errorIds = [ ...cloneIds.filter( ( id ) => id !== item.itemId ) ];
                setItemErrorIds( errorIds );

                await service.savedInfo( `${keyPolicyStep}_error_ids`, errorIds );
              }
            }
          } );
        }
      }
    }, [ actions, currentStep, formState.errors, isDisabledForm,
      itemErrorIds, keyPolicyStep, logger, service, stepData ] );

  const infoPopup = React.useCallback( async ( fieldInfo: string ): Promise<void> => {
    if ( !fieldInfo ) {
      return;
    }

    if ( currentStep && currentStep.containers ) {
      const leadStore: ILeadData = await service.getLead( 0 );
      const infoMessage = getInfoMessage( fieldInfo, currentStep.containers, leadStore );

      if ( infoMessage ) {
        setMessageError( infoMessage );
        setShowErrorPopup( true );
      }

    }
  }, [ currentStep, service ] );

  const updateLeadData = React.useCallback( async (): Promise<void> => {
    const { saveLeadOnComplete } = stepData;

    if ( saveLeadOnComplete && saveLeadOnComplete.enabled && saveLeadOnComplete.endStatus ) {
      try {
        setUpdatedLead( true );

        const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

        if ( savedLeadData ) {
          const { status, policy, account } = savedLeadData;
          const { stepsConfig, insuredObjects } = productData;

          if ( saveLeadOnComplete.endStatus === status && policy && policy.policyObjects && stepsConfig ) {

            const policyObject = generatePolicyObject( policy.policyObjects, insuredObjects );

            if ( !isEmpty( policyObject ) ) {
              const multiInsuredObjects = generateMultiObjects( policy.policyObjects, insuredObjects );
              await service.savedInfo( keyMultiInsuredObjects, multiInsuredObjects );

              let allSteps = stepsConfig.steps;
              const steps = generateSteps( allSteps as StepItem[] );

              const policySteps = steps.filter( ( p ) => p.componentType === JSONComponentType.Policy );

              if ( policySteps && policySteps.length ) {
                policySteps.forEach( async ( item ) => {
                  const policyStepData = generateSavedData( policyObject, item.steps, multiInsuredObjects );

                  await service.savedInfo( item.nameStep, policyStepData );
                } );
              }
            }

            if ( account ) {
              const customFields = account.hasOwnProperty( keyCustomFields ) ? account[keyCustomFields] : {};

              delete account[keyCustomFields];

              const personalData = {
                ...account,
                ...customFields,
              };

              await service.savedInfo( personalDataCoreKey, personalData );
            }

            setUpdatedLead( false );
            actions.goToStep( stepData.nextStep!, true );
          } else {
            setTimeout( async () => {
              if ( previousLimitRequest.current < limitRequest ) {
                previousLimitRequest.current = previousLimitRequest.current + 1;

                await updateLeadData();
              }
              if ( previousLimitRequest.current === limitRequest ) {
                setUpdatedLead( false );
                logger.error(
                  'Update lead: It\'s not possible to go to the next step because the status is not correct. ',
                );

                showAlert( {
                  message: t( 'base:forms.messages.errorSave' ),
                  type: 'danger',
                } );

                actions.goToTopPage();
              }
            }, 1500 );
          }
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          logger.error( e );
        } else {
          throw e;
        }

        actions.goToStep( stepData.nextStep!, true );
      }
    } else {
      setUpdatedLead( false );
      actions.goToStep( stepData.nextStep!, true );
    }
  }, [ actions, logger, productData, service, showAlert, stepData, t ] );

  const saveLeadOnComplete = React.useCallback( async (): Promise<void> => {
    if ( stepData.saveLeadOnComplete &&
      stepData.saveLeadOnComplete.enabled &&
      stepData.saveLeadOnComplete.beginStatus ) {
      try {
        const leadStore: ILeadData = await service.getLead( 0 );
        const { beginStatus, defaultAccountEmail } = stepData.saveLeadOnComplete;

        let payloadLead = preparePayloadForLead(
          leadStore, productData, beginStatus, productCode, false, defaultAccountEmail,
        );

        const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

        if ( savedLeadData ) {
          payloadLead.productVersionId = savedLeadData.policy.productVersionId;

          await service.updateLead( payloadLead, false );
        } else {
          if ( configuration && configuration.productVersionId ) {
            payloadLead.productVersionId = configuration.productVersionId;
          }

          await service.createLead( payloadLead, false );
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          logger.error( e );
        } else {
          throw e;
        }
      }
    }
  }, [ configuration, logger, productCode, productData, service, stepData.saveLeadOnComplete ] );

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    if ( isDisabledForm ) {
      actions.setValidForm( true );
      actions.goToStep( stepData.nextStep!, true );
      return;
    }

    rejectionPopup( true );

    if ( isRejected || actions.isRejected() ) {
      return;
    }

    if ( !leadData[keyPolicyStep] ) {
      return;
    }

    previousLimitRequest.current = 0;
    const savedFormData = formatFormData( formData );
    const result = await service.savedInfo( keyPolicyStep, savedFormData );

    if ( result.errorCode === 0 && !isRejected ) {
      await saveLeadOnComplete();
      await updateLeadData();
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, isDisabledForm, isRejected, keyPolicyStep, leadData, rejectionPopup,
    saveLeadOnComplete, service, showAlert, stepData.nextStep, t, updateLeadData ] );

  React.useEffect( () => {
    let isMounted = true;

    const formValues = formatFormData( getValues() );

    const saveDataToLocalStore = async ( ) => {
      if ( isEmpty( previousResultData.current ) ) {
        previousResultData.current = formValues;

        const isEditLead = lead['isEditLead'];

        if ( !lead[keyPolicyStep] || isEmpty( lead[keyPolicyStep] ) || isEditLead ) {
          setLeadData( ( prevLead ) => ( {
            ...prevLead,
            [keyPolicyStep]: { ...prevLead[keyPolicyStep], ...previousResultData.current },
          } ) );

          await service.savedInfo( keyPolicyStep, previousResultData.current );
        }

        rejectionPopup();

        return;
      }
    };

    const saveFormValues = async ( fieldInfo: string ) => {
      try {
        await service.savedInfo( keyPolicyStep, previousResultData.current );
        const storeLeadData = await service.getLead( 0 );

        setLeadData( storeLeadData );

        rejectionPopup();
        infoPopup( fieldInfo );
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };

    saveDataToLocalStore();

    const changedField = getFieldDiff( formValues, previousResultData.current );

    if ( changedField && isMounted ) {
      previousResultData.current = formValues;

      const isSystemField = systemFields.some( ( item ) => changedField.startsWith( item ) );
      if ( isSystemField ) {
        isIgnoreOnRecalculation.current = true;
      } else {
        isIgnoreOnRecalculation.current = isIgnoreOnRecalculationFieldCore(
          changedField, currentStep!, productData.stepsConfig?.steps! );
      }

      const crossPolicyFields = generateCrossPolicyFields(
        productData.stepsConfig?.steps!, keyPolicyStep, changedField,
      );

      if ( actions.updatedStoreData && crossPolicyFields ) {
        actions.updatedStoreData( crossPolicyFields, previousResultData.current[changedField] );
      }

      saveFormValues( changedField );
    }

    return () => {
      isMounted = false;
    };
  }, [ formState.isValidating, service, showAlert, t, lead, getValues, formState.isValid, rejectionPopup,
    keyPolicyStep, currentStep, policyStepNames, infoPopup, containerName, productData.stepsConfig, actions ] );

  const onCloseErrorPopup = React.useCallback ( async ( ): Promise<void> => {
    if ( itemErrorId ) {
      const errorIds = [ ...itemErrorIds, itemErrorId ];
      setItemErrorIds( errorIds );

      await service.savedInfo( `${keyPolicyStep}_error_ids`, errorIds );
    }

    setShowErrorPopup( false );
    setMessageError( '' );
  }, [ itemErrorId, itemErrorIds, keyPolicyStep, service ] );

  React.useEffect( () => {
    let isMounted = true;
    const emailVerification = async () => {
      try {
        const { visibleIfEmailValidated, visibleEmailSettings, previousStep } = stepData;

        if ( isMounted && visibleIfEmailValidated && !lead.isLogin ) {
          setLoading( true );

          const email = visibleEmailSettings && visibleEmailSettings.emailName ?
            jsonpath.query( lead, `$.${visibleEmailSettings.emailName}` )[0] : undefined;
          const token = visibleEmailSettings && visibleEmailSettings.codeName ?
            jsonpath.query( lead, `$.${visibleEmailSettings.codeName}` )[0] : undefined;

          if ( token && email ) {
            const payload: CompleteEmailVerification = {
              email,
              token,
            };
            const response = await service.completeEmailVerification( payload );

            if ( response && response.result ) {
              setValidEmail( true );
            } else {
              let previousStepName = previousStep!;
              if ( visibleEmailSettings && visibleEmailSettings.codeName ) {
                const splitToken = visibleEmailSettings.codeName.split( '.' );

                if ( splitToken.length >= 2 ) {
                  previousStepName = splitToken[0];
                }
              }

              actions.goToStep( previousStepName, false );
            }
          }
          setLoading( false );
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          if ( isMounted ) {
            setLoading( false );
          }
        } else {
          throw e;
        }
      }
    };

    emailVerification();

    return () => {
      isMounted = false;
    };
  }, [ service, t, stepData, lead, actions ] );

  React.useEffect( () => {
    const interval = setInterval( () => {
      if ( actions.isLoadingPremiumData ) {
        if ( !previousIsLoadingPremiumData.current && !actions.isLoadingPremiumData() ) {
          return;
        }

        setLoadingPremiumData( actions.isLoadingPremiumData() );
        previousIsLoadingPremiumData.current = actions.isLoadingPremiumData();
      }
    }, 500 );

    return () => {
      clearInterval( interval );
    };
  }, [ actions ] );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    if ( !isCheckedProduct() ) {
      setMessageError( t( 'bookingFunnel.validFormError' ) );
      setShowErrorPopup( true );
      trigger();
      return;
    }

    if ( isRejected ) {
      rejectionPopup();

      return;
    }

    previousIsReloadData.current = false;

    actions.reloadLead();
    setShowSavedStep( isShow );
  }, [ actions, isCheckedProduct, isRejected, rejectionPopup, t, trigger ] );

  const onCloseSavedPopup = React.useCallback ( ( ): void => {
    previousIsReloadData.current = true;
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( ( ): void => {
    previousIsReloadData.current = true;
    setShowSavedStep( false );
    actions.goToStep( stepData.nextStep!, true );
  }, [ actions, stepData.nextStep ] );

  const onShowError = React.useCallback ( ( ): void => {
    setMessageError( t( 'bookingFunnel.validFormError' ) );
    setShowErrorPopup( true );
  }, [ t ] );

  const goBackSummary = React.useCallback( async (): Promise<void> => {
    if ( isRejected ) {
      return;
    }

    if ( !isEmpty( errors ) ) {
      onShowError();
      return;
    }

    await saveLeadOnComplete();

    const findSummaryComponent = productData.stepsConfig && productData.stepsConfig.steps.find(
      ( stepItem ) => stepItem.componentType === JSONComponentType.Summary,
    );

    if ( findSummaryComponent ) {
      const summaryStep = findSummaryComponent.subSteps && findSummaryComponent.subSteps[0];

      actions.goToStep( summaryStep ? summaryStep.name : stepData.nextStep!, true );
    }

  }, [ actions, errors, isRejected, onShowError, productData.stepsConfig, saveLeadOnComplete, stepData.nextStep ] );

  if ( stepData.previousStep && !lead[stepData.previousStep] && !lead.isLogin ) {
    actions.goToStep( policyStepNames[0], false );
  }

  return (
    <FormProvider { ...formContext }>
      <div id={ stepData.nameStep } className="policy-step">
        <Row className="mt-3 mx-0">
          <Col md={ 12 } className="px-0" id={ `alert-${stepData.nameStep}` }>
            <AppAlertUI />
          </Col>
          <Col md={ 12 } className="px-0">
            <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
              <Fragment>
                { isLoading && loadingOnFullContainer }
                { currentStep && (
                  <Fragment>
                    { stepData.title && (
                      <Row>
                        <Col className="p-0" md={ 12 }
                          dangerouslySetInnerHTML={ { __html: `${stepData.title}` } }
                        >
                        </Col>
                      </Row>
                    ) }
                    { stepData.subTitle && (
                      <Row>
                        <Col className="p-0" md={ 12 }
                          dangerouslySetInnerHTML={ { __html: `${stepData.subTitle}` } }
                        >
                        </Col>
                      </Row>
                    ) }
                    { currentStep.title && (
                      <Row>
                        <Col className="p-0" md={ 12 }
                          dangerouslySetInnerHTML={ { __html: `${currentStep.title}` } }
                        >
                        </Col>
                      </Row>
                    ) }
                    { currentStep.subTitle && (
                      <Row>
                        <Col className="p-0" md={ 12 }
                          dangerouslySetInnerHTML={ { __html: `${currentStep.subTitle}` } }
                        >
                        </Col>
                      </Row>
                    ) }
                    <CoreRenderContainer
                      storeLeadData={ lead }
                      leadData={ leadData }
                      productData={ productData }
                      productCode={ productCode }
                      stepData={ stepData }
                      currentStep={ currentStep }
                      recalculationTariff={ recalculationTariff }
                      containerName={ containerName || '' }
                      policyStepNames={ policyStepNames }
                      isIgnoreOnRecalculation={ isIgnoreOnRecalculation.current }
                      isDisabledForm={ isDisabledForm }
                      onSaved={ onSaved }
                    />
                    { !isEmpty( containerName ) && (
                      <Row>
                        <Col sm={ 12 } className="dynamic-btn-panel d-flex justify-content-center px-0">
                          <Button
                            type="button"
                            variant="primary"
                            className={ `mr-0 mb-0 bg-btn-primary${isRejected ? ' box-disabled' : ''}` }
                            onClick={ goBackSummary }
                          >
                            { t( 'bookingFunnel.summary.backSummaryPage' ) }
                          </Button>
                        </Col>
                      </Row>
                    ) }
                  </Fragment>
                ) }
              </Fragment>
              <Row className={
                `justify-content-center align-items-center mt-0 mx-0 ${!isEmpty( containerName ) ? 'box-disabled' : ''}`
              }
              >
                <CoreCirclesNavigationMobile lead={ lead } />
              </Row>
              <Row className={
                `bf-footer-btn-panel justify-content-center align-items-center mt-4 mx-0 
                ${!isEmpty( containerName ) ? 'box-disabled' : ''}`
              }
              >
                { !isDisabledForm && saveForLaterButton && saveForLaterButton.enabled && (
                  <>
                    { saveForLaterButton.suppressEmailDialog ? (
                      <CoreSaveLeadNav
                        currentStep={ currentStep! }
                        stepData={ stepData }
                        productCode={ productCode }
                        productData={ productData! }
                        disabled={ formState.isSubmitting || !isValidEmail || isUpdatedLead || isLoadingPremiumData }
                        rejectionPopup={ rejectionPopup }
                        isRejected={ isRejected }
                      />
                    ) : (
                      <Button
                        id={ `${stepData.nextStep ? stepData.nextStep+'_' : ''}save_for_later` }
                        type="button"
                        variant="primary"
                        disabled={ formState.isSubmitting || !isValidEmail || isUpdatedLead || isLoadingPremiumData }
                        onClick={ () => onSaved( true ) }
                        className="col-sm-12 mr-0"
                      >
                        { saveForLaterButton.buttonText ?
                          saveForLaterButton.buttonText : t( 'bookingFunnel.saveForLaterBtn' ) }
                      </Button>
                    ) }
                  </>
                ) }
                <Button
                  id={ `${stepData.nextStep}_next` }
                  type="submit"
                  variant="primary"
                  disabled={ formState.isSubmitting || !isValidEmail || isUpdatedLead || isLoadingPremiumData }
                  className="col-sm-12 mr-0"
                >
                  { stepData.nextButtonText ? stepData.nextButtonText : t( 'bookingFunnel.nextBtn' ) }
                </Button>
                { stepData.previousStep && (
                  <Button
                    id={ `${stepData.previousStep}_back` }
                    type="button"
                    variant="link"
                    className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                    onClick={ () => actions.goToStep( stepData.previousStep!, true ) }
                  >
                    { t( 'bookingFunnel.previousBtn' ) }
                  </Button>
                ) }
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      { isShowErrorPopup && messageError && (
        <TooltipPopupCore
          tooltipInfo={ messageError }
          onClose={ () => onCloseErrorPopup( ) }
        />
      ) }
      { isShowSavedStep && (
        <CoreSavedDataPopup
          currentStep={ keyPolicyStep }
          productCode={ productCode }
          productData={ productData! }
          onClose={ () => onCloseSavedPopup( ) }
          goNextStep={ goNextStep }
          policyStepNames={ policyStepNames }
        />
      ) }
    </FormProvider>
  );
};
