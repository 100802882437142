import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ILeadData, SubStepItem } from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { getIgnoreFieldsInContainers, preparePayloadForLead } from '../../core-hooks';
import { useWidgetService } from 'Services/widget';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { useAppLogger } from 'Services/logger';
import { LeadStatus } from '@cover42/ts-contracts';
import { StepInfoItem } from '../../interfaces';
import { useCoreActions } from '../../DynamicCore';

export interface CoreSaveLeadNavProps {
  currentStep: SubStepItem;
  stepData: StepInfoItem;
  productCode: string;
  productData: IDataFactorsAndVariables;
  disabled: boolean;
  rejectionPopup?: ( isClickNextBtn: boolean, isSkipSaveForLaterValidation: boolean ) => Promise<void>;
  isRejected?: boolean;
}

export const CoreSaveLeadNav: React.FC<CoreSaveLeadNavProps> = ( dataProps ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { showAlert } = useAppAlertService();
  const actions = useCoreActions();
  const logger = useAppLogger();
  const service = useWidgetService();

  const [ isSuccess, setIsSuccess ] = React.useState<boolean>( false );
  const [ isSavedData, setIsSavedData ] = React.useState<boolean>( false );
  const { currentStep, stepData, productCode, productData, disabled, rejectionPopup, isRejected } = dataProps;
  const { saveForLaterButton } = stepData || {};

  const saveForLaterSettings = productData.stepsConfig?.saveForLaterSettings;
  const ignoreFields = getIgnoreFieldsInContainers( currentStep );

  const { formState, trigger, getValues, errors, clearErrors } = useFormContext();
  const sleep = ( ms: number ) => new Promise( ( resolve ) => setTimeout( resolve, ms ) );

  const isCheckedForm = React.useCallback( ( ): boolean => {
    return formState.isValid;
  }, [ formState.isValid ] );

  const onSaveLead = React.useCallback( async () => {
    setIsSavedData( true );
    setIsSuccess( false );

    if ( !saveForLaterButton?.ignoreValidatedFormData ) {
      if ( !isCheckedForm() ) {
        clearErrors( ignoreFields );

        const allField = Object.keys( getValues() ) || [];
        const validationFields = allField.filter( ( f ) => !ignoreFields.includes( f ) );

        await trigger( validationFields );

        const keyFieldErrors = Object.keys( await errors );

        if ( keyFieldErrors && keyFieldErrors.length > 0 ) {
          actions.goToTopPage();
          setIsSavedData( false );
          return;
        }
      }

      if ( rejectionPopup ) {
        rejectionPopup( true, true );
        await sleep( 500 );
      }

      if ( isRejected || actions.isRejected() ) {
        setIsSavedData( false );
        return;
      }
    }

    try {
      const leadData: ILeadData = await service.getLead();
      let leadStatus: string = LeadStatus.Created;
      if ( saveForLaterSettings && saveForLaterSettings.leadStatus ) {
        leadStatus = saveForLaterSettings.leadStatus;
      } else {
        const initialLeadStatus = productData.stepsConfig?.initialLeadStatus;
        leadStatus = initialLeadStatus ? initialLeadStatus : leadStatus;
      }

      let payloadLead = preparePayloadForLead(
        leadData, productData, leadStatus, productCode, false,
      );

      const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

      if ( savedLeadData ) {
        payloadLead.productVersionId = savedLeadData.policy.productVersionId;
      }

      const result = savedLeadData ?
        await service.updateLead( payloadLead ) : await service.createLead( payloadLead, false );

      if ( result ) {
        setTimeout( () => {
          const resLead = result.lead;
          actions.onSubmit( resLead.leadNumber );
        }, 500 );
      } else {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );

        setIsSuccess( false );
      }
      setIsSavedData( false );
    } catch( e ) {
      if ( e instanceof Error ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
        logger.error( e );
      } else {
        throw e;
      }
      setIsSavedData( false );
    }
  }, [ saveForLaterButton, isCheckedForm, rejectionPopup, isRejected, actions, clearErrors,
    ignoreFields, getValues, trigger, errors, service, saveForLaterSettings, productData,
    productCode, showAlert, t, logger ] );

  return (
    <Fragment>
      { isSuccess && (
        <>
          { saveForLaterButton?.successMessageText ? (
            <div className="message-success"
              dangerouslySetInnerHTML={ { __html: saveForLaterButton?.successMessageText } }
            >
            </div>
          ) : (
            <div className="message-success">
              <p>{ t( 'base:forms.messages.success' ) }</p>
            </div>
          ) }
        </>
      ) }
      { saveForLaterButton && saveForLaterButton.enabled && (
        <Button
          id={ `${stepData.nextStep ? stepData.nextStep+'_' : ''}save_for_later` }
          type="button"
          variant="primary"
          disabled={ disabled || isSavedData }
          onClick={ () => onSaveLead() }
          className="col-sm-12 mr-0"
        >
          { saveForLaterButton.buttonText ?
            saveForLaterButton.buttonText : t( 'bookingFunnel.saveForLaterBtn' ) }
        </Button>
      ) }
    </Fragment>
  );
};
